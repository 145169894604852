import TabBar from '../../components_new/TabBar';
import ListButton from '../../components_new/buttons/ListButton';
import MainMap from '../../components_new/MainMap';

const MapPage = () => {
  return (
    <>
      <MainMap></MainMap>
      <TabBar />
      <ListButton />
    </>
  );
};

export default MapPage;
