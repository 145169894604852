import React from 'react';

const AddIcon = ({ width, height, color }) => {
  return (
    <svg
      width='76'
      height='76'
      viewBox='0 0 76 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M75.5 38C75.5 35.9289 73.8211 34.25 71.75 34.25C69.6789 34.25 68 35.9289 68 38H75.5ZM38 8C40.0711 8 41.75 6.32107 41.75 4.25C41.75 2.17893 40.0711 0.5 38 0.5V8ZM66.125 68H9.875V75.5H66.125V68ZM8 66.125V9.875H0.5V66.125H8ZM68 38V66.125H75.5V38H68ZM9.875 8H38V0.5H9.875V8ZM9.875 68C8.83947 68 8 67.1606 8 66.125H0.5C0.5 71.3026 4.69732 75.5 9.875 75.5V68ZM66.125 75.5C71.3026 75.5 75.5 71.3026 75.5 66.125H68C68 67.1606 67.1606 68 66.125 68V75.5ZM8 9.875C8 8.83947 8.83946 8 9.875 8V0.5C4.69734 0.5 0.5 4.69732 0.5 9.875H8Z'
        fill={color || '#3495CF'}
      />
      <path
        d='M4.25 58.6249L24.2996 40.2462C25.6979 38.9643 27.8336 38.9286 29.2739 40.1633L53 60.4999'
        stroke={color || '#3495CF'}
        strokeWidth='7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M45.5 51.125L54.4503 42.1747C55.7697 40.8552 57.8589 40.7067 59.3519 41.8265L71.75 51.125'
        stroke={color || '#3495CF'}
        strokeWidth='7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M49.25 15.5H71.75'
        stroke={color || '#3495CF'}
        strokeWidth='7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M60.5 4.25V26.75'
        stroke={color || '#3495CF'}
        strokeWidth='7.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AddIcon;
