import InfoContainer from '../../components_new/InfoContainer';

const ServerError = () => {
  return (
    <InfoContainer
      code={500}
      linkText="Написать в поддержку"
      link="https://t.me/boldarevyury"
      external
    >
      <p>Ой! Что-то сломалось!</p>
      <p>Перезагрузите страницу или обратитесь в поддержку</p>
    </InfoContainer>
  );
};

export default ServerError;
