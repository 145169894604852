import React from 'react';
import { Box, Modal } from '@mui/material';
import FacebookIcon from '../../../images/shareIcons/FacebookIcon';
import FacebookShareButton from '../../buttons/shareButtons/FacebookShareButton';
import { useLocation } from 'react-router-dom';
import EmailShareButton from '../../buttons/shareButtons/EmailShareButton';
import WhatsappIcon from '../../../images/shareIcons/WhatsappIcon';
import VKIcon from '../../../images/shareIcons/VKIcon';
import { useSelector } from 'react-redux';
import { getCurrentRoute } from '../../../store/routes/selectors';
import InstapaperIcon from '../../../images/shareIcons/InstapaperIcon';
import OKIcon from '../../../images/shareIcons/OKIcon';
import MailruIcon from '../../../images/shareIcons/MailruIcon';
import ViberIcon from '../../../images/shareIcons/ViberIcon';
import EmailIcon from '../../../images/shareIcons/EmailIcon';
import TelegramIcon from '../../../images/shareIcons/TelegramIcon';
import TwitterIcon from '../../../images/shareIcons/TwitterIcon';
import VKShareButton from '../../buttons/shareButtons/VKShareButton';
import InstapaperShareButton from '../../buttons/shareButtons/InstapaperShareButton';
import TelegramShareButton from '../../buttons/shareButtons/TelegramShareButton';
import OKShareButton from '../../buttons/shareButtons/OKShareButton';
import WhatsappShareButton from '../../buttons/shareButtons/WhatsappShareButton';
import MailruShareButton from '../../buttons/shareButtons/MailruShareButton';
import TwitterShareButton from '../../buttons/shareButtons/TwitterShareButton';
import ViberShareButton from '../../buttons/shareButtons/ViberShareButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '3px solid #C6F04E',
  borderRadius: 5,
  boxShadow: 24,
  p: 2,
  display: 'grid',
  justifyContent: 'center',
  gap: 1,
  gridTemplateColumns: '0.2fr 0.2fr 0.2fr',
};

const SharePopup = ({ isOpen, close }) => {
  const route = useSelector(getCurrentRoute);
  const location = useLocation();
  const shareUrl = `https://Kibergid.ru${location.pathname}`;
  const title = route?.name;
  const exampleImage = route;
  return (
    <Modal
      open={isOpen}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: '50%' }}>
        <VKShareButton
          url={shareUrl}
          image={`${String(window.location)}/${exampleImage}`}
          className="share-button"
        >
          <VKIcon size={32} round />
        </VKShareButton>
        <InstapaperShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <InstapaperIcon size={32} round />
        </InstapaperShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <OKShareButton
          url={shareUrl}
          image={`${String(window.location)}/${exampleImage}`}
          className="share-button"
        >
          <OKIcon size={32} round />
        </OKShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <MailruShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <MailruIcon size={32} round />
        </MailruShareButton>
        <FacebookShareButton
          url={shareUrl}
          quote={'Facebook'}
          className="share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <ViberShareButton url={shareUrl} title={title} className="share-button">
          <ViberIcon size={32} round />
        </ViberShareButton>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </Box>
    </Modal>
  );
};

export default SharePopup;
