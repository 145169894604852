import React, { useState } from 'react';
import styles from './AudioInput.module.css';
import IconClose from '../../../images_new/IconClose';
import IconAudio from '../../../images_new/IconAudio';

const AudioInput = ({ register, index, reset }) => {
  const { onChange, ...rest } = register(`points${index}file`);
  const [audioPoint, setAudioPoint] = useState(null);

  const handleAudioRemove = () => {
    setAudioPoint(null);
    reset({ dynImg: null });
  };

  const handleAudioChange = async (event) => {
    const file = event.target.files[0];
    setAudioPoint(URL.createObjectURL(file));
    onChange(event);
  };

  return (
    <>
      <label className={styles.file} htmlFor={`points${index}file`}>
        <IconAudio />
        <p className={styles.text}>Добавить аудио</p>
      </label>
      {audioPoint && (
        <>
          <div className={styles.audioContainer}>
            <p className={styles.photoText}>{audioPoint}</p>
            <button className={styles.deleteAudio} onClick={handleAudioRemove}>
              <IconClose width={5} height={5} />
            </button>
          </div>
        </>
      )}
      <input
        id={`points${index}file`}
        type='file'
        name={`points${index}file`}
        accept='.mpeg,.mp4,.ogg,.mp3,.wav'
        onChange={handleAudioChange}
        {...rest}
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
      />
    </>
  );
};

export default AudioInput;
