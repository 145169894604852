import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataAudio: {
    file: "http://158.160.22.29:8000/media/routes/intro/b61ff965633d8d302d1492dd57d77a992d0bf02f.mp3",
    points_media: [{
      photo: null
    }],
    name: 'Вступление',
  },
  showPlayer: false,
  playAudio: false,
  onSuccess: null,
  errors: null,
  loaders: {
    newAudio: false,
  },
};

const AudioPlayer = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    showAudioPlayer: (state) => {
      state.showPlayer = !state.showPlayer;
    },
    setCurrentAudio: (state, action) => {
      state.dataAudio = action.payload;
    },
    togglePlaying: (state) => {
      state.playAudio = !state.playAudio;
    },
  }
})

export const { showAudioPlayer, setCurrentAudio, togglePlaying} = AudioPlayer.actions;

export default AudioPlayer.reducer;