import React from 'react';

const IconCase = ({ style, width, height, color, cursor }) => {
  return (
    <svg
      style={style}
      cursor={cursor}
      width={width || 24}
      height={height || 22}
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.66669 5.58333H3.33335C2.75872 5.58333 2.20762 5.81161 1.80129 6.21794C1.39496 6.62426 1.16669 7.17536 1.16669 7.75V18.5833C1.16669 19.158 1.39496 19.7091 1.80129 20.1154C2.20762 20.5217 2.75872 20.75 3.33335 20.75H20.6667C21.2413 20.75 21.7924 20.5217 22.1988 20.1154C22.6051 19.7091 22.8334 19.158 22.8334 18.5833V7.75C22.8334 7.17536 22.6051 6.62426 22.1988 6.21794C21.7924 5.81161 21.2413 5.58333 20.6667 5.58333H16.3334M7.66669 5.58333V1.9C7.66669 1.72761 7.73517 1.56228 7.85707 1.44038C7.97897 1.31848 8.1443 1.25 8.31669 1.25H15.6834C15.8557 1.25 16.0211 1.31848 16.143 1.44038C16.2649 1.56228 16.3334 1.72761 16.3334 1.9V5.58333M7.66669 5.58333H16.3334"
            stroke={color || '#979798'}
            strokeWidth="1.625"/>
    </svg>
  );
};

export default IconCase;
