import React from 'react';

const IconStar = ({width, height, color }) => {
  return (
    <svg width={width || 16} height={height || 15} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.05041 0.92705C7.34976 0.00573921 8.65317 0.00573969 8.95252 0.92705L10.0221 4.21885C10.156 4.63087 10.5399 4.90983 10.9731 4.90983H14.4343C15.4031 4.90983 15.8058 6.14945 15.0221 6.71885L12.222 8.75329C11.8715 9.00793 11.7248 9.4593 11.8587 9.87132L12.9283 13.1631C13.2276 14.0844 12.1731 14.8506 11.3894 14.2812L8.58925 12.2467C8.23876 11.9921 7.76417 11.9921 7.41368 12.2467L4.61351 14.2812C3.8298 14.8506 2.77532 14.0844 3.07467 13.1631L4.14424 9.87132C4.27811 9.4593 4.13145 9.00793 3.78097 8.75329L0.980798 6.71885C0.197084 6.14945 0.59986 4.90983 1.56858 4.90983H5.02978C5.46301 4.90983 5.84696 4.63087 5.98084 4.21885L7.05041 0.92705Z"
            fill={color || '#3495CF'}/>
    </svg>
  );
};

export default (IconStar);




