import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function instapaperLink(url, { title, description }) {
  assert(url, "instapaper.url")

  return (
    "http://www.instapaper.com/hello2" +
    objectToGetParams({
      url,
      title,
      description
    })
  )
}

const InstapaperShareButton = createShareButton(
  "instapaper",
  instapaperLink,
  props => ({
    title: props.title,
    description: props.description
  }),
  {
    windowWidth: 500,
    windowHeight: 500,
    windowPosition: "windowCenter"
  }
)

export default InstapaperShareButton