import React from 'react';

const playerPlay = ({ width = 58, height = 58, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 58C12.9833 58 0 45.0167 0 29C0 12.9833 12.9833 0 29 0C45.0167 0 58 12.9833 58 29C58 45.0167 45.0167 58 29 58ZM29 52.2C35.153 52.2 41.054 49.7557 45.4049 45.4049C49.7557 41.054 52.2 35.153 52.2 29C52.2 22.847 49.7557 16.946 45.4049 12.5951C41.054 8.24428 35.153 5.8 29 5.8C22.847 5.8 16.946 8.24428 12.5951 12.5951C8.24428 16.946 5.8 22.847 5.8 29C5.8 35.153 8.24428 41.054 12.5951 45.4049C16.946 49.7557 22.847 52.2 29 52.2ZM25.0038 18.6035L39.1529 28.0343C39.312 28.1402 39.4425 28.2838 39.5328 28.4522C39.623 28.6207 39.6702 28.8089 39.6702 29C39.6702 29.1911 39.623 29.3793 39.5328 29.5478C39.4425 29.7162 39.312 29.8598 39.1529 29.9657L25.0009 39.3965C24.8264 39.5122 24.6239 39.5785 24.4147 39.5883C24.2056 39.5982 23.9977 39.5513 23.8131 39.4526C23.6285 39.3539 23.474 39.2071 23.3661 39.0277C23.2581 38.8483 23.2007 38.6431 23.2 38.4337V19.5663C23.2004 19.3565 23.2577 19.1507 23.3658 18.9709C23.4738 18.791 23.6287 18.6439 23.8138 18.5451C23.9989 18.4463 24.2073 18.3996 24.4169 18.4098C24.6264 18.4201 24.8293 18.4871 25.0038 18.6035Z"
        fill="#3495CF"
      />
    </svg>
  );
};

export default playerPlay;
