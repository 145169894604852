import axios from 'axios';
import { stringify } from 'query-string';

const baseUrlClear = process.env.REACT_APP_API_ENDPOINT || '/';

const axiosInstance = axios.create({
  baseURL: baseUrlClear,

  headers: {
    'X-CSRFToken': 'CSRF_TOKEN',
  },
  paramsSerializer: (params) => stringify(params),
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export { axiosInstance };
