import { useEffect, useState } from 'react';
import styles from './CardOpened.module.css';
import { getCurrentRoute } from '../../store/routes/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getRoute } from '../../store/routes';
import PopUpReview from '../../components_new/popUps/PopUpReview';
import PopUpReviewComment from '../../components_new/popUps/PopUpReviewСomment';
import PopUpParamsPhoto from '../../components_new/popUps/PopUpParamsPhoto';
import PopUpThanks from '../../components_new/popUps/PopUpThanks';
import IconBack from '../../images_new/IconBack';
import Avatar from '../../images_new/avatar.png';
import { map } from 'lodash';
import IconPlay from '../../images_new/IconPlay';
import IconPause from '../../images_new/IconPause';
import ExcursionMap from '../../components_new/ExcursionMap';
import PopUpPoint from '../../components_new/popUps/PopUpPoint';
import {
  setCurrentAudio,
  showAudioPlayer,
  togglePlaying,
} from '../../store/audioPlayer';
import { getPlaying, getDataAudio } from '../../store/audioPlayer/selectors';
import ReactPlayer from 'react-player';
import Text from '../../components_new/UI/Text';
import { getUser } from '../../store/auth/selectors';

const CardOpened = () => {
  const [point, setPoint] = useState();
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [rating, setRating] = useState(null);
  const [isOpenReviewComment, setIsOpenReviewComment] = useState(false);
  const [isOpenParamsPhoto, setIsOpenParamsPhoto] = useState(false);
  const [isOpenPoint, setIsOpenPoint] = useState(false);
  const [isOpenThanks, setIsOpenThanks] = useState(false);
  const [activePoint, setActivePoint] = useState(null);
  const [fullMap, setFullMap] = useState(false);
  const [photo, setPhoto] = useState([]);
  const [timer, setTimer] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idCard } = useParams();
  const user = useSelector(getUser);
  const routeData = useSelector(getCurrentRoute);
  const playing = useSelector(getPlaying);
  const dataAudio = useSelector(getDataAudio);
  const userData = useSelector(getUser);
  let formData = new FormData();
  const location = useLocation();

  const onClickPoint = (item, index) => {
    setActivePoint(index);
    setPoint(item);
    setIsOpenPoint(true);
  };

  const handleCompleteBtn = () => {
    if (routeData?.author?.id === userData?.id) {
      navigate('/publications', { replace: true });
    } else {
      setIsOpenReview(true);
    }
  };

  const handleMapBtn = () => {
    setFullMap(!fullMap);
    if (activePoint === null) setActivePoint(0);
    activePoint && dispatch(setCurrentAudio(routeData.points[activePoint]));
  };

  const handleClickBtnMap = () => {
    setFullMap(!fullMap);
  };

  const handleClickBtnIntro = () => {
    (dataAudio?.file === routeData?.intro ||
      activePoint === null ||
      (!playing && activePoint >= 0)) &&
      dispatch(togglePlaying());
    dispatch(
      setCurrentAudio({
        file: routeData.intro,
        points_media: [
          {
            photo: routeData.image,
          },
        ],
        name: 'Вступление',
      })
    );

    dispatch(showAudioPlayer());
  };

  const updateData = (files) => {
    files.forEach((file) => {
      setPhoto((prevPhoto) => [...prevPhoto, file]);
      formData.append('file', file);
    });
  };

  useEffect(() => {
    dispatch(getRoute(idCard));
  }, [idCard]);

  useEffect(() => {
    routeData && dispatch(setCurrentAudio(routeData.points[activePoint]));
  }, [activePoint]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.containerImage}>
        <img
          className={styles.cover}
          src={routeData?.image}
          alt="Route cover"
        />
        <div
          onClick={() => {
            navigate(-1 || '/search');
          }}
          className={styles.containerArrow}
        >
          <IconBack width={9} height={15} />
        </div>
      </div>

      <div className={styles.info}>
        <div className={styles.about}>
          <h1 className={styles.title}>{routeData?.name}</h1>
          <div className={styles.containerInfo}>
            <img
              className={styles.iconImg}
              src={
                routeData?.author.avatar !== null
                  ? routeData?.author.avatar
                  : Avatar
              }
              alt="mark"
            />
            <h2 className={styles.textAuthor}>
              {routeData?.author.first_name} {routeData?.author.last_name}
            </h2>
          </div>
        </div>
        {routeData?.description && <Text value={routeData.description} />}
      </div>
      {routeData?.is_audioguide && (
        <div onClick={handleClickBtnIntro} className={styles.player}>
          <div className={styles.playBtn}>
            {playing && dataAudio?.file === routeData.intro ? (
              <IconPause />
            ) : (
              <IconPlay />
            )}
          </div>
          <p className={styles.playerTitle}>
            {playing && dataAudio?.file === routeData.intro
              ? 'Остановить запись'
              : 'Прослушать вступление'}
          </p>
        </div>
      )}
      <div className={styles.map}>
        {routeData !== null && !fullMap && (
          <>
            <ExcursionMap
              route={routeData}
              activePoint={activePoint}
              setActivePoint={setActivePoint}
              fullMap={fullMap}
              setPoint={setPoint}
              point={point}
            />
            <button
              onClick={handleMapBtn}
              className={`${styles.btn} ${styles.btnMap}`}
            >
              Слушать
            </button>
          </>
        )}
        {routeData !== null && fullMap && (
          <>
            <ExcursionMap
              route={routeData}
              activePoint={activePoint}
              setActivePoint={setActivePoint}
              fullMap={fullMap}
              togglePlaying={togglePlaying}
              handleClickBtnMap={handleClickBtnMap}
              setIsOpenPoint={setIsOpenPoint}
              setPoint={setPoint}
              point={point}
            />
          </>
        )}
        {routeData && point && (
          <PopUpPoint
            point={point}
            routeData={routeData}
            setIsOpenPoint={setIsOpenPoint}
            isOpenPoint={isOpenPoint}
            setPoint={setPoint}
            handleMapBtn={handleMapBtn}
          />
        )}
      </div>
      <div
        className={
          routeData?.points.length > 1 ? styles.points : styles.pointsHidden
        }
      >
        {map(routeData?.points, (item, index) => (
          <div className={styles.pointsContainer} key={item.id}>
            <div
              onClick={() => onClickPoint(item, index)}
              className={styles.pointsImgContainer}
            >
              <img
                className={styles.pointsImg}
                src={item.points_media[0]?.photo}
                alt="img"
              />
              <div className={styles.pointsNum}>{index + 1}</div>
            </div>
            <p className={styles.pointsTitle}>{item.name}</p>
          </div>
        ))}
      </div>
      {routeData?.author.id !== user?.id && (
        <button
          onClick={handleCompleteBtn}
          className={`${styles.btn} ${styles.btnComplete}`}
        >
          Закончить маршрут
        </button>
      )}
      {isOpenReview && (
        <PopUpReview
          isOpen={isOpenReview}
          setIsOpenReview={setIsOpenReview}
          setIsOpenReviewComment={setIsOpenReviewComment}
          rating={rating}
          setRating={setRating}
        />
      )}
      {isOpenReviewComment && (
        <PopUpReviewComment
          isOpen={isOpenReviewComment}
          setIsOpenReviewComment={setIsOpenReviewComment}
          setIsOpenParamsPhoto={setIsOpenParamsPhoto}
          setIsOpenThanks={setIsOpenThanks}
          photo={photo}
          setPhoto={setPhoto}
          idCard={idCard}
          rating={rating}
          setTimer={setTimer}
        />
      )}
      {isOpenParamsPhoto && (
        <PopUpParamsPhoto
          isOpen={isOpenParamsPhoto}
          setIsOpenParamsPhoto={setIsOpenParamsPhoto}
          updateData={updateData}
        />
      )}
      {isOpenThanks && (
        <PopUpThanks
          isOpen={isOpenThanks}
          setIsOpenThanks={setIsOpenThanks}
          timer={timer}
        />
      )}
      {dataAudio?.file && (
        <ReactPlayer
          url={dataAudio.file}
          playing={playing && dataAudio.name === 'Вступление'}
          controls
          width="100%"
          height="100%"
          style={{ display: 'none' }}
        />
      )}
    </div>
  );
};

export default CardOpened;
