import React, { useRef, useState } from 'react';
// import PopupProfile from '../../../components/popUps/PopupProfile';
import { getUser } from '../../store/auth/selectors';
import { patchProfile } from '../../store/auth/index';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './ProfileEdit.module.css';
import avatarPlug from '../../images_new/avatar_plug.svg';
import IconBack from '../../images_new/IconBack';
import IconPhotoCamera from '../../images_new/IconPhotoCamera';

const ProfileEdit = () => {
  const avatarUploader = useRef(null);

  const userData = useSelector(getUser);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });
  const [isChangedFirstname, setIsChangedfirstname] = useState(false);
  const [isChangedLastname, setIsChangedLastname] = useState(false);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [file, setFile] = useState(null);
  const [avatarFile, SetAvatarFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAboutPopup, setShowAboutPopup] = useState(false);
  const [showMailPopup, setShowMailPopup] = useState(false);
  const [showCityPopup, setShowCityPopup] = useState(false);

  const handleInputChange = (event) => {
    let avatar = URL.createObjectURL(event.target.files[0]);

    SetAvatarFile(event.target.files[0]);
    setFile(avatar);

    const formData = new FormData();
    formData.append('avatar', event.target.files[0]);
    // dispatch(patchAvatar(formData));
  };

  const handleAvatarPick = () => {
    avatarUploader.current.click();
  };

  // const deleteAvatarProfile = () => {
  //   const formData = new FormData();
  //   formData.append('avatar', '');
  //   dispatch(patchAvatar(formData));
  //   togglePopup();
  // };
  const submitForm = (data) => {
    const formData = new FormData();
    file !== null && formData.append('avatar', avatarFile);
    data.first_name !== '' && formData.append('first_name', data.first_name);
    data.last_name !== '' && formData.append('last_name', data.last_name);
    dispatch(patchProfile(formData));
    navigate('/profile');
  };

  function handleNameChange(e) {
    setFirstname(e.target.value);
    setIsChangedfirstname(true);
  }

  function handleLastnameChange(e) {
    setLastname(e.target.value);
    setIsChangedLastname(true);
  }

  return (
    <section className={styles.profileEdit}>
      <form className={styles.container} onSubmit={handleSubmit(submitForm)}>
        <div className={styles.header}>
          <div
            className={styles.headerWrapper}
            onClick={() => navigate('/profile')}
          >
            <IconBack width={12} height={20} color={'#979798'} />
          </div>
          <button className={styles.submitButton}>Сохранить</button>
        </div>
        <h4 className={styles.title}>Личная информация</h4>
        <div className={styles.avatar}>
          <img
            className={styles.avatarImage}
            src={file || userData?.avatar || avatarPlug}
            alt="avatar"
          />
          <div className={styles.buttonWrapper} onClick={handleAvatarPick}>
            <IconPhotoCamera />
            <p className={styles.buttonText}>Добавить</p>
          </div>
        </div>
        <p className={styles.subtitle}>Профиль</p>
        <fieldset className={styles.inputContainer}>
          <input
            className={styles.formInput}
            style={
              errors?.first_name && { border: '0.5px solid red', color: 'red' }
            }
            type="text"
            value={isChangedFirstname ? firstname : userData?.first_name ?? ''}
            placeholder="Имя"
            {...register('first_name', {
              onChange: handleNameChange,
              // required: 'Поле обязательно к заполнению',
              // minLength: {
              //   value: 2,
              //   message: 'Количество символов не менее 2',
              // },
              maxLength: {
                value: 40,
                message: 'Количество символов не более 40',
              },
              pattern: {
                value: /^[A-Za-z-А-Яа-яЁё]+$/i,
                message: 'Разрешены только латинские буквы и кириллица',
              },
            })}
          ></input>

          <input
            className={styles.formInput}
            value={isChangedLastname ? lastname : userData?.last_name ?? ''}
            type="text"
            style={
              errors?.last_name && { border: '0.5px solid red', color: 'red' }
            }
            placeholder="Фамилия"
            {...register('last_name', {
              onChange: handleLastnameChange,
              // required: 'Поле обязательно к заполнению',
              // minLength: {
              //   value: 2,
              //   message: 'Количество символов не менее 2',
              // },
              maxLength: {
                value: 40,
                message: 'Количество символов не более 40',
              },
              pattern: {
                value: /^[A-Za-z-А-Яа-яЁё]+$/i,
                message: 'Разрешены только латинские буквы и кириллица',
              },
            })}
          ></input>
          <span className={styles.form__error}>
            {' '}
            {(errors?.first_name && errors.first_name.message) ||
              (errors?.last_name && errors.last_name.message)}
          </span>
        </fieldset>
        <input
          type="file"
          ref={avatarUploader}
          style={{ opacity: 0, width: '1px', margin: 0, padding: 0 }}
          onChange={handleInputChange}
          accept="/image*,.png,.jpg,.jpeg,.gif"
        ></input>
      </form>
      <p className={styles.subtitle}>Контактные данные</p>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <div className={styles.infoText}>
            <p className={styles.emailTitle}>Email</p>
            <span className={styles.emailText}>{userData?.email}</span>
          </div>
          <button className={styles.submitButton}>Изменить</button>
        </div>
        <div className={styles.info}>
          <div className={styles.infoText}>
            <p className={styles.emailTitle}>Телефон</p>
            <span className={styles.emailText}>
              Для оповещений, напоминаний и восстановления пароля.
            </span>
          </div>
          <button className={styles.submitButton}>Изменить</button>
        </div>
        <div className={styles.info}></div>
      </div>
    </section>
  );
};

export default ProfileEdit;
