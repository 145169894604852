import styles from './PopUpImgBig.module.css';
import { useLocation } from 'react-router-dom';

const PopUpImgBig = () => {
  const location = useLocation();
  const url = location.state.url;

  return (
    <>
      <img className={styles.url} src={url} alt="Img" />
    </>
  );
};

export default PopUpImgBig;
