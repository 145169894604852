import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { NewAddButton } from './styles';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const AddBigButton = ({ text, onClick }) => {
  return (
    <NewAddButton onClick={() => onClick()}>
      <AddIcon sx={{ width: '67%', height: '67%', color: '#C6F04E' }} />
      <Typography
        variant="subtitle1"
        sx={{ color: grey[900], textTransform: 'none' }}
      >
        {text}
      </Typography>
    </NewAddButton>
  );
};

export default AddBigButton;
