import React from 'react';
import styles from './TabBar.module.css';
import IconLike from '../../images_new/IconLike';
import IconProfile from '../../images_new/IconProfile';
import IconCase from '../../images_new/IconCase';
import IconMagnifierTabBar from '../../images_new/IconMagnifierTabBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthentication } from '../../store/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { openPopupLogin } from '../../store/toolkitSlice';

const TabBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = useSelector(isAuthentication);

  const search = location.pathname === '/search';
  const favorite = location.pathname === '/favorite';
  const partners = location.pathname === '/partners';
  const profile = location.pathname === '/profile';
  const mapPage = location.pathname === '/map';

  return (
    <>
      <div className={styles.container}>
        <button
          onClick={() => {
            navigate('/search');
          }}
          className={styles.button}
        >
          <IconMagnifierTabBar
                     width={21}
                     height={21}
                     color={search || mapPage ? '#3495CF' : '#979798'}/>
          <p style={{color: search? '#3495CF':'#979798'}} className={styles.text}>
            Поиск
          </p>
        </button>
        <button
          onClick={() => {
            isAuth !== true
              ? dispatch(openPopupLogin())
              : navigate('/favorite');
          }}
          className={styles.button}
        >
          <IconLike width={21}
                     height={21}
                     color={favorite ? '#3495CF' : '#979798'}/>
          <p style={{color: favorite? '#3495CF':'#979798'}} className={styles.text}>Избранное</p>
        </button>
        <button

          onClick={() => {navigate('/partners')}}

          className={styles.button}
        >
          <IconCase width={21}
                       height={21}
                       color={partners ? '#3495CF' : '#979798'}/>
          <p style={{color: partners? '#3495CF':'#979798'}} className={styles.text}>Партнерам</p>
        </button>
        <button
          onClick={() => {
            isAuth !== true ? dispatch(openPopupLogin()) : navigate('/profile');
          }}
          className={styles.button}
        >
          <IconProfile width={21}
                    height={21}
                    color={profile ? '#3495CF' : '#979798'}/>
          <p style={{color: profile? '#3495CF':'#979798'}} className={styles.text}>Профиль</p>
        </button>
      </div>
    </>
  );
};

export default TabBar;
