import { styled } from '@mui/material/styles';

export const BoxWrapper = styled('div')(({ theme }) => ({
  maxWidth: 400,
}));

export const TimeItem = styled('li')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  gap: '1.5rem',
  '&:lastChild': {
    '&:before': {
      display: 'none',
    },
  },
}));

export const TimeContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  flex: 1,
  order: 1,
  paddingLeft: '1.5rem',
  paddingBottom: '1rem',
  '&:before': {
    content: '""',
    position: 'absolute',
    right: '100%',
    top: 0,
    height: '100%',
    width: '2px',
    backgroundColor: '#ECFF9F',
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    left: 'calc(0px - 13px)',
    top: 0,
    width: '20px',
    height: '20px',
    backgroundColor: '#ECFF9F',
    zIndex: 1,
    border: '2px solid #ECFF9F',
    borderRadius: '50%',
  },
}));
