import { useEffect } from 'react';
import styles from './PopUpReview.module.css';
import IconClose from '../../../images_new/IconClose';
import Star from '../../buttons/Star';

const PopUpReview = ({
  isOpen,
  setIsOpenReview,
  setIsOpenReviewComment,
  rating,
  setRating,
}) => {
  const openCommentPopup = () => {
    setIsOpenReviewComment(true);
    setIsOpenReview(false);
  };

  const clickClose = () => {
    setIsOpenReview(false);
  };

  useEffect(() => {
    setRating(rating);
  }, [rating]);

  return (
    <>
      <div className={!isOpen ? styles.popup : styles.popup_opened}>
        <div className={styles.container}>
          <div className={styles.btnClose} onClick={clickClose}>
            <IconClose width={17} height={17} />
          </div>
          <h1 className={styles.title}>Вы завершили маршрут.</h1>
          <p className={styles.text}>Поставьте оценку маршруту</p>
          <Star setRating={setRating} rating={rating} />
          <button
            onClick={openCommentPopup}
            className={styles.button}
            disabled={!rating}
          >
            Оценить
          </button>
        </div>
      </div>
    </>
  );
};

export default PopUpReview;
