import React from 'react';

const IconFeedback = ({ width, height, color }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0615 5.30372L14.6973 0.93946C14.5703 0.81249 14.4196 0.711772 14.2537 0.643055C14.0878 0.574339 13.91 0.538971 13.7305 0.538971C13.5509 0.538971 13.3731 0.574339 13.2072 0.643055C13.0414 0.711772 12.8906 0.81249 12.7637 0.93946L0.720705 12.9815C0.593493 13.1082 0.492623 13.2589 0.423905 13.4248C0.355188 13.5908 0.319982 13.7687 0.320315 13.9482V18.3125C0.320315 18.6751 0.464357 19.0229 0.720755 19.2793C0.977152 19.5357 1.3249 19.6797 1.6875 19.6797H18.0938C18.2492 19.6797 18.3982 19.618 18.5081 19.5081C18.618 19.3982 18.6797 19.2492 18.6797 19.0938C18.6797 18.9384 18.618 18.7893 18.5081 18.6794C18.3982 18.5696 18.2492 18.5078 18.0938 18.5078H7.79004L19.0615 7.23731C19.1885 7.11035 19.2892 6.95963 19.3579 6.79375C19.4266 6.62786 19.462 6.45007 19.462 6.27052C19.462 6.09096 19.4266 5.91317 19.3579 5.74728C19.2892 5.5814 19.1885 5.43068 19.0615 5.30372ZM6.13281 18.5078H1.6875C1.6357 18.5078 1.58602 18.4872 1.5494 18.4506C1.51277 18.414 1.49219 18.3643 1.49219 18.3125V13.9482C1.49241 13.8965 1.51313 13.847 1.54981 13.8106L10.2813 5.07911L14.9219 9.71876L6.13281 18.5078ZM18.2324 6.40821L15.75 8.89063L11.1104 4.25001L13.5918 1.76856C13.6099 1.7504 13.6315 1.736 13.6552 1.72617C13.6789 1.71634 13.7043 1.71128 13.73 1.71128C13.7557 1.71128 13.7811 1.71634 13.8048 1.72617C13.8285 1.736 13.85 1.7504 13.8682 1.76856L18.2324 6.13184C18.2506 6.14998 18.265 6.17152 18.2748 6.19523C18.2846 6.21894 18.2897 6.24436 18.2897 6.27003C18.2897 6.29569 18.2846 6.32111 18.2748 6.34482C18.265 6.36853 18.2506 6.39007 18.2324 6.40821Z"
        fill={color || '#979798'}
      />
    </svg>
  );
};

export default IconFeedback;
