import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Arrow = ({ path }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      component={Link}
      onClick={() => {navigate(path || -1); }}
      aria-label="delete"
    >
      <ArrowBackIcon sx={{ color: '#1B8086' }} />
    </IconButton>
  );
};

export default Arrow;
