import React from 'react';

const IconLikeFull = ({ width, height, color }) => {
  return (
    <svg width={width || 18} height={height || 16} viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.80001 15.8068L7.59167 14.7068C3.30001 10.8151 0.466675 8.24014 0.466675 5.09847C0.466675 2.52347 2.48334 0.515137 5.05001 0.515137C6.50001 0.515137 7.89167 1.19014 8.80001 2.24847C9.70834 1.19014 11.1 0.515137 12.55 0.515137C15.1167 0.515137 17.1333 2.52347 17.1333 5.09847C17.1333 8.24014 14.3 10.8151 10.0083 14.7068L8.80001 15.8068Z'
        fill={color || '#3495CF'} />
    </svg>
  );
};

export default (IconLikeFull);




