import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';

const BoxInput = styled('div')(({ theme }) => ({
  border: '1px solid #AAAAAA',
  width: '100%',
  borderRadius: 8,
  marginTop: '12px',
  padding: '12px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
}));

const InputСost = styled('input')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  width: '90%',
  cursor: 'pointer',
}));

const CoordinatesInput = ({
  handleCoorChange,
  register,
  name,
  marginBottom,
  placeholder,
  readOnly,
}) => {
  return (
    <Box marginBottom={marginBottom} sx={{ width: '100%' }}>
      <BoxInput sx={{ backgroundColor: '#ECFF9F' }}>
        <FmdGoodOutlinedIcon sx={{ color: '#1B8086' }} />
        <InputСost
          {...register(name, {required: "Поле"})}
          // required
          fullWidth
          id={name}
          placeholder={placeholder}
          onClick={handleCoorChange}
          readOnly={readOnly}
        />
      </BoxInput>
    </Box>
  );
};

export default CoordinatesInput;
