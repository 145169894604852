import React from 'react';
import styles from '../PopUpPlayer.module.css';
import { showAudioPlayer } from '../../../../store/audioPlayer';
import IconClose from '../../../../images_new/IconClose';
import Img from '../../../../images_new/avatar.png';
import { useDispatch } from 'react-redux';

const AudioCover = ({ dataAudio }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.continerInfo}>
      <div className={styles.btnClose} onClick={() => dispatch(showAudioPlayer(false))}>
        <IconClose />
      </div>
      <img
        className={styles.img}
        src={dataAudio !== null ? dataAudio?.points_media[0]?.photo : Img}
        alt='title'
      />
      <h2 className={styles.title}>{dataAudio?.name}</h2>
    </div>
  );
};

export default AudioCover;