import React from 'react';
import styles from './PopUpPoint.module.css';
import IconClose from '../../../images_new/IconClose';
import MainButton from '../../buttons/MainButton';
import IconMark from '../../../images_new/IconMark';
import Text from '../../UI/Text';

const PopUpPoint = ({ isOpenPoint, setIsOpenPoint, point, routeData, handleMapBtn }) => {
  return (
    <div
      className={!isOpenPoint ? styles.containerPlayer : styles.containerOpen}
    >
      <div className={styles.wrapper}>
        <div className={styles.containerImage}>
          <img
            className={styles.cover}
            src={point?.points_media[0].photo}
            alt="Route cover"
          />
          <div
            onClick={() => {
              setIsOpenPoint(false);
              handleMapBtn()
            }}
            className={styles.containerArrow}
          >
            <IconClose width={11} height={11} color={'#3495CF'} />
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.about}>
            <h1 className={styles.title}>{point?.name}</h1>
          </div>
          <div className={styles.containerCity}>
            <IconMark height={19} width={16} />
            <p className={styles.nameCity}>{routeData.city}</p>
          </div>
          {point?.description && <Text value={point.description} />}
        </div>
        <div className={styles.button}>
          <MainButton
            text="Показать на карте"
            onClick={() => {
              setIsOpenPoint(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PopUpPoint;
