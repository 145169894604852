import React from 'react';
import styles from './EndCreating.module.css';
import MainButton from '../../buttons/MainButton';
import { useNavigate } from 'react-router-dom';

function EndCreating({ clickClose }) {
  const navigate = useNavigate();
 const redirect = () => {
   clickClose()
   navigate('/search');
 }

  return (
    <div onClick={clickClose} className={styles.wrapper}>
      <div className={styles.content}>
        <p className={styles.text}>Еще чуть-чуть!<br/>
          В течение 24 часов ваш маршрут будет опубликован.</p>
        <div className={styles.containerButton}>
          <MainButton onClick={redirect} text={'Перейти на главную'} />
        </div>
      </div>
    </div>
  );
}

export default EndCreating;
