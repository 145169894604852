import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { modalReducer } from './toolkitSlice';
import authSlice from './auth';
import routesSlice from './routes';
import pointsSlice from './points';
import citySlice from './city';
import AudioPlayer from './audioPlayer';

const rootReducer = combineReducers({
  auth: authSlice,
  routes: routesSlice,
  modal: modalReducer,
  city: citySlice,
  points: pointsSlice,
  audioPlayer: AudioPlayer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
