import styles from './Modal.module.css';
import IconClose from '../../images_new/IconClose';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

const modalRoot = document.querySelector('#root-modal');

const Modal = ({ children, onClose }) => {
  const navigate = useNavigate();

  const onCloseModal = (e) => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  };

  return createPortal(
    <>
      <section className={styles.wrapper}>
        <article className={styles.container}>
          <div className={styles.button} onClick={onCloseModal}>
            <IconClose />
          </div>
          {children}
        </article>
      </section>
    </>,
    modalRoot
  );
};

export default Modal;
