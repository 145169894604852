import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './SearchInput.module.css';
import IconMagnifier from '../../../images_new/IconMagnifier';
import IconSettings from '../../../images_new/IconSettings';

export default function SearchInput({ setWordForFilter }) {
  const { register, watch } = useForm();
  const word = watch('city');

  useEffect(() => {
    word ? setWordForFilter(word) : setWordForFilter('');
  }, [word, setWordForFilter]);

  return (
    <form className={styles.containerInput}>
      <button disabled type="submit" className={styles.buttonSearch}>
        <IconMagnifier cursor="pointer" width={23} height={23} />
      </button>
      <input
        className={styles.input}
        {...register('city')}
        placeholder="Введите город или экскурсию"
      />
      <IconSettings />
    </form>
  );
}
