import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  return localStorage.getItem('accessToken') !== null ? (
    children
  ) : (
    <Navigate to="/sign-in" />
  );
  //  return isAuth === true ? children :<Navigate to="/sign-in" />;
}
export default ProtectedRoute;
