import React from 'react';

const IconPlus = ({ width, height, color, bacgraundColor }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="#3495CF" />
      <path
        d="M22.0001 18.3317H17.8334V22.4984C17.8334 22.7194 17.7456 22.9313 17.5893 23.0876C17.4331 23.2439 17.2211 23.3317 17.0001 23.3317C16.7791 23.3317 16.5671 23.2439 16.4108 23.0876C16.2545 22.9313 16.1667 22.7194 16.1667 22.4984V18.3317H12.0001C11.7791 18.3317 11.5671 18.2439 11.4108 18.0876C11.2545 17.9313 11.1667 17.7194 11.1667 17.4984C11.1667 17.2774 11.2545 17.0654 11.4108 16.9091C11.5671 16.7528 11.7791 16.665 12.0001 16.665H16.1667V12.4984C16.1667 12.2774 16.2545 12.0654 16.4108 11.9091C16.5671 11.7528 16.7791 11.665 17.0001 11.665C17.2211 11.665 17.4331 11.7528 17.5893 11.9091C17.7456 12.0654 17.8334 12.2774 17.8334 12.4984V16.665H22.0001C22.2211 16.665 22.4331 16.7528 22.5893 16.9091C22.7456 17.0654 22.8334 17.2774 22.8334 17.4984C22.8334 17.7194 22.7456 17.9313 22.5893 18.0876C22.4331 18.2439 22.2211 18.3317 22.0001 18.3317Z"
        fill="white"
      />
    </svg>
  );
};

export default IconPlus;
