import React, { useEffect, useState } from 'react';
import { YAMAP_API } from '../../../utils/constants';
import './styles.css';
import { Modal } from '@mui/material';
import { Cancel } from '@mui/icons-material';

export default function MapCoord({ open, close, currentTarget }) {
const [coords, setCoords] = useState('');
const [address, setAddress] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    loadScript(
      `https://api-maps.yandex.ru/2.1/?apikey=${YAMAP_API}&lang=ru_RU`,
      () => {
        window.ymaps.ready(init);
      }
    );
  }, []);
const handleClick =()=> {
  currentTarget.value = coords;
  setAddress('');
  setCoords('');
  close();
}
 
  function init() {
    var location = window.ymaps.geolocation.get(   {provider: 'auto'});
    var
      titleCoords = document.getElementById('coords'),
      titleAddress = document.getElementById('address'),
      button = document.getElementById('mapButton'),
      myPlacemark,

      myMap = new window.ymaps.Map("searchMap", {
        center: [47.219228, 39.702295],
        zoom: 16,
        controls: ['searchControl','geolocationControl']
      });
      myMap.options.set({

        // searchControlMaxWidth: [30, 72, 1000],
        searchControlSize: "small"
    });
      // placemark = new window.ymaps.Placemark(
      //   [47.219228, 39.702295],
      //   {
      //     iconCaption: 'Узнать адрес',
      //     hintContent: 'Перетащите метку и кликните, чтобы узнать адрес',
      //   },
      //   {
      //     draggable: 'true',
      //     preset: 'islands',
      //     iconColor: '#1B8086',
      //     // Заставляем балун открываться даже если в нем нет содержимого.
      //     openEmptyBalloon: true,
      //   }
      // );

    // placemark.events.add(
    //   'balloonclose'
    //   , function (e) {
    //     button.classList.remove('visible');
    //     titleAddress.textContent = '';
    //     titleCoords.textContent = '';
    //     placemark.properties.set('balloonContent', '');
    //   })
    // placemark.events.add(
    //   'balloonopen'
    //   , function (e) {
    //     var coords, address, baloonStatusTitle = '';
    //     placemark.properties.set('balloonContent', 'Идет загрузка данных...');
    //     coords = placemark.geometry.getCoordinates();
    //     window.ymaps
    //       .geocode(placemark.geometry.getCoordinates(), {
    //         results: 1,
    //       })
    //       .then(function (res) {
    //         coords = placemark.geometry.getCoordinates();

    //         if (res.geoObjects.get(0)) {
    //           address = res.geoObjects.get(0).properties.get('name');

    //           baloonStatusTitle = "Точка найдена"

    //         } else {
    //           address = 'Не удалось определить адрес.';
    //           baloonStatusTitle = "Адресc не найден"
    //         }
    //         button.classList.add('visible');
    //         setCoords(coords);
    //         setAddress(address);
    //         // titleAddress.textContent = address;
    //         // titleCoords.textContent = `координаты: ${coords}`;
    //         placemark.properties.set('balloonContent', baloonStatusTitle);
    //         // placemark.properties.set('balloonContent', baloonStatusTitle);
    //       });
    //   });
    // myMap.geoObjects.add(placemark);
    location.then(
      function (result) {
        // Добавление местоположения на карту.
        myMap.geoObjects.add(result.geoObjects, { float: 'right' });
      },
      function (err) {
        console.log('Ошибка: ' + err);
      }
    );
    myMap.events.add('click', function (e) {
      const panel = document.getElementById('mapPanel');
      panel.classList.remove('visible');
      var coordinations = e.get('coords');
     
      //центрую карту по клику
      myMap.setCenter(coordinations, 16);
     
      panel.classList.add('visible');
      // Если метка уже создана – просто передвигаем ее.
      if (myPlacemark) {
          myPlacemark.geometry.setCoordinates(coordinations);
          setCoords(coordinations);

      }
      // Если нет – создаем.
      else {
          myPlacemark = createPlacemark(coordinations);
          //  titleAddress.textContent = coordinations;
          myMap.geoObjects.add(myPlacemark);
          
          setCoords(coordinations);
          button.classList.add('visible');
          // Слушаем событие окончания перетаскивания на метке.
          myPlacemark.events.add('dragend', function () {
              getAddress(myPlacemark.geometry.getCoordinates());
        
              setCoords(myPlacemark.geometry.getCoordinates());
          });
      }
      getAddress(myPlacemark.geometry.getCoordinates())
  });
  function getAddress(coords) {
    myPlacemark.properties.set('iconCaption', 'поиск...');
    window.ymaps.geocode(coords).then(function (res) {
        var firstGeoObject = res.geoObjects.get(0);
        titleAddress.textContent = firstGeoObject.getAddressLine();
        myPlacemark.properties
            .set({
                // Формируем строку с данными об объекте.
                iconCaption: [
                    // Название населенного пункта или вышестоящее административно-территориальное образование.
                    firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                    // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                    firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                ].filter(Boolean).join(', '),
                // В качестве контента балуна задаем строку с адресом объекта.
 
              balloonContent: firstGeoObject.getAddressLine()
                
            });
    });
}
  // Создание метки.
  function createPlacemark(coords) {
      return new window.ymaps.Placemark(coords, {
          iconCaption: 'поиск...'
      }, {
          preset: 'islands#violetDotIconWithCaption',
          draggable: true
      });
  }
  }

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{maxWidth: "100%"}}
    >


      <div className="mapCoord__container">
      <Cancel onClick={()=> close()}style={{zIndex: 11, position: "fixed", top:15, right: 15, cursor: "pointer", width:35, height:35}}/>
        <div id="searchMap" className="mapCoord" ></div>
        
        <div id="mapPanel" className={'  fadeInRight mapPanel' }>
          <p id="address">{address}</p>
          {coords? null : <h2 id='point'>Кликните, чтобы узнать адрес</h2>}
          {/* <p id="coords">{coords}</p> */}
          <button id="mapButton" onClick={handleClick} className='searchMap__button'>Выбрать эту точку</button>
        </div>
      </div>
    </Modal>
  );
}
const loadScript = (src, onLoad) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
  script.onload = onLoad;
};
