import React from 'react';
import newEventSuccessImg from '../../../images/newEventSuccessImg.svg';
import { Box, Button, Grid, Link, Typography } from '@mui/material';

const NewEventSuccess = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          direction="column"
          sx={{ marginTop: 6, padding: '0 1.3rem' }}
        >
          <Box
            component="img"
            src={newEventSuccessImg}
            sx={{
              height: '40vh',
              width: '41vh',
              display: 'flex',
              marginBottom: 4,
              alignItems: 'center',
            }}
          />
          <Typography variant="h2" sx={{ marginBottom: 1 }}>
            Экскурсия отправлена
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 4.8, maxWidth: 314, textAlign: 'center' }}
          >
            Событие было создано и уже в очереди на проверку. Оно будет
            проверено в течении 24 часов с момента создания. Спасибо за вклад в
            сообщество!
          </Typography>
          <Button component={Link} href="/search" variant="contained">
            Перейти к экскурсиям
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewEventSuccess;
