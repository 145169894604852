import React, { useState } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { LikeButton } from '../buttons/LikeButton';
import IconMark from '../../images_new/IconMark';
import IconHeadphones from '../../images_new/IconHeadphones';
import IconClock from '../../images_new/IconClock';
import IconMapCardClose from '../../images_new/IconMapcardClose';

function MapCard({ card, activePoint, setActivePoint, index, routes }) {
  const navigate = useNavigate();
  const [slide, setSlide] = useState(0);

  // useEffect(()=> {
  //     const slider = routes !== null && document.querySelector('.mapCard__slider');
  // routes !== null && slider.addEventListener("selectstart", (e) => {
  //   e.preventDefault()
  // })

  // }, [routes]);

  // const [touchPosition, setTouchPosition] = useState(null)

  // const handleTouchStart = (e) => {
  //   const touchDown = e.touches[0].clientX;

  //   setTouchPosition(touchDown);
  // }
  // const handleTouchMove = (e, index) => {

  //   if (touchPosition === null) {
  //     return;
  //   }

  //   const currentPosition = e.touches[0].clientX;
  //   const direction = touchPosition - currentPosition;

  //   if (direction > 1) {

  //     if (activePoint + 1 < routes.length &&  index + 1< routes.length)
  //       setActivePoint(index + 1)
  //     else setActivePoint(0)
  //   }

  //   // else if (direction < -1) {
  //   //   setActivePoint(index - 1)
  //   else setActivePoint(index)
  //   setTouchPosition(null);
  // }

  // const handleMouseDown = (e) => {

  //   const touchDown = e.clientX;

  //   setTouchPosition(touchDown)
  // }
  // const handleMouseUp = (e, index) => {

  //   if (touchPosition === null) {
  //     return;
  //   }

  //   const currentPosition = e.clientX;
  //   const direction = touchPosition - currentPosition;

  //   if (direction > 1) {

  //     if (activePoint + 1 < routes.length &&  index + 1< routes.length)
  //       setActivePoint(index + 1)
  //     else setActivePoint(0)
  //   }

  //   // else if (direction < -1) {
  //   //   setActivePoint(index - 1)
  //   else setActivePoint(index)

  //   setTouchPosition(null);
  // }
  const handleClick = () => {
    navigate(`/route/${card?.id}`);
  };
  return (
    <div className="mapCard__slider">
      <div
        className={index === activePoint ? 'mapCard fadeInDown' : 'mapCard_dis'}

        // onMouseDown={handleMouseDown}
        // onMouseMove={(e) => handleMouseUp(e, index)}

        // onTouchStart={handleTouchStart}
        // onTouchMove={(e)=>handleTouchMove(e,index)}
        //onClick={() => setActivePoint(index + 1)}
      >
        <div className="mapCard__heartWrapper">
          <LikeButton card={card} width={15} height={14} />{' '}
        </div>
        <div className="mapCard__mainWrap" onClick={handleClick}>
          <div className="mapCard__wrapperImage">
            <div className="mapCard__boxImage">
              <img
                className="mapCard__image"
                src={card.image}
                alt={card.name}
              />
            </div>
            <div
              className="mapCard__imageButton"
              onClick={(e) => {
                e.stopPropagation();
                setActivePoint(null);
              }}
            >
              <IconMapCardClose height={6} width={6} />
            </div>
          </div>
          <div className="mapCard__wrapper">
            <div className="mapCard__wrapperDescription">
              <div className="mapCard__wrapperTitle">
                <p className="mapCard__title">{card.name}</p>
              </div>

              <div className="mapCard__wrapperCity">
                <IconMark width={8.36} height={10.15} />
                <p className="mapCard__subtitle">{card.city}</p>
              </div>
            </div>
            <div className="mapCard__wrapperIcon">
              <div className="mapCard__wrapperEndTitle">
                <IconHeadphones width={10.4} height={10.4} />
                <p className="mapCard__IconText">{card.format || 'Аудио'}</p>
                <IconClock width={11} height={11} />
                <p className="mapCard__IconText mapCard__IconTextLeft">1 час</p>
              </div>
              <p className="mapCard__priceTitle">
                {card.price === 0 ? 'Бесплатно' : card.price}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* {isShown && <PopUpLogin close={toggle}/>} */}
    </div>
  );
}
export default MapCard;
