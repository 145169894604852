import React from 'react';

const IconMapCardClose = ({width, height }) => {
  return (
<svg width={width || 6} height={height||6} viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.36246 0.641334C5.32777 0.60657 5.28656 0.57899 5.2412 0.560172C5.19583 0.541354 5.1472 0.531667 5.09809 0.531667C5.04897 0.531667 5.00034 0.541354 4.95498 0.560172C4.90961 0.57899 4.8684 0.60657 4.83371 0.641334L2.99996 2.47133L1.16621 0.637584C1.13149 0.602866 1.09028 0.575326 1.04492 0.556536C0.999554 0.537747 0.950936 0.528076 0.901837 0.528076C0.852738 0.528076 0.80412 0.537747 0.758758 0.556536C0.713396 0.575326 0.67218 0.602866 0.637462 0.637584C0.602743 0.672302 0.575203 0.713519 0.556414 0.75888C0.537625 0.804242 0.527954 0.85286 0.527954 0.901959C0.527954 0.951058 0.537625 0.999676 0.556414 1.04504C0.575203 1.0904 0.602743 1.13162 0.637462 1.16633L2.47121 3.00008L0.637462 4.83383C0.602743 4.86855 0.575203 4.90977 0.556414 4.95513C0.537625 5.00049 0.527954 5.04911 0.527954 5.09821C0.527954 5.14731 0.537625 5.19593 0.556414 5.24129C0.575203 5.28665 0.602743 5.32787 0.637462 5.36258C0.67218 5.3973 0.713396 5.42484 0.758758 5.44363C0.80412 5.46242 0.852738 5.47209 0.901837 5.47209C0.950936 5.47209 0.999554 5.46242 1.04492 5.44363C1.09028 5.42484 1.13149 5.3973 1.16621 5.36258L2.99996 3.52883L4.83371 5.36258C4.86843 5.3973 4.90965 5.42484 4.95501 5.44363C5.00037 5.46242 5.04899 5.47209 5.09809 5.47209C5.14719 5.47209 5.1958 5.46242 5.24117 5.44363C5.28653 5.42484 5.32774 5.3973 5.36246 5.36258C5.39718 5.32787 5.42472 5.28665 5.44351 5.24129C5.4623 5.19593 5.47197 5.14731 5.47197 5.09821C5.47197 5.04911 5.4623 5.00049 5.44351 4.95513C5.42472 4.90977 5.39718 4.86855 5.36246 4.83383L3.52871 3.00008L5.36246 1.16633C5.50496 1.02383 5.50496 0.783834 5.36246 0.641334Z" fill="#979798"/>
</svg>

  );
};

export default (IconMapCardClose);