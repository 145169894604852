import React from 'react';
import './styles.css';
import LikeButton from '../buttons/LikeButton';
import ArrowButton from '../buttons/ArrowButton';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { settingsSlideLending } from '../../utils/constants';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import SharePopup from '../popUps/SharePopup';

const SliderList = ({ card }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="slider-list__container">
      <div className="slider-list__button-container">
        <ArrowButton path="/search" />
        <div className="slider-list__button">
          <LikeButton card={card} />
          <IconButton onClick={handleOpen}>
            <ShareIcon sx={{ color: '#1B8086' }} />
          </IconButton>
        </div>
      </div>
      {/* <Slider {...settingsSlideLending}>
                {data.map((item, index.js) => {
                    return(
                        <img className="slider-list__slider" src={item.image.url} alt={item.alt} key={index.js}/>
                    )
                })}
            </Slider> */}
      <Slider {...settingsSlideLending}>
        {/* <img className="slider-list__slider" src={placeHolder}/> */}
        <img className="slider-list__slider" src={card.image} alt={card.name} />
      </Slider>
      <SharePopup isOpen={isOpen} close={handleClose} />
    </div>
  );
};

export default SliderList;
