import React from 'react';
import styles from './MainButton.module.css';

const MainButton = ({ disabled, text, onClick, style, variant }) => {

  return (
    <button
      disabled={disabled}
      style={style}
      onClick={onClick}
      className={variant === 'transparent' ? styles.button : styles.buttonBlue}
    >
      {text}
    </button>
  );
};

export default MainButton;
