import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Grid, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { grey, red } from '@mui/material/colors';
import { useLocation } from 'react-router-dom';

const EventHeader = ({ marginBottom, openSideBar, deleteClick, saveRoute }) => {
  const userData = useSelector(getUser);
  const location = useLocation();

  return (
    <Grid
      sx={{ marginBottom: marginBottom, alignItems: 'center' }}
      container
      justifyContent="space-between"
    >
      <IconButton>
        <Avatar
          alt="Аватарка"
          src={userData?.avatar}
          sx={{ width: 28, height: 28 }}
          onClick={openSideBar}
        />
      </IconButton>
      <Grid>
        <IconButton sx={{ padding: 0, marginRight: '0.7rem' }}>
          <SaveOutlinedIcon sx={{ color: grey[900] }} onClick={saveRoute} />
        </IconButton>
        {location.pathname !== `/new-event` ? (
          <IconButton sx={{ padding: 0 }}>
            <DeleteOutlineOutlinedIcon
              sx={{ color: red[500] }}
              onClick={deleteClick}
            />
          </IconButton>
        ) : (
          ''
        )}
      </Grid>
    </Grid>
  );
};

export default EventHeader;
