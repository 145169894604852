import React from 'react';
import styles from './PopUpThanks.module.css';
import IconClose from '../../../images_new/IconClose';
import Heart from '../../../images_new/heartThanksBlue.svg';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const PopUpThanks = ({ isOpen, setIsOpenThanks, timer }) => {
  const navigate = useNavigate();

  const clickClose = () => {
    setIsOpenThanks(false);
    navigate('/search');
    clearInterval(timer);
  };

  return (
    <div className={!isOpen ? styles.popup : styles.popup_opened}>
      <div className={styles.container}>
        <div className={styles.btnClose} onClick={clickClose}>
          <IconClose width={17} height={17} />
        </div>
        <h1 className={styles.title}>Спасибо за отзыв!</h1>
        <p className={styles.text}>Вы помогаете нам стать лучше.</p>
        <CSSTransition timeout={1000}>
          <img className={styles.img} src={Heart} alt="heart" />
        </CSSTransition>
      </div>
    </div>
  );
};

export default PopUpThanks;
