import React from 'react';

const IconWalk = ({ width, height, color }) => {
  return (
    <svg
      width={width || 14}
      height={height || 22}
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50057 2.46924C6.50057 1.94709 6.70594 1.44633 7.07149 1.07712C7.43705 0.70791 7.93285 0.500488 8.44983 0.500488C8.9668 0.500488 9.4626 0.70791 9.82816 1.07712C10.1937 1.44633 10.3991 1.94709 10.3991 2.46924C10.3991 2.99138 10.1937 3.49214 9.82816 3.86135C9.4626 4.23057 8.9668 4.43799 8.44983 4.43799C7.93285 4.43799 7.43705 4.23057 7.07149 3.86135C6.70594 3.49214 6.50057 2.99138 6.50057 2.46924ZM5.14015 8.6749C5.09954 8.69131 5.06299 8.70772 5.02238 8.72412L4.69751 8.86768C4.03151 9.16709 3.51983 9.73721 3.28836 10.4345L3.18277 10.7544C2.95536 11.4435 2.22033 11.8126 1.53809 11.5829C0.855846 11.3532 0.49036 10.6108 0.717773 9.92178L0.823358 9.60186C1.28631 8.20322 2.30967 7.06299 3.64166 6.46416L3.96654 6.32061C4.81121 5.94326 5.72493 5.74639 6.65083 5.74639C8.46201 5.74639 10.0945 6.84561 10.7889 8.53135L11.4143 10.0448L12.2834 10.4837C12.925 10.8077 13.1849 11.5952 12.8641 12.2433C12.5433 12.8913 11.7636 13.1538 11.1219 12.8298L10.0336 12.2843C9.61532 12.071 9.28638 11.7183 9.1077 11.2794L8.71785 10.336L7.93409 13.0226L9.94426 15.2374C10.1636 15.4794 10.3179 15.7706 10.3991 16.0905L11.3331 19.8681C11.5077 20.5694 11.0854 21.2831 10.3869 21.4595C9.68842 21.6358 8.98587 21.2093 8.81125 20.5038L7.91784 16.8903L5.04675 13.728C4.44573 13.0677 4.22238 12.1448 4.44979 11.2794L5.13609 8.6749H5.14015ZM2.79292 16.8247L3.80816 14.2653C3.89344 14.3884 3.9909 14.5032 4.09243 14.6181L5.74523 16.4392L5.15639 17.9239C5.05893 18.17 4.91274 18.3956 4.72593 18.5843L2.22033 21.1149C1.71271 21.6276 0.888333 21.6276 0.380714 21.1149C-0.126905 20.6022 -0.126905 19.7696 0.380714 19.2569L2.79292 16.8247Z"
        fill={color || '#3495CF'}
      />
    </svg>
  );
};

export default IconWalk;
