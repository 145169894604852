import React from 'react';

const IconSettings = ({width, height, color }) => {
  return (
    <svg width={width || 19} height={height || 14} viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.539 3.35942H0.814286C0.598324 3.35942 0.391207 3.27093 0.238499 3.11342C0.0857905 2.95592 0 2.74229 0 2.51954C0 2.29679 0.0857905 2.08317 0.238499 1.92566C0.391207 1.76815 0.598324 1.67967 0.814286 1.67967H11.539C11.7076 1.1884 12.0196 0.763108 12.4322 0.462311C12.8448 0.161513 13.3376 0 13.8429 0C14.3481 0 14.8409 0.161513 15.2535 0.462311C15.6661 0.763108 15.9782 1.1884 16.1467 1.67967H18.1857C18.4017 1.67967 18.6088 1.76815 18.7615 1.92566C18.9142 2.08317 19 2.29679 19 2.51954C19 2.74229 18.9142 2.95592 18.7615 3.11342C18.6088 3.27093 18.4017 3.35942 18.1857 3.35942H16.1467C15.9782 3.85068 15.6661 4.27597 15.2535 4.57677C14.8409 4.87757 14.3481 5.03908 13.8429 5.03908C13.3376 5.03908 12.8448 4.87757 12.4322 4.57677C12.0196 4.27597 11.7076 3.85068 11.539 3.35942ZM18.1857 12.3181C18.4017 12.3181 18.6088 12.2296 18.7615 12.0721C18.9142 11.9146 19 11.701 19 11.4782C19 11.2555 18.9142 11.0418 18.7615 10.8843C18.6088 10.7268 18.4017 10.6383 18.1857 10.6383H7.46103C7.29245 10.1471 6.98039 9.72178 6.5678 9.42099C6.15522 9.12019 5.66239 8.95868 5.15714 8.95868C4.6519 8.95868 4.15907 9.12019 3.74648 9.42099C3.3339 9.72178 3.02184 10.1471 2.85326 10.6383H0.814286C0.598324 10.6383 0.391207 10.7268 0.238499 10.8843C0.0857905 11.0418 0 11.2555 0 11.4782C0 11.701 0.0857905 11.9146 0.238499 12.0721C0.391207 12.2296 0.598324 12.3181 0.814286 12.3181H2.85326C3.02134 12.8099 3.33321 13.2357 3.74585 13.537C4.1585 13.8382 4.6516 14 5.15714 14C5.66269 14 6.15579 13.8382 6.56843 13.537C6.98108 13.2357 7.29295 12.8099 7.46103 12.3181H18.1857Z"
            fill={color || '#3495CF'}/>

    </svg>
  );
};

export default (IconSettings);




