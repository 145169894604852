import { useRef } from 'react';
import styles from './PopUpParamsPhoto.module.css';

const PopUpParamsPhoto = ({ isOpen, setIsOpenParamsPhoto, updateData }) => {
  const inputFileRef = useRef();

  const clickClose = () => {
    setIsOpenParamsPhoto(false);
  };

  const onFileChangeCapture = (evt) => {
    const files = Array.from(evt.target.files); // преобразование в массив
    if (files.length > 4) {
      alert(`Разрешено загружать только 4 файла.`);
      return;
    }
    updateData(files);
    setIsOpenParamsPhoto(false);
  };

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <div onClick={clickClose} className={styles.containerPopup_opened}></div>
      <div className={!isOpen ? styles.popup : styles.popup_opened}>
        <div className={styles.container}>
          <button onClick={onBtnClick} className={styles.downloadPfoto}>
            Загрузить фотографии
          </button>
          <p className={styles.text}>*Размер фотографий не более 50МБ</p>
          <p className={styles.text}>**Загрузка не более 4-х фотографий</p>
        </div>
        <input
          onChangeCapture={onFileChangeCapture}
          ref={inputFileRef}
          type="file"
          accept=".png,.jpg,.jpeg,.gif"
          multiple
        />
      </div>
    </>
  );
};

export default PopUpParamsPhoto;
