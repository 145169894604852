export const getRoutesReviews = ({ routes }) => routes.reviews;
export const getRoutesCard = ({ routes }) => routes.selectedCard;
export const getImgRoutes = ({ routes }) => routes.imgRoutes;
export const getCurrentRoute = ({ routes }) => routes.currentRoute;
export const loading = ({ routes }) => routes.loaders.common;
export const getSuccess = ({ routes }) => routes.success;
export const getShopped = ({ routes }) => routes.currentRoute?.is_shopped;
export const getAllRoutes = ({ routes }) => routes.routes;
export const getSearchRoutes = ({ routes }) => routes.searchRoutes;
export const getIsRequestSearchRoutes = ({ routes }) => routes.isRequestSearchRoutes;
export const getAllAuthorRoutes = ({ routes }) => routes.authorRoutes;
export const tokenReview = ({ routes }) => routes.tokenReview;
export const getReviewId = ({ routes }) => routes.reviews.id;
export const getCreateError = ({ routes }) => routes.errors.create;
export const getModerRoutes = ({ routes }) => routes.moderationRoutes;
export const getDrafts = ({ routes }) => routes.draftRoutes;