import React from 'react';

const IconMagnifier = ({ style, width, height, color, cursor }) => {
  return (
    <svg
      style={style}
      cursor={cursor}
      width={width || 25}
      height={height || 26}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4583 20.2917C16.0607 20.2917 19.7917 16.5607 19.7917 11.9583C19.7917 7.35596 16.0607 3.625 11.4583 3.625C6.85596 3.625 3.125 7.35596 3.125 11.9583C3.125 16.5607 6.85596 20.2917 11.4583 20.2917Z"
        stroke={color || '#363636'}
        strokeWidth="1.74394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.875 22.375L17.3438 17.8438"
        stroke={color || '#363636'}
        strokeWidth="1.74394"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconMagnifier;
