import React from 'react';
import styles from '../PopUpPlayer.module.css';
import IconPausePlayer from '../../../../images_new/IconPausePlayer';
import IconPlayPlayer from '../../../../images_new/IconPlayPlayer';
import { togglePlaying, setCurrentAudio } from '../../../../store/audioPlayer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDataAudio,
  getPlaying,
} from '../../../../store/audioPlayer/selectors';
import { getCurrentRoute } from '../../../../store/routes/selectors';

const ControlBtns = ({
  points,
  activePoint,
  setActivePoint,
  size = 'full',
}) => {
  const dataAudio = useSelector(getDataAudio);
  const playing = useSelector(getPlaying);
  const routeData = useSelector(getCurrentRoute);
  const dispatch = useDispatch();

  const togglePlay = () => {
    (dataAudio.name !== 'Вступление' ||
      (dataAudio.name === 'Вступление' && !playing)) &&
      dispatch(togglePlaying());
    dataAudio.name === 'Вступление' &&
      dispatch(setCurrentAudio(routeData.points[activePoint]));
  };

  const playClassName =
    size === 'small' ? `${styles.play} ${styles.play_s}` : styles.play;
  const prevClassName =
    size === 'small'
      ? `${styles.control_s} ${styles.prev_s}  ${styles.prev}`
      : styles.prev;
  const nextClassName =
    size === 'small'
      ? `${styles.control_s} ${styles.next_s}  ${styles.next}`
      : styles.next;

  return (
    <div className={styles.containerButton}>
      <button
        disabled={activePoint === 0 || !dataAudio.id}
        onClick={() => setActivePoint(activePoint - 1)}
        className={prevClassName}
      ></button>
      <button className={playClassName} onClick={togglePlay}>
        {playing && dataAudio.name !== 'Вступление' ? (
          size === 'small' ? (
            <IconPausePlayer width={40} height={40} />
          ) : (
            <IconPausePlayer />
          )
        ) : size === 'small' ? (
          <IconPlayPlayer width={40} height={40} />
        ) : (
          <IconPlayPlayer />
        )}
      </button>
      <button
        disabled={activePoint + 1 >= points || !dataAudio.id}
        onClick={() => setActivePoint(activePoint + 1)}
        className={nextClassName}
      ></button>
    </div>
  );
};

export default ControlBtns;
