import React from 'react';
import styles from './PopUpError.module.css';
import MainButton from '../../buttons/MainButton';

function PopUpError({ clickClose }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p className={styles.text}>
          Упс! Что-то пошло не так, но мы уже разбираемся в проблеме.
        </p>
        <p className={styles.textSecondary}>
          Попробуйте создать свой маршрут в Google Chrome
        </p>
        <MainButton onClick={clickClose} text="Принять" />
      </div>
    </div>
  );
}

export default PopUpError;
