import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews } from '../../store/routes';
import { getRoutesReviews } from '../../store/routes/selectors';
import Review from '../Review';
import styles from './ReviewList.module.css';
import { map } from 'lodash';

const ReviewList = ({ card }) => {
  const customEqual = (oldValue, newValue) => newValue === null;
  const reviewsData = useSelector(getRoutesReviews, customEqual);
  const [reviewList, setReviewList] = useState(reviewsData);
  const dispatch = useDispatch();
  const [moreReview, setMoreReview] = useState(false);

  useEffect(() => {
    dispatch(getReviews(card.id));
  }, [card]);

  useEffect(() => {
    if (reviewsData?.length > 0) {
      let reviewCountData =
        moreReview === true || reviewsData.length < 2
          ? reviewsData
          : reviewsData.slice(0, 2);
      setReviewList(reviewCountData);
    }
  }, [moreReview, reviewsData]);

  return (
    <>
      <div className={styles.containerList}>
        {map(reviewList, (review) => (
          <Review
            review={review}
            card={card}
            key={review.id}
            setReviewList={setReviewList}
            reviewsData={reviewsData}
          />
        ))}
      </div>
      <button
        className={
          moreReview === false && reviewsData?.length > 2
            ? styles.show
            : styles.button_click
        }
        onClick={() => setMoreReview(true)}
      >
        Загрузить еще
      </button>
      <button
        className={
          moreReview === true && reviewsData?.length > 2
            ? styles.show
            : styles.button_click
        }
        onClick={() => setMoreReview(false)}
      >
        Свернуть отзывы
      </button>
    </>
  );
};

export default ReviewList;
