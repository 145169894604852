import React from 'react';
import styles from './Agreement.module.css';
import MainButton from '../../buttons/MainButton';

function Agreement({ setStep }) {
  return (
    <div>
      <ol className={styles.list}>
        <li>
          Предлагая Услуги сервиса «КиберГид», ООО «КиберГид» обеспечивает
          авторов контента необходимыми для создания маршрутов по городу и/или
          аудио гидов приложениями: системой управления контентом, мобильной
          версией сервиса, шаблонами и документацией, включая инструкции и
          рекомендации.
        </li>
        <li>
          Контент создается его автором независимо от и без участия ООО
          «КиберГид».
        </li>
        <li>
          Услуги сервиса «КиберГид» предлагаются поставщику контента
          безвозмездно. ООО «КиберГид» оставляет за собой право взимать плату за
          некоторые дополнительные возможности, не являющиеся обязательными к
          использованию автором контента.
        </li>
        <li>
          Для конечных пользователей контент может предоставляться как
          бесплатно, так и на платной основе. Решение о взимании или не взимании
          платы за предоставляемый контент принимается его автором. В случае
          необходимости взимать плату за контент, получаемый доход будет
          разделен между автором контента, ООО «КиберГид» и возможным
          посредником в продаже.
        </li>
        <li>
          Полный контроль над контентом остается за его автором: ему принадлежат
          авторские права на такой контент, равно как и право в любой момент
          добавлять, менять или убирать контент.
        </li>
        <li>
          На время использования Услуги сервиса «КиберГид» автор контента дает
          ООО «КиберГид» право использовать указанный контент, включать его в
          свои базы данных и предоставлять доступ к таким базам третьей стороне.
        </li>
        <li>
          Автор контента несет единоличную ответственность за предоставляемый
          контент (право на объект интеллектуальной собственности, достоверность
          информации, нарушение прав третьей стороны, дискриминация и т. д.).
          ООО «КиберГид» не несет ответственности за любые претензии
          относительно указанного контента, заявленные третьей стороной.
        </li>
        <li>
          ООО «КиберГид» оставляет за собой право в некоторых случаях
          модерировать или удалять контент или блокировать доступ к нему,
          например, в случае обнаружения нарушения прав третьей стороны или
          государства. Также ООО «КиберГид» может рекомендовать
          доработать/отредактировать контент, если в нем будут обнаружены
          фактические ошибки и неточности.
        </li>
        <li>
          ООО «КиберГид» настоятельно рекомендует автору контента максимально
          широко использовать свои маркетинговые каналы для эффективного
          информирования своих пользователей о наличии маршрута и аудио гида на
          сервисе «КиберГид».
        </li>
        <li>
          ООО «КиберГид» не несет ответственности за возможные сбои в работе
          Услуги сервиса «КиберГид», но обязуется приложить максимальные усилия
          для предотвращения и исправления таких сбоев.
        </li>
        <li>
          ООО «КиберГид» оставляет за собой право периодически менять условия
          Пользовательского соглашения на Услуги сервиса «КиберГид». Мы уведомим
          пользователей об изменениях в условиях настоящего Пользовательского
          соглашения. Продолжая пользоваться Услугами сервиса «КиберГид»,
          пользователь подтверждает своё согласие с внесенными изменениями.
        </li>
      </ol>
      <div className={styles.containerButton}>
        <MainButton onClick={() => setStep(1)} text={'Принять'} />
      </div>
    </div>
  );
}

export default Agreement;
