class AssertionError extends Error {
  constructor(message) {
    super(message)
    this.name = "AssertionError"
  }
}

export default function assert(value, message) {
  if (!value) {
    throw new AssertionError(message)
  }
}