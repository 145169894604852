import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const AddButton = styled('label')(({ theme }) => ({
  backgroundColor: '#ECFF9F',
  width: 74,
  height: 71,
  borderRadius: 8,
  color: grey[600],
  display: 'flex',
  flexDirection: 'column',
  '&:hover': { color: '#94AB07' },
  '&:active': { color: '#94AB07' },
  cursor: 'pointer',
}));
