import React from 'react';
import { alpha, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { Controller } from 'react-hook-form';

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    maxHeight: 40,
    fontSize: 16,
    border: '1px solid #AAAAAA',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system fontSfPro instead of the default Roboto fontSfPro.
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StandardInput = ({
  validation,
  control,
  text,
  name,
  marginBottom,
  placeholder,
  defaultValue,
}) => {
  return (
    <Box marginBottom={marginBottom} sx={{ width: '100%' }}>
      <Controller
        control={control}
        name={name}
        rules={validation}
        render={({ field }) => (
          <>
            <InputLabel
              sx={{ fontSize: 26, color: grey[900] }}
              shrink
              htmlFor={name}
            >
              {text}
            </InputLabel>
            <StyledInput
              onChange={(e) => field.onChange(e)}
              value={field.value}
              defaultValue={defaultValue}
              fullWidth
              id={name}
              placeholder={placeholder}
            />
          </>
        )}
      />
    </Box>
  );
};

export default StandardInput;
