import React from 'react';
import { AddButton } from './styles';
import { map } from 'lodash';
import { Box } from '@mui/material';
import { ImageEvent } from '../../../containers/newEvents/NewEvent/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import WallpaperRoundedIcon from '@mui/icons-material/WallpaperRounded';

const ImgInputSmall = ({
  name,
  register,
  watch,
  resetField,
  validation,
}) => {
  return (
    <>
      <AddButton htmlFor={name} >
        <WallpaperRoundedIcon sx={{ width: '100%', height: '100%', color: '#C6F04E' }} />
      </AddButton>
      <input
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
        id={name}
        {...register(name, validation)}
        type="file"
        name={name}
        accept="/image*,.png,.jpg,.jpeg,.gif"
      />
      {map(watch(name), (image, index) => (
        <Box sx={{ position: 'relative' }} key={index}>
          <ImageEvent src={URL.createObjectURL(image)} alt="Картинка" />
          <ClearOutlinedIcon
            onClick={() => {
              resetField(name);
            }}
            sx={{
              position: 'absolute',
              width: '20px',
              height: '20px',
              left: '3.3rem',
              cursor: 'pointer',
              color: '#1B8086',
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default ImgInputSmall;
