import React from 'react';

const playerPause = ({ width = 58, height = 58, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_800_1269)">
        <path
          d="M28.8333 57.8333C12.8166 57.8333 -0.166672 44.85 -0.166672 28.8333C-0.166672 12.8166 12.8166 -0.166656 28.8333 -0.166656C44.85 -0.166656 57.8333 12.8166 57.8333 28.8333C57.8333 44.85 44.85 57.8333 28.8333 57.8333ZM28.8333 52.0333C34.9864 52.0333 40.8874 49.5891 45.2382 45.2382C49.5891 40.8874 52.0333 34.9864 52.0333 28.8333C52.0333 22.6803 49.5891 16.7793 45.2382 12.4285C40.8874 8.07762 34.9864 5.63334 28.8333 5.63334C22.6803 5.63334 16.7793 8.07762 12.4285 12.4285C8.07761 16.7793 5.63333 22.6803 5.63333 28.8333C5.63333 34.9864 8.07761 40.8874 12.4285 45.2382C16.7793 49.5891 22.6803 52.0333 28.8333 52.0333ZM20.1333 20.1333H25.9333V37.5333H20.1333V20.1333ZM31.7333 20.1333H37.5333V37.5333H31.7333V20.1333Z"
          fill="#3495CF"
        />
      </g>
      <defs>
        <clipPath id="clip0_800_1269">
          <rect width="58" height="58" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default playerPause;
