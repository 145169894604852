import React from 'react';
import newEventFailImg from '../../images/newEventFailImg.svg';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const PaymentFail = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const subString1 = location.search.indexOf('shp_route') + 10;
    const subString2 = location.search.indexOf('Culture') -1;
    const id  = Number(location.search.slice(subString1, subString2));

    const navigate = useNavigate();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          direction="column"
          sx={{ marginTop: 6, padding: '0 1.3rem' }}
        >
          <Box
            component="img"
            src={newEventFailImg}
            sx={{
              height: '40vh',
              width: '41vh',
              display: 'flex',
              marginBottom: 4,
              alignItems: 'center',
            }}
          />
          <Typography variant="h2" sx={{ marginBottom: 20 }}>
            Оплата прошла не удачно 
          </Typography>

          <Button  onClick={()=>navigate(`/route/${id}`) } variant="contained">
            Перейти к экскурсии
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentFail;