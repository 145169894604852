import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const theme = createTheme({
  typography: {
    h1: {
      fontSize: 24,
      fontWeight: 600,
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 18,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 24,
      fontWeight: 600,
    },
    subtitle3: {
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle4: {
      fontSize: 18,
      fontWeight: 600,
    },
    body: {
      fontSize: 20,
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 18,
      fontWeight: 400,
    },
    button: {
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'capitalize',
    },
    caption: {
      fontSize: 14,
      fontWeight: 400,
    },
    caption2: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            textTransform: 'none',
            backgroundColor: '#C6F04E',
            color: grey[900],
            '&:hover': {
              backgroundColor: '#94AB07',
            },
            borderRadius: 45,
            minWidth: 240,
            minHeight: 56,
            width: '100%',
          },
        },
        {
          props: { variant: 'containedCard' },
          style: {
            textTransform: 'none',
            backgroundColor: '#ECFF9F',
            color: grey[900],
            '&:hover': {
              backgroundColor: '#ECFF9F',
            },
            borderRadius: 41,
            minWidth: 240,
            minHeight: 49,
            width: '100%',
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            textTransform: 'none',
            color: grey[900],
            border: `3px solid ${grey[500]}`,
            borderRadius: 16,
            minWidth: 240,
            minHeight: 56,
            width: '100%',
          },
        },
        {
          props: { variant: 'outlinedHalf' },
          style: {
            textTransform: 'none',
            backgroundColor: '#FFFFFF',
            color: grey[900],
            borderRadius: 45,
            minWidth: 152,
            minHeight: 56,
            boxShadow: 15,
          },
        },
        {
          props: { variant: 'reviews' },
          style: {
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            marginBottom: 24,
            padding: 0,
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'outlinedProfile' },
          style: {
            textTransform: 'none',
            backgroundColor: '#C6F04E',
            color: grey[900],
            borderRadius: 16,
            minWidth: 152,
            minHeight: 56,
          },
        },
        {
          props: { variant: 'containedProfile' },
          style: {
            textTransform: 'none',
            backgroundColor: '#C6F04E',
            color: grey[900],
            borderRadius: 40,
            minWidth: 266,
            minHeight: 56,
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: grey[900],
          textDecorationColor: grey[900],
        },
      },
    },
  },
});

export const ButtonSwitch = styled((props) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'black',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? grey[600] : grey[900],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
