import styles from './InfoContainer.module.css';
import { Link } from 'react-router-dom';

const InfoContainer = ({
  code,
  children,
  linkText,
  link,
  external = false,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.codeWrapper}>
        <p className={styles.code}>{code}</p>
      </div>
      <div className={styles.info}>{children}</div>
      {external ? (
        <a className={styles.link} href={link} rel="noopener noreferrer">
          {linkText}
        </a>
      ) : (
        <Link className={styles.link} to={link}>
          {linkText}
        </Link>
      )}
    </div>
  );
};

export default InfoContainer;
