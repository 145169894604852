import { each } from 'lodash/collection';

const formDataCombiner_2 = (data, fd) => {
  each(data, (item, key) => {
    const curentKey = key.replace(/([0-9]+)/g, '[$1]');
    if (typeof item === 'object') {
      if (item[0] !== undefined) {
        fd.set(curentKey, item[0]);
      }
    } else {
      fd.set(curentKey, item);
    }
  });
};

export default formDataCombiner_2;
