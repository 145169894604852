import React from 'react';

const IconDocument = ({ width, height, color }) => {
  return (
    <svg
      width={width || '23'}
      height={height || '20'}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.875 0.882812H14.625C14.0028 0.883642 13.3907 1.04051 12.8447 1.33907C12.2988 1.63764 11.8364 2.06835 11.5 2.5918C11.1636 2.06835 10.7012 1.63764 10.1553 1.33907C9.60934 1.04051 8.99724 0.883642 8.375 0.882812H2.125C1.7624 0.882812 1.41465 1.02686 1.15825 1.28325C0.901855 1.53965 0.757813 1.8874 0.757812 2.25V14.75C0.757812 15.1126 0.901855 15.4603 1.15825 15.7167C1.41465 15.9731 1.7624 16.1172 2.125 16.1172H8.375C9.04761 16.1198 9.69194 16.3881 10.1675 16.8637C10.6432 17.3393 10.9115 17.9836 10.9141 18.6562C10.9141 18.8117 10.9758 18.9607 11.0857 19.0706C11.1956 19.1805 11.3446 19.2422 11.5 19.2422C11.6554 19.2422 11.8044 19.1805 11.9143 19.0706C12.0242 18.9607 12.0859 18.8117 12.0859 18.6562C12.0885 17.9836 12.3568 17.3393 12.8325 16.8637C13.3081 16.3881 13.9524 16.1198 14.625 16.1172H20.875C21.2376 16.1172 21.5853 15.9731 21.8417 15.7167C22.0981 15.4603 22.2422 15.1126 22.2422 14.75V2.25C22.2422 1.8874 22.0981 1.53965 21.8417 1.28325C21.5853 1.02686 21.2376 0.882812 20.875 0.882812ZM8.375 14.9453H2.125C2.0732 14.9453 2.02352 14.9247 1.98689 14.8881C1.95027 14.8515 1.92969 14.8018 1.92969 14.75V2.25C1.92969 2.1982 1.95027 2.14852 1.98689 2.11189C2.02352 2.07526 2.0732 2.05469 2.125 2.05469H8.375C9.04761 2.05726 9.69194 2.32559 10.1675 2.8012C10.6432 3.27681 10.9115 3.92114 10.9141 4.59375V15.9512C10.2269 15.3045 9.31863 14.9447 8.375 14.9453ZM21.0703 14.75C21.0703 14.8018 21.0497 14.8515 21.0131 14.8881C20.9765 14.9247 20.9268 14.9453 20.875 14.9453H14.625C13.681 14.9427 12.772 15.3028 12.0859 15.9512V4.59375C12.0885 3.92114 12.3568 3.27681 12.8325 2.8012C13.3081 2.32559 13.9524 2.05726 14.625 2.05469H20.875C20.9268 2.05469 20.9765 2.07526 21.0131 2.11189C21.0497 2.14852 21.0703 2.1982 21.0703 2.25V14.75Z"
        fill={color || '#979798'}
      />
    </svg>
  );
};

export default IconDocument;
