import { useEffect, useState } from 'react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Avatar, Button, Grid, Link, Rating, Typography } from '@mui/material';
import SliderList from '../../../../components/SliderList';
import PointsStandart from '../../../../components/PointsStandart';
import ReviewList from '../../../../components_new/ReviewList';
import CardReviewForm from '../../../../components/forms/CardReviewForm';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { buyRoute, deleteRoute } from '../../../../store/routes';
import { getUser, isAuthentication } from '../../../../store/auth/selectors';
import { getRoutesReviews, getShopped } from '../../../../store/routes/selectors';
import { map } from 'lodash';
import PamentButton from '../../../../components/PamentButton';

const CurrentRoute = ({ card }) => {
  const user = useSelector(getUser);
  const shopped = useSelector(getShopped);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [textButton, setTextButton] = useState('');
  const reviewsData = useSelector(getRoutesReviews);
  const userRoute = card?.author?.id === user?.id;
  const location = useLocation();

  const handleBuyButtonClick = () => {
    if (isAuthentication === false) {
      navigate('/sign-up');
    }
    dispatch(buyRoute(card.id));
  };

  const handleTransitionButtonClick = () => {
    navigate(`/card-audio/${card.id}`);
  };

  const deleteRouteClick = () => {
    dispatch(deleteRoute(card.id));
    navigate('/new-event-pages');
  };

  useEffect(() => {
    if (!isAuthentication) return;
    setTextButton(
      userRoute || shopped || card?.price === 0
        ? 'Начать маршрут'
        : `Купить за ${card?.price}`
    );
  }, [userRoute, shopped, card]);

  // useEffect(() => {
  //   dispatch(getReviews(card.id));
  // }, []);

  // useEffect(() => {
  //   map(card.points, (item, index) =>
  //     item.file?.includes('mp') ? setAudioCounter(audioCounter + 1) : ''
  //   );
  // }, [card]);

  const openEditingClick = () => {
    navigate(`/edit-route/${card.id}`, { state: card });
  };

  const fileSummary = () => {
    //переделать потом с помощью sort и arr.length
    let summary = 0;
    map(card.points, (item, index) =>
      item.file?.includes('mp') ||
      item.file?.includes('ogg') ||
      item.file?.includes('wav') ||
      item.file?.includes('MOV')
        ? (summary = summary + 1)
        : ''
    );
    return summary;
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <SliderList card={card} />
        <Grid container sx={{ padding: '0 2.7vh 7vh', marginTop: 3 }}>
          <Typography variant="h1" sx={{ marginBottom: 2.5 }}>
            {card.name}
          </Typography>
          <Grid
            container
            direction="column"
            sx={{ gap: 1.5, marginBottom: 4.8 }}
          >
            <Grid
              container
              direction="row"
              sx={{ gap: 1.5, alignItems: 'center' }}
            >
              <AccessTimeOutlinedIcon
                alt="Аудио и видео"
                sx={{ color: '#1B8086' }}
              />
              {/* ПЕРЕДЕЛАТЬ , попросить у бека передавать тип файла!!! */}
              <Typography variant="subtitle1">
                {card.points[0]?.file?.includes('mp3') ||
                card.points[0]?.file?.includes('MOV') ||
                card.points[0]?.file?.includes('ogg') ||
                card.points[0]?.file?.includes('mpeg') ||
                card.points[0]?.file?.includes('mp4') ||
                card.points[0]?.file?.includes('wav')
                  ? ` ${fileSummary()} аудио`
                  : 'Нет аудио и видео'}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ gap: 1.5, flexWrap: 'nowrap' }}
            >
              <FmdGoodOutlinedIcon
                alt="Название места"
                sx={{ color: '#1B8086' }}
              />
              <Typography sx={{ fontSize: 18 }}>{card.city}</Typography>
            </Grid>
            <Grid container direction="row" sx={{ gap: 1.5 }}>
              <ShoppingCartOutlinedIcon
                alt="Цена экскурсии"
                sx={{ color: '#1B8086' }}
              />
              <Typography variant="subtitle1">
                Цена экскурсии {card.price}р.
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column">
            <Typography variant="h2" sx={{ marginBottom: 0.3 }}>
              Описание
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: 2.5 }}>
              {card.description}
            </Typography>
            <PointsStandart card={card} user={userRoute} />
            {/*<Link sx={{ display: 'block', textAlign: 'center' }}  onClick={() => copy(`${document.location.href}`)} href="#">*/}
            {/*  Поделиться маршрутом*/}
            {/*</Link>*/}
          </Grid>
          <Grid container sx={{ marginTop: 5.7, gap: 1, marginBottom: 3.8 }}>
            <Grid
              container
              direction="row"
              sx={{ gap: 1.5, alignItems: 'center' }}
            >
              <Avatar
                alt="Аватар"
                src={card.author.avatar}
                sx={{ width: 56, height: 56 }}
              />
              <div className="card__user-info">
                <Grid container sx={{ alignItems: 'center' }}>
                  <Typography variant="subtitle1">
                    {card.rating || 0}/5
                  </Typography>
                  <Rating
                    defaultValue={card.rating}
                    sx={{
                      marginLeft: '4px',
                      '& .MuiRating-iconFilled': { color: '#1B8086' },
                    }}
                    readOnly
                  />
                </Grid>
                <Typography variant="h2">Организатор</Typography>
                <Typography variant="subtitle1">
                  {card.author.first_name}
                </Typography>
              </div>
            </Grid>
            <Typography variant="subtitle1">
              {card.author.description}
            </Typography>
          </Grid>
          {location.pathname !== `/moderation-route/${card.id}` ? (
            <>
              {location.pathname !== `/draft-route/${card.id}` ? (
                <>
                  <Typography variant="h2" sx={{ marginBottom: 2 }}>
                    Отзывы
                  </Typography>
                  <ReviewList card={card} />
                  <Grid container direction="column" sx={{ marginBottom: 3.5 }}>
                    <Typography variant="h2" sx={{ marginBottom: 2 }}>
                      Оставить отзыв
                    </Typography>
                    <CardReviewForm card={card} />
                  </Grid>
                  <Grid container direction="column" alignItems="center">
                    {shopped || userRoute || card?.price === 0
                      ? user !== null && (
                          <Button
                            sx={{
                              position: 'fixed',
                              bottom: '0',
                              width: '90%',
                              maxWidth: '400px',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                            }}
                            variant="contained"
                            component={Button}
                            onClick={handleTransitionButtonClick}
                            //href={card.is_shoppend === false ? '' : }
                          >
                            Начать экскурсию
                          </Button>
                        )
                      : user !== null && (
                          <PamentButton card={card} buttonStyle={'fixed'} />
                        )}
                    {user === null && (
                      <Button
                        sx={{
                          position: 'fixed',
                          bottom: '0',
                          width: '90%',
                          maxWidth: '400px',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                        variant="contained"
                        component={Button}
                        onClick={() => navigate(`/sign-up`)}
                      >
                        Регистрация
                      </Button>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid container direction="column" sx={{ padding: '0 0.7rem' }}>
                  {/*<Button
                    variant="contained"
                    onClick={() => openEditingClick(card)}
                  >
                    Перейти к редактированию
                </Button>*/}
                  <Link
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      marginTop: 2,
                      cursor: 'pointer',
                    }}
                    onClick={deleteRouteClick}
                  >
                    Удалить маршрут
                  </Link>
                </Grid>
              )}
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentRoute;
