import { useEffect, useState } from 'react';
import { AllCity } from '../../../store/city/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCity } from '../../../store/city';
import styles from './SearchCity.module.css';
import { filter, map } from 'lodash';

const SearchCity = ({ text, setText, setCityCoords, register, errors }) => {
  const allCity = useSelector(AllCity);
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);

  const onChangeHandler = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = filter(allCity, (item) => {
        const regex = new RegExp(`${text}`);
        return item.name.match(regex);
      });
    }
    setSuggestions(matches);
    setText(text);
  };

  const onSuggestHandler = (selectCity) => {
    setText(selectCity.name);
    let coordsArr=[];
    coordsArr.push(  selectCity.coords_lat, selectCity.coords_lon);
    setCityCoords(coordsArr);
    setSuggestions([]);
  };

  useEffect(() => {
    dispatch(getAllCity());
  }, []);
  return (
    <div className={styles.container}>
      <input
        className={`${styles.inputCity} ${errors.city && styles.error}`}
        placeholder="Выберите город"
        type="text"
        // onChange={(evt) => onChangeHandler(evt.target.value)}
        {...register("city", {
          onChange: (e)=>onChangeHandler(e.target.value),
          required: 'А в каком городе?',
          pattern: {
           value: /^[а-яА-Я-]+$/i
          }
        })}
        value={text}
      />
      <div
        className={
          suggestions.length > 0
            ? styles.containerCity
            : styles.containerCityHidden
        }
      >
        {map(suggestions, (suggestion, i) => {
          return (
            <p
              key={i}
              className={styles.text}
              onClick={() => onSuggestHandler(suggestion)}
            >
              {suggestion.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default SearchCity;
