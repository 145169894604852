import React from 'react';
import styles from './PopUpGo.module.css';
import PopUpPlayer from '../PopUpPlayer';

const PopUpGo = ({
  route,
  activePoint,
  setActivePoint,
  setIsOpenPoint,
  setPoint,
  handleClickBtnMap
}) => {
  const handlePopupClick = () => {
    // handleClickBtnMap()
    setIsOpenPoint(true);
    setPoint(route.points[activePoint]);
  };

  return (
    <div className={styles.pointPopupWrapper}>
      <div className={styles.pointPopup} onClick={handlePopupClick}>
        <img
          className={styles.pointPopup__img}
          src={route.points[activePoint].points_media[0].photo}
          alt={route.points[activePoint].name}
        ></img>
        <div className={styles.pointPopup__player}>
          <p className={styles.pointPopup__title}>
            {route.points[activePoint].name}
          </p>
          <PopUpPlayer
            activePoint={activePoint}
            setActivePoint={setActivePoint}
            points={route.points.length}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default PopUpGo;
