import styles from './Publications.module.css';
import Container from '../../components_new/Container';
import DefaultView from '../../components_new/DefaultView';
import ExcursionList from '../../components/ExcursionList';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllRoutes } from '../../store/routes/selectors';
import { getUser } from '../../store/auth/selectors';
import { useState } from 'react';

const Publications = () => {
  const routes = useSelector(getAllRoutes);
  const user = useSelector(getUser);
  const [renderRoutes, setRenderRoutes] = useState([]);

  useEffect(() => {
    routes &&
      setRenderRoutes(routes.filter((item) => item.author.id === user.id));
  }, [routes, user]);

  return (
    <Container title="Мои публикации">
      {renderRoutes?.length > 0 ? (
        <div className={styles.container}>
          <ExcursionList
            type="publications"
            routes={renderRoutes}
            path={`/route/`}
          />
        </div>
      ) : (
        <DefaultView text="ваши опубликованные маршруты" />
      )}
    </Container>
  );
};

export default Publications;
