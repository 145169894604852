import { useEffect, useState } from 'react';

const useCarousel = (images, delay) => {
    const [index, setIndex] = useState(0);
    const [timer, setTimer] = useState(null);

    const onNext = () => {
        setIndex((index + 1) % images.length);
    };

    const onPrev = () => {
        setIndex((index - 1 + images.length) % images.length);
    };

    const onIndicatorClick = (i) => {
        setIndex(i);
    };

    useEffect(() => {
        const tick = () => {
            setIndex((index + 1) % images.length);
        };
        const id = setInterval(tick, delay);
        setTimer(id);
        return () => clearInterval(id);
    }, [index, images.length, delay]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                clearInterval(timer);
            } else {
                const id = setInterval(() => {
                    setIndex((index + 1) % images.length);
                }, delay);
                setTimer(id);
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);
    }, [index, images.length, delay, timer]);

    return {
        image: images[index].src,
        title: images[index].title,
        subtitle: images[index].subtitle,
        textButton: images[index].textButton,
        indicators: images.map((_, i) => i === index),
        onNext,
        onPrev,
        onIndicatorClick,
        setIndex,
    };
}

export default useCarousel;