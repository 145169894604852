import React from 'react';
import styles from '../PopUpPlayer.module.css';

const PlaybackSlider = ({
  progress,
  currentTime,
  duration,
  onChange,
  size = 'full',
}) => {
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const timeClassName = size === 'small' ? styles.time_s : styles.time;
  const containerClassName =
    size === 'small'
      ? `${styles.containerTime} ${styles.containerTime_s}`
      : styles.containerTime;

  return (
    <div>
      <input
        className={styles.progressInput}
        type="range"
        min={0}
        max={100}
        step={1}
        value={progress}
        onChange={onChange}
      />
      <div className={containerClassName}>
        <p className={timeClassName}>{formatTime(currentTime) || '0:00'}</p>
        <p className={timeClassName}>{formatTime(duration) || '0:00'}</p>
      </div>
    </div>
  );
};

export default PlaybackSlider;
