import React from 'react';
import styles from './Tooltip.module.css';

const Tooltip = ({ visible }) => {
  return (
    <>
      <span className={styles.tooltip}>
        <h2 className={styles.tooltipTitle}>Стоимость маршрута</h2>
        <p className={styles.tooltipText}>
          Маршрут может быть и бесплатным, но если указываете стоимость,
          учитывайте комиссию платформы Кибергид.
        </p>
      </span>
    </>
  );
};

export default Tooltip;
