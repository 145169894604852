import React, { useState } from 'react';
import { YAMAP_API } from '../../utils/constants';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  Clusterer,
  GeolocationControl,
  Map,
  Placemark,
  YMaps,
} from '@pbe/react-yandex-maps';
import { getAllRoutes } from '../../store/routes/selectors';
import MapCard from '../MapCard';
import iconMapDis from '../../images_new/map_icon_dis.svg';
import iconMap from '../../images_new/map_icon.svg';

export default function MainMap() {
  const dispatch = useDispatch();

  const [activePoint, setActivePoint] = useState(null);
  const routes = useSelector(getAllRoutes);
  //const centerCoords = !routes? [47.26315812409134, 39.66601187052418]: routes[0].points[0].point_coords.split(',');
  const centerCoords =
    !routes || routes[0].points.length === 0
      ? [47.26315812409134, 39.66601187052418]
      : activePoint === null
      ? routes[0].points[0].point_coords.split(',')
      : routes[activePoint].points[0].point_coords.split(',');
  const mapState = { center: centerCoords, zoom: 10, controls: [] };

  return (
    <div className="map__container">
      <YMaps query={{ lang: 'ru_RU', apikey: YAMAP_API }}>
        <Map defaultState={mapState} className="mapAllRoutes">
          <Clusterer
            options={{
              preset: 'islands#invertedVioletClusterIcons',
              groupByCoordinates: true,
              clusterDisableClickZoom: true,
              clusterHideIconOnBalloonOpen: true,
              geoObjectHideIconOnBalloonOpen: true,
            }}
          >
            {routes?.map((el, i) =>
              el.points.length > 0 ? (
                <Placemark
                  onClick={() => setActivePoint(i)}
                  key={el.id + 'mapPoint'}
                  geometry={[
                    el.points[0].point_coords.split(',')[0],
                    el.points[0].point_coords.split(',')[1],
                  ]}
                  options={
                    i === activePoint
                      ? {
                          iconLayout: 'default#image',
                          iconImageHref: iconMap,
                          iconImageSize: [31, 40],
                        }
                      : {
                          iconLayout: 'default#image',
                          iconImageHref: iconMapDis,
                          iconImageSize: [31, 40],
                        }
                  }
                />
              ) : (
                ''
              )
            )}
          </Clusterer>
          <GeolocationControl options={{ float: 'left' }} />
        </Map>
      </YMaps>

      {routes?.map((item, index) =>
        index === activePoint ? (
          <MapCard
            key={item.id + 'mapCard'}
            activePoint={activePoint}
            index={index}
            setActivePoint={setActivePoint}
            card={item}
            routes={routes}
          />
        ) : (
          ''
        )
      )}
    </div>
  );
}
