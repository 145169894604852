import React from 'react';

const IconPause = ({ width, height, color }) => {
  return (
    <svg width={width || 10} height={height || 12} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33337 11C3.33337 11.221 3.24558 11.4329 3.0893 11.5892C2.93302 11.7455 2.72105 11.8333 2.50004 11.8333H1.66671C1.44569 11.8333 1.23373 11.7455 1.07745 11.5892C0.921172 11.4329 0.833374 11.221 0.833374 11V0.999959C0.833374 0.778946 0.921172 0.566984 1.07745 0.410704C1.23373 0.254424 1.44569 0.166626 1.66671 0.166626H2.50004C2.72105 0.166626 2.93302 0.254424 3.0893 0.410704C3.24558 0.566984 3.33337 0.778946 3.33337 0.999959V11ZM9.16671 11C9.16671 11.221 9.07891 11.4329 8.92263 11.5892C8.76635 11.7455 8.55439 11.8333 8.33337 11.8333H7.50004C7.27903 11.8333 7.06707 11.7455 6.91079 11.5892C6.7545 11.4329 6.66671 11.221 6.66671 11V0.999959C6.66671 0.778946 6.7545 0.566984 6.91079 0.410704C7.06707 0.254424 7.27903 0.166626 7.50004 0.166626H8.33337C8.55439 0.166626 8.76635 0.254424 8.92263 0.410704C9.07891 0.566984 9.16671 0.778946 9.16671 0.999959V11Z"
            fill={color || 'white'}/>
    </svg>
  );
};

export default (IconPause);




