import React, { useRef, useState } from 'react';
import { YAMAP_API } from '../../utils/constants';
import './styles.css';
import { Modal } from '@mui/material';
import { Cancel } from '@mui/icons-material';

import {
  GeolocationControl,
  Map,
  Placemark,
  YMaps,
} from '@pbe/react-yandex-maps';
import iconMap from '../../images_new/map_icon.svg';

export default function CreatePointMap({ open, close, currentTarget, city }) {
  //const centerCoords = !routes? [47.26315812409134, 39.66601187052418]: routes[0].points[0].point_coords.split(',');
  const centerCoords = city ? city : [47.26315812409134, 39.66601187052418];
  const mapState = { center: centerCoords, zoom: 10, controls: [] };
  const mapRef = useRef();
  const [ymaps, setYmaps] = useState(null);
  const [placeCoords, setPlaceCoords] = useState('');

  const [address, setAddress] = useState('');

  const handleClick = () => {
    currentTarget.value = placeCoords;
    setAddress('');
    setPlaceCoords('');
    close();
  };
  const handleClickMap = (coordsData) => {
    mapRef.current.setCenter(coordsData);
    ymaps.geocode(coordsData).then(function (res) {
      var firstGeoObject = res.geoObjects.get(0);

      let adress = firstGeoObject.getAddressLine();

      setAddress(adress);
      setPlaceCoords(coordsData);
    });
  };
  const handleClickPlacemark = (e) => {
    let coord = e.originalEvent.target.geometry.getCoordinates();
    mapRef.current.setCenter(coord);
    ymaps.geocode(coord).then(function (res) {
      var firstGeoObject = res.geoObjects.get(0);

      let adress = firstGeoObject.getAddressLine();

      setAddress(adress);
      setPlaceCoords(coord);
    });
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ maxWidth: '100%' }}
    >
      <div className="mapCoord__container">
        <Cancel
          onClick={() => close()}
          style={{
            zIndex: 11,
            position: 'fixed',
            top: 15,
            right: 15,
            cursor: 'pointer',
            width: 35,
            height: 35,
          }}
        />
        <YMaps query={{ lang: 'ru_RU', apikey: YAMAP_API }}>
          <Map
            modules={['geocode']}
            defaultState={mapState}
            className={'createPointMap'}
            onClick={(e) => {
              var coordinations = e.get('coords');
              setPlaceCoords(coordinations);
              handleClickMap(coordinations);
            }}
            instanceRef={mapRef}
            onLoad={(ymaps) => setYmaps(ymaps)}
          >
            {address && (
              <Placemark
                key={'createPoint'}
                onDragend={(e) => handleClickPlacemark(e)}
                geometry={placeCoords}
                options={{
                  iconLayout: 'default#image',
                  iconImageHref: iconMap,
                  iconImageSize: [31, 40],
                  draggable: true,
                }}
              />
            )}

            <GeolocationControl options={{ float: 'left' }} />
          </Map>
        </YMaps>

        <div className={'fadeInDown createMapPanel'}>
          <p className="createPointMap__address">{address}</p>
          {address ? null : (
            <h2 className="createPointMap__address">
              Кликните, чтобы узнать адрес
            </h2>
          )}

          {address && (
            <button onClick={handleClick} className="createPointMap__button">
              Выбрать эту точку
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
