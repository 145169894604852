import React, { useState } from 'react';
import useCarousel from '../../utils/castomHooks/useCarousel';
import styles from './MainCarousel.module.css';
import MainButton from '../buttons/MainButton';
import { useNavigate } from 'react-router-dom';
import SimpleTextForClick from '../buttons/SimpleTextForClick';

const MainCarousel = ({ images }) => {
  const [touchStart, setTouchStart] = useState(0);
  const navigate = useNavigate();
  const { textButton, image, title, subtitle, indicators, onNext, onPrev, onIndicatorClick } =
    useCarousel(images, 99999999999);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = () => {
    //for future
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const diff = touchStart - touchEnd;
    const sensitivity = 50;

    if (diff > sensitivity) {
      onNext(e);
    } else if (diff < -sensitivity) {
      onPrev(e);
    }
  };

  const clickNext = () => {
    onNext()
  }

  const clickStart = () => {
    navigate('/search')
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.imageWrapper}
           onTouchStart={handleTouchStart}
           onTouchMove={handleTouchMove}
           onTouchEnd={handleTouchEnd}
      >
        <img className={styles.image} src={image} alt='carousel' />
      </div>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.text}>{subtitle}</p>
      <div className={styles.indicators}>
        {indicators.map((active, i) => (
          <div key={i} className={active ? styles.active : styles.inactive} onClick={() => onIndicatorClick(i)} />
        ))}
      </div>
      <MainButton style={{ marginBottom: '22px' }} text={textButton} onClick={() => {textButton === 'Далее' ? clickNext() : clickStart()}}/>
      {textButton === 'Далее' && <SimpleTextForClick onClick={clickStart} text='Пропустить'/>}
    </div>
  );
};

export default MainCarousel;
