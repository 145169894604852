import PicMain1 from '../images_new/mainPic/PicMain1.png';
import PicMain2 from '../images_new/mainPic/PicMain2.png';
import PicMain3 from '../images_new/mainPic/PicMain3.png';

export const YAMAP_API = 'c8eeb4f9-60a3-4b52-84be-f9d5619de6aa';

export const PATH_DOCUMENTS_USER = '/documents-user';
export const PATH_DOCUMENTS_AUTHOR = '/documents-author';

export const sliderData = [
  {
    title: 'В любом месте интересно',
    subtitle: 'Открывай новое в своем городе и по всей России',
    src: PicMain1,
    altPic: 'WhichCountry',
    textButton: 'Далее',
  },
  {
    title: 'Создавай свои маршруты',
    subtitle: 'Стань автором и поделись любимыми местами со всеми',
    src: PicMain2,
    altPic: 'TravelRoute',
    textButton: 'Далее',
  },
  {
    title: 'Получай новые впечатления',
    subtitle: 'Побывай там, где никогда раньше не был. Навстречу приключениям!',
    src: PicMain3,
    altPic: 'LetGoTravel',
    textButton: 'Начать',
  },
];

export const settingsSlideLending = {
  dots: true,
  infinite: true,
  speed: 800,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
};

export const shortTextLength = 70;
export const defaultTextLength = 175;
