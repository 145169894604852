import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postRoutes } from '../../store/routes';
import formDataCombiner from '../../utils/formDataCombiner';
import TabBar from '../../components_new/TabBar';
import SearchCity from '../../components_new/inputs/SearchCity';
import { filter, map } from 'lodash';
import IconClose from '../../images_new/IconClose';
import IconPlus from '../../images_new/IconPlus';
import styles from './NewEvent.module.css';
import CreatePointMap from '../../components_new/CreatePointMap';
import PriceInput from '../../components_new/inputs/PriceInput';
import BigImgInput from '../../components_new/inputs/BigImgInput';
import TextAreaInput from '../../components_new/inputs/TextAreaInput';
import PointForm from '../../components_new/PointForm';
import EndCreating from '../../components_new/popUps/EndCreating';
import PopUpError from '../../components_new/popUps/PopUpError';
import PdfOfferUser from '../../utils/documents/service_user_offer.pdf';
import {
  getCreateError,
  getSuccess,
  loading,
} from '../../store/routes/selectors';
import { useEffect } from 'react';
import MainButton from '../../components_new/buttons/MainButton';

const NewEvent = () => {
  const [items, setItems] = useState([0]);
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');
  const [currentTarget, setCurrentTarget] = useState('');
  const [isOpenPointsPopup, setIsOpenPointsPopup] = useState(false);
  const [isOpenSuccessPopup, setIsOpenSuccessPopup] = useState(false);
  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);
  const [cityCoords, setCityCoords] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(getCreateError);
  const isSuccess = useSelector(getSuccess);
  const FD = new FormData();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });
  const addPoints = (evt, itemData) => {
    evt.preventDefault();
    setItems([...items, itemData]);
  };

  const deletePoints = (evt, i) => {
    setItems(filter(items, (item, index) => i !== index));
  };

  const togglePointsPopup = (evt) => {
    setIsOpenPointsPopup(!isOpenPointsPopup);
    evt && setCurrentTarget(evt.target);
  };

  function showFormData(formData) {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
  }

  const sendInModeration = (data) => {
    FD.set('route_coords', cityCoords);
    FD.set('is_draft', false);
    formDataCombiner(data, FD);
    FD.set('city', text);
    // showFormData(FD)
    dispatch(postRoutes(FD));
  };

  useEffect(() => {
    if (isSuccess) setIsOpenSuccessPopup(true);
  }, [isSuccess]);

  useEffect(() => {
    if (error) setIsOpenErrorPopup(true);
  }, [error]);

  return (
    <section className={styles.newEvent}>
      <div className={styles.header}>
        <div className={styles.btnClose} onClick={() => navigate(-1)}>
          <IconClose width={17} height={17} />
        </div>
      </div>
      <h1 className={styles.h1}>Маршрут</h1>
      <form className={styles.form}>
        <BigImgInput
          image={watch().image}
          register={register}
          errors={errors}
          reset={reset}
        />
        <SearchCity
          register={register}
          errors={errors}
          text={text}
          setText={setText}
          setCityCoords={setCityCoords}
        />
        <input
          name="nameRoute"
          placeholder="Назовите маршрут"
          className={`${styles.input} ${errors.name && styles.error}`}
          {...register('name', { required: true, maxLength: 150 })}
        />
        <TextAreaInput
          register={register}
          errors={errors}
          placeholder="Опишите маршрут"
          name="description"
          counter={5000}
          watch={watch}
        />
        <PriceInput
          register={register}
          setVisible={setVisible}
          visible={visible}
        />

        {map(items, (item, index) => (
          <PointForm
            key={index}
            errors={errors}
            index={index}
            register={register}
            deletePoints={deletePoints}
            currentTarget={currentTarget}
            openPopup={togglePointsPopup}
            watch={watch}
            reset={reset}
          />
        ))}
        <button className={styles.addPoint} onClick={addPoints}>
          <IconPlus />
          <p className={styles.textAddPoint}>Добавить точку маршрута</p>
        </button>
        <p className={styles.textForm}>
          Нажимая «Отправить на публикацию», вы принимаете условия
          <a
            className={styles.link}
            href={PdfOfferUser}
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            договора на оказание услуг с ООО «Кибергид»
          </a>
        </p>
        <MainButton
          text="Отправить на публикацию"
          style={{ width: '100%' }}
          onClick={handleSubmit(sendInModeration)}
        />
      </form>
      <TabBar />

      {isOpenPointsPopup && (
        <CreatePointMap
          open={isOpenPointsPopup}
          close={togglePointsPopup}
          currentTarget={currentTarget}
          city={cityCoords}
        />
      )}
      {isOpenSuccessPopup && (
        <EndCreating clickClose={() => setIsOpenSuccessPopup(false)} />
      )}
      {isOpenErrorPopup && (
        <PopUpError clickClose={() => setIsOpenErrorPopup(false)} />
      )}
    </section>
  );
};

export default NewEvent;
