import React from 'react';

const IconMail = ({width, height, color, click }) => {
  return (
    <svg onClick={click} width={width || 60} height={height || 60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill={color || '#3495CF'}/>
      <path d="M42 18H18C16.35 18 15 19.35 15 21V39C15 40.65 16.35 42 18 42H42C43.65 42 45 40.65 45 39V21C45 19.35 43.65 18 42 18ZM41.4 24.375L31.59 30.51C30.615 31.125 29.385 31.125 28.41 30.51L18.6 24.375C18.4496 24.2906 18.3179 24.1765 18.2128 24.0397C18.1078 23.9029 18.0316 23.7462 17.9889 23.5791C17.9461 23.4119 17.9378 23.2379 17.9642 23.0675C17.9907 22.897 18.0515 22.7337 18.1429 22.5875C18.2343 22.4412 18.3545 22.315 18.4961 22.2165C18.6377 22.118 18.7978 22.0493 18.9667 22.0144C19.1357 21.9796 19.3099 21.9795 19.4789 22.0139C19.6479 22.0484 19.8082 22.1168 19.95 22.215L30 28.5L40.05 22.215C40.1918 22.1168 40.3521 22.0484 40.5211 22.0139C40.6901 21.9795 40.8643 21.9796 41.0333 22.0144C41.2022 22.0493 41.3623 22.118 41.5039 22.2165C41.6455 22.315 41.7657 22.4412 41.8571 22.5875C41.9485 22.7337 42.0093 22.897 42.0358 23.0675C42.0622 23.2379 42.0539 23.4119 42.0111 23.5791C41.9684 23.7462 41.8922 23.9029 41.7872 24.0397C41.6821 24.1765 41.5504 24.2906 41.4 24.375Z" fill="white"/>
      />
    </svg>
  );
};

export default (IconMail);




