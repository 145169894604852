import React, { useEffect, useState } from 'react';
import ExcursionList from '../../components/ExcursionList';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchasedRoutes } from '../../store/routes';
import { isAuthentication } from '../../store/auth/selectors';
import { useNavigate } from 'react-router-dom';
import TabBar from '../../components_new/TabBar';
import styles from './PurchasedPage.module.css';
import DefaultView from '../../components_new/DefaultView';
import Container from '../../components_new/Container';

const BoughtItemPage = () => {
  const dispatch = useDispatch();
  const routes = useSelector((state) => state.routes.purchasedRoutes);
  const [status, setStatus] = useState(true);
  const isAuth = useSelector(isAuthentication);
  const navigate = useNavigate();

  if (isAuth !== true) navigate('/search');
  useEffect(() => {
    dispatch(getPurchasedRoutes());
  }, [dispatch]);

  useEffect(() => {
    if (routes === 0 || routes?.length === 0) setStatus(false);
  }, [routes]);

  return (
    <>
      <Container title="Мои покупки">
        {routes?.length > 0 ? (
          <div className={styles.cantainerList}>
            <ExcursionList routes={routes} path={`/route/`} />
          </div>
        ) : (
          <DefaultView text="купленные экскурсии" />
        )}
      </Container>
      <TabBar />
    </>
  );
};
export default BoughtItemPage;
