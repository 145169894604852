import React from 'react';

const IconHeadphones = ({width, height, color }) => {
  return (
    <svg width={width || 18} height={height || 17} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.99996 2.33329C7.23185 2.33329 5.53616 3.03567 4.28591 4.28591C3.03567 5.53616 2.33329 7.23185 2.33329 8.99996H4.83329C5.27532 8.99996 5.69924 9.17555 6.0118 9.48811C6.32436 9.80068 6.49996 10.2246 6.49996 10.6666V14.8333C6.49996 15.2753 6.32436 15.6992 6.0118 16.0118C5.69924 16.3244 5.27532 16.5 4.83329 16.5H2.33329C1.89127 16.5 1.46734 16.3244 1.15478 16.0118C0.842221 15.6992 0.666626 15.2753 0.666626 14.8333V8.99996C0.666626 4.39746 4.39746 0.666626 8.99996 0.666626C13.6025 0.666626 17.3333 4.39746 17.3333 8.99996V14.8333C17.3333 15.2753 17.1577 15.6992 16.8451 16.0118C16.5326 16.3244 16.1087 16.5 15.6666 16.5H13.1666C12.7246 16.5 12.3007 16.3244 11.9881 16.0118C11.6756 15.6992 11.5 15.2753 11.5 14.8333V10.6666C11.5 10.2246 11.6756 9.80068 11.9881 9.48811C12.3007 9.17555 12.7246 8.99996 13.1666 8.99996H15.6666C15.6666 7.23185 14.9642 5.53616 13.714 4.28591C12.4638 3.03567 10.7681 2.33329 8.99996 2.33329ZM2.33329 10.6666V14.8333H4.83329V10.6666H2.33329ZM13.1666 10.6666V14.8333H15.6666V10.6666H13.1666Z"
            fill={color || '#3495CF'}/>

    </svg>
  );
};

export default (IconHeadphones);




