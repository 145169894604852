import React, { useEffect, useState } from 'react';
import ExcursionList from '../../components/ExcursionList';
import { useDispatch, useSelector } from 'react-redux';
import TabBar from '../../components_new/TabBar';
import { isAuthentication } from '../../store/auth/selectors';
import { useNavigate } from 'react-router-dom';
import styles from './Favorite.module.css';
import { getFavoritesRoutes } from '../../store/routes';
import Container from '../../components_new/Container';
import DefaultView from '../../components_new/DefaultView';

const FavoritesPage = () => {
  const routes = useSelector((state) => state.routes.favoritesRoutes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFavoritesRoutes());
  }, []);

  return (
    <div>
      <Container title="Избранное">
        {routes?.length > 0 ? (
          <div className={styles.cantainerList}>
            <ExcursionList routes={routes} path={`/route/`} />
          </div>
        ) : (
          <DefaultView text="отмеченные вами места" />
        )}
      </Container>
      <TabBar />
    </div>
  );
};
export default FavoritesPage;
