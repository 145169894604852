import React from 'react';

const IconPlay = ({ width, height, color }) => {
  return (
    <svg width={width || 10} height={height || 13} viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.50617 12.819C1.17695 13.0462 0.843786 13.0595 0.506666 12.8588C0.169547 12.6582 0.000658436 12.3478 0 11.9276V1.07297C0 0.653465 0.168888 0.343034 0.506666 0.141674C0.844444 -0.0596867 1.17761 -0.0464024 1.50617 0.181526L9.55556 5.60882C9.85185 5.81857 10 6.11572 10 6.50026C10 6.8848 9.85185 7.18195 9.55556 7.3917L1.50617 12.819Z"
        fill={color || 'white'}/>

    </svg>
  );
};

export default (IconPlay);




