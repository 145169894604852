import { Link } from 'react-router-dom';
import styles from './Menu.module.css';
import IconMenuArrow from '../../images_new/IconMenuArrow';

const Menu = ({ title, links }) => {
  return (
    <>
      <h2>{title}</h2>
      <nav>
        <ul className={styles.list}>
          {links.map((link) => (
            <li className={styles.li} key={link.name}>
              <Link className={styles.link} to={`/${link.to}`}>
                {link.icon}
                <span className={styles.linkName}>{link.name}</span>
                <IconMenuArrow width={9} height={16} />
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Menu;
