import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function vkLink(url, { title, image, noParse, noVkLinks }) {
  assert(url, "vk.url")

  return (
    "https://vk.com/share.php" +
    objectToGetParams({
      url,
      title,
      image,
      noparse: noParse ? 1 : 0,
      no_vk_links: noVkLinks ? 1 : 0
    })
  )
}

const VKShareButton = createShareButton(
  "vk",
  vkLink,
  props => ({
    title: props.title,
    image: props.image,
    noParse: props.noParse,
    noVkLinks: props.noVkLinks
  }),
  {
    windowWidth: 660,
    windowHeight: 460
  }
)

export default VKShareButton