import React, { useState, useRef, useEffect } from 'react';
import { YAMAP_API } from '../../utils/constants';
import './styles.css';

import {
  Clusterer,
  GeolocationControl,
  Map,
  Placemark,
  YMaps,
} from '@pbe/react-yandex-maps';

import iconMapDis from '../../images_new/map_icon_dis.svg';
import iconMap from '../../images_new/map_icon.svg';
import IconBack from '../../images_new/IconBack';
import PopUpGo from '../popUps/PopUpGo';

export default function ExcursionMap({
  route,
  activePoint,
  setActivePoint,
  fullMap,
  handleClickBtnMap,
  setIsOpenPoint,
  setPoint,
  point
}) {

  //const centerCoords = !routes? [47.26315812409134, 39.66601187052418]: routes[0].points[0].point_coords.split(',');
  const centerCoords = route?.points[0].point_coords.split(',');
  const mapState = {center: centerCoords, zoom: 15, controls: [] };
  const mapRef = useRef();
  const [ymaps, setYmaps] = useState(null);

  if (ymaps)
  {     var MyIconContentLayout = ymaps.templateLayoutFactory.createClass(

         '<div class="placemark_layout_container"><div class="placemark_layout">$[properties.iconContent]</div></div>'
        
    )

  }
  if (ymaps)
  {     var MyIconContentLayout_active = ymaps.templateLayoutFactory.createClass(
         '<div class="placemark_layout_container placemark_layout_container_active"><div class="placemark_layout">$[properties.iconContent]</div></div>'
        
    )

  }
 
  useEffect(() => {

    if ((activePoint || activePoint === 0) && route) { let coords = route.points[activePoint].point_coords.split(','); mapRef?.current?.setCenter(coords);}
    if (!fullMap) mapRef?.current?.setZoom(15);
    if ((activePoint || activePoint === 0) && route  && point)  {mapRef?.current?.setZoom(18); }
  }, [activePoint, route, ymaps, fullMap])
  return (
    <div className={fullMap ? 'exMap__containerFull' : 'exMap__container'}>
      <YMaps query={{ lang: 'ru_RU', apikey: YAMAP_API}}>
        <Map
          defaultState={mapState}
          className={fullMap ? 'exMapRoutesFull' : 'exMapRoutes'}
          instanceRef={mapRef}
          onLoad={(ymaps) => setYmaps(ymaps)}
          modules={[
            "templateLayoutFactory",
          ]}
          
        >
          <Clusterer
          
            options={{
              preset: 'islands#invertedVioletClusterIcons',
              groupByCoordinates: true,
              clusterDisableClickZoom: true,
              clusterHideIconOnBalloonOpen: true,
              geoObjectHideIconOnBalloonOpen: true,
            }}
          >
            {route?.points.map((el, i) => (
              <Placemark
                onClick={() => {
                
                  if (!fullMap) return;
                  setActivePoint(i);
                  setPoint(route.points[activePoint]);
                }}
                key={el.id + 'mapPoint'}
                geometry={[
                  el.point_coords.split(',')[0],
                  el.point_coords.split(',')[1],
                ]}
                options={
                  i === activePoint
                    ? {
                      
                     iconLayout: MyIconContentLayout_active,
                   
                      iconImageSize: [31, 40],
                   
                    
                    }
                    : {
                       iconLayout: MyIconContentLayout,
                 
                      iconImageSize: [31, 40],
                      
                  
                    }
                  
                    }
                    properties={{
                      iconContent: `${i + 1}`
                    }}
                
              />
            ))}
          </Clusterer>
          <GeolocationControl options={{ float: 'left' }} />
        </Map>
      </YMaps>
      {fullMap && (
        <div className="backButtonMap" onClick={handleClickBtnMap}>
          {<IconBack width={10} height={18} color={'#ffffff'} />}
        </div>
      )}
      {fullMap && route.points[activePoint] && (
        <PopUpGo
          route={route}
          activePoint={activePoint}
          setActivePoint={setActivePoint}
          setIsOpenPoint={setIsOpenPoint}
          setPoint={setPoint}
          handleClickBtnMap={handleClickBtnMap}
        />
      )}
    </div>
  );
}
