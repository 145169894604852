import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function telegramLink(url, { title }) {
  assert(url, "telegram.url")

  return (
    "https://telegram.me/share/url" +
    objectToGetParams({
      url,
      text: title
    })
  )
}

const TelegramShareButton = createShareButton(
  "telegram",
  telegramLink,
  props => ({
    title: props.title
  }),
  {
    windowWidth: 550,
    windowHeight: 400
  }
)

export default TelegramShareButton