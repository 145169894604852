import styles from './Author.module.css';
import Container from '../../components_new/Container';
import { useNavigate } from 'react-router-dom';
import IconFolder from '../../images_new/IconFolder';
import IconDraft from '../../images_new/IconDraft';
import IconWallet from '../../images_new/IconWallet';
import Menu from '../../components_new/Menu';
import TabBar from '../../components_new/TabBar';

const Author = () => {
  const navigate = useNavigate();
  const links = [
    {
      icon: <IconFolder />,
      name: 'Мои публикации',
      to: 'publications',
    },
    {
      icon: <IconDraft />,
      name: 'Мои черновики',
      to: 'drafts',
    },
    {
      icon: <IconWallet />,
      name: 'Кошелёк',
      to: 'author',
    },
  ];

  return (
    <>
      <Container title="Кабинет автора">
        <button
          className={styles.createButton}
          onClick={() => navigate('/new-event')}
        >
          <p className={styles.createButtonText}>Создать свой маршрут</p>
          <div className={styles.createButtonImg}></div>
        </button>
        {links?.length && <Menu title="Профиль" links={links} />}
      </Container>
      <TabBar />
    </>
  );
};

export default Author;
