import React, { useState } from 'react';
import styles from './SmallImgInput.module.css';
import IconClose from '../../../images_new/IconClose';
import IconPicture from '../../../images_new/IconPicture';
import heic2any from 'heic2any';

const SmallImgInput = ({  errors,  register, reset, index }) => {
  const [photoPoint, setPhotoPoint] = useState(null);
  const { onChange, ...rest } = register(`points${index}uploaded_images`, { required: true });
  const handleImageRemove = () => {
    setPhotoPoint(null);
    reset({ dynImg: null });
  };

  const convertHeicToJpeg = async (heicFile) => {
    try {
      const jpegBlob = await heic2any({ blob: heicFile, toType: 'image/jpeg' });
      return URL.createObjectURL(jpegBlob);
    } catch (error) {
      console.error('Ошибка при конвертации изображения:', error);
      return null;
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/heic') {
      const jpegUrl = await convertHeicToJpeg(file);
      setPhotoPoint(jpegUrl);
      onChange(event);
    } else {
      setPhotoPoint(URL.createObjectURL(file));
      onChange(event);
    }
  };

  return (
    <>
      <label htmlFor={`points${index}uploaded_images`} className={styles.file}>
        <IconPicture backgroundColor={errors[`points${index}uploaded_images`] && '#FE5F55'} />
        <p className={`${styles.text}  ${errors[`points${index}uploaded_images`] && styles.errorPlaceholder}`}>Добавить фото</p>
      </label>
      {photoPoint && (
        <div className={styles.photoContainer}>
          <div className={styles.photo}>
            <img
              className={styles.image}
              src={photoPoint}
              alt='reviewPhoto'
            />
            <button className={styles.delete} onClick={handleImageRemove}>
              <IconClose width={5} height={5} />
            </button>
          </div>
        </div>
      )}
      <input
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
        id={`points${index}uploaded_images`}
        onChange={handleImageChange}
        {...rest}
        type='file'
        name={`points${index}uploaded_images`}
        accept='.png,.jpg,.jpeg,.gif,.heic'
      />
    </>
  );
};

export default SmallImgInput;
