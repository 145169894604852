import React, { useEffect } from 'react';
import newEventSuccessImg from '../../images/newEventSuccessImg.svg';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { buyRoute } from '../../store/routes';

const PaymentSuccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const subString1 = location.search.indexOf('shp_route') + 10;
  const subString2 = location.search.indexOf('Signature') - 1;
  const id = Number(location.search.slice(subString1, subString2));

  useEffect(() => {
    if (id) {
      dispatch(buyRoute(id));
    }
  }, [id]);

  const navigate = useNavigate();
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          direction="column"
          sx={{ marginTop: 6, padding: '0 1.3rem' }}
        >
          <Box
            component="img"
            src={newEventSuccessImg}
            sx={{
              height: '40vh',
              width: '41vh',
              display: 'flex',
              marginBottom: 4,
              alignItems: 'center',
            }}
          />
          <Typography variant="h2" sx={{ marginBottom: 12 }}>
            Оплата произведена!
          </Typography>

          <Button
            onClick={() => navigate(`/card-audio/${id}`)}
            variant="contained"
            sx={{ mb: 3 }}
          >
            Начать экскурсию
          </Button>
          {/* <Button  onClick={()=>navigate(`/search`) } variant="contained">
            Перейти к экскурсиям
          </Button> */}
          <Typography
            variant="subtitle3"
            onClick={() => navigate(`/search`)}
            sx={{ color: '#1B8086' }}
          >
            Перейти к экскурсиям
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
