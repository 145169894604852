import React from 'react';

const IconLogout = ({ width, height, color }) => {
  return (
    <svg
      width={width || 18}
      height={height || 19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.375 13.0714L16.8334 9.49999M16.8334 9.49999L13.375 5.92856M16.8334 9.49999H6.11908M13.2619 1.16666L3.73813 1.16904C2.42384 1.17023 1.35718 2.2357 1.35718 3.54999V15.4488C1.35718 16.0803 1.60803 16.6859 2.05454 17.1324C2.50106 17.5789 3.10666 17.8298 3.73813 17.8298H13.375"
        stroke="#333333"
        strokeWidth="1.19048"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconLogout;
