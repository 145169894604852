import { useEffect, useState } from 'react';
import styles from './PopUpLogin.module.css';
import IconClose from '../../../images_new/IconClose';
import { useForm } from 'react-hook-form';
import StandardInput from '../../inputs/StandardInput';
import MainButton from '../../buttons/MainButton';
import CodeInput from '../../inputs/CodeInput';
import { checkCode, generateCode } from '../../../store/auth';
import { useDispatch, useSelector } from 'react-redux';
import Agreement from '../Agreement';
import { closePopupLogin } from '../../../store/toolkitSlice';
import { isSuccess } from '../../../store/auth/selectors';
import PdfOfferUser from '../../../utils/documents/service_user_offer.pdf';
import PdfPolicy from '../../../utils/documents/privacy_policy.pdf';
import PdfConclusion from '../../../utils/documents/user_conclusion.pdf';
import PdfConsentUser from '../../../utils/documents/user_consent.pdf';

const PopUpLogin = () => {
  const onSuccess = useSelector(isSuccess);
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isBtnСontinue, setIsBtnСontinue] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      otp_code: '',
    },
  });
  const { otp_code, email } = watch();

  const clickClose = () => {
    if (step === 3) {
      dispatch(closePopupLogin());
      setStep(1);
      return;
    }
    dispatch(closePopupLogin());
  };

  const onSubmitEmail = (data) => {
    const email = data.email.toLowerCase();
    const params = { email };
    dispatch(generateCode(params)).then((response) => {
      if (response.payload.success) {
        setStep(2);
      }
    });
  };

  const onSubmitEmailAgan = (data) => {
    const { email } = data;
    dispatch(generateCode(email));
  };

  useEffect(() => {
    if (otp_code?.length === 6) {
      const params = { email, otp_code };
      dispatch(checkCode(params)).then((response) => {
        if (response.payload.success) {
          dispatch(closePopupLogin());
        }
      });
    }
  }, [otp_code]);

  useEffect(() => {
    email? setIsBtnСontinue(false) : setIsBtnСontinue(true)
  }, [email]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.btnClose} onClick={clickClose}>
            <IconClose />
          </div>
          <p className={styles.title}>
            {step === 1 && 'Войдите или зарегистрируйтесь'}
            {step === 2 && 'Подтвердите вашу почту'}
            {step === 3 && 'Политика конфиденциальности'}
          </p>
        </div>
        {step === 1 && (
          <>
            <form
              className={styles.form}
              onSubmit={handleSubmit(onSubmitEmail)}
            >
              <StandardInput
                error={errors.email}
                control={control}
                {...register('email', {
                  pattern: {
                    value: /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i,
                    message: 'Введите корректный Email',
                  },
                })}
                placeholder="Введите Email"
              />
              <p
                style={{ marginTop: '12px', marginBottom: '40px' }}
                className={styles.text}
              >
                Мы отправим код для подтверждения на этот адрес
              </p>
              <p className={styles.textForm}>Нажимая «Продолжить», вы принимаете условия
                <a className={styles.link} href={PdfConclusion} target='_blank' rel="noreferrer"> Пользовательского соглашения</a> и
                <a className={styles.link} href={PdfOfferUser} target='_blank' rel="noreferrer"> Публичной оферты</a>, а также даёте
                <a className={styles.link} href={PdfConsentUser} target='_blank' rel="noreferrer"> согласие на обработку персональных данных</a> в соответствии с
                <a className={styles.link} href={PdfPolicy} target='_blank' rel="noreferrer"> политикой конфиденциальности.</a>
              </p>
              <MainButton
                disabled={isBtnСontinue}
                text="Продолжить"
                style={{ marginBottom: '64px', width: '100%' }}
              />
            </form>
            <div className={styles.textLine}>
              <div className={styles.line} />
              <p className={styles.text}> или </p>
              <div className={styles.line} />
            </div>
            <MainButton
              disabled
              variant={'transparent'}
              text="Войти по номеру телефона"
              style={{ marginBottom: '64px', width: '100%' }}
            />
          </>
        )}
        {step === 2 && (
          <form>
            <p style={{ marginBottom: '24px' }} className={styles.text}>
              Введите код, который мы прислали на почту {email}
            </p>
            <CodeInput
              control={control}
              error={otp_code?.length === 6 && onSuccess === false}
              {...register('otp_code')}
            />
            <p style={{ marginTop: '40px' }} className={styles.text}>
              Не получили письмо?{' '}
              <span
                style={{
                  opacity: 0.8,
                  color: '#363636',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
                onClick={onSubmitEmailAgan}
              >
                Прислать еще раз
              </span>{' '}
              или{' '}
              <a
                target="_blank"
                href="https://t.me/boldarevyury"
                rel="noreferrer"
                style={{
                  opacity: 0.8,
                  color: '#363636',
                  textDecorationLine: 'underline',
                  cursor: 'pointer',
                }}
              >
                Написать в поддержку
              </a>
            </p>
          </form>
        )}
        {step === 3 && <Agreement step={step} setStep={setStep} />}
      </div>
    </div>
  );
};

export default PopUpLogin;
