import React from 'react';

const IconClose = ({ width, height, color }) => {
  return (

    <svg width={width || 17} height={height || 17} viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.5375 0.472054C12.4488 0.383213 12.3435 0.31273 12.2276 0.264639C12.1116 0.216548 11.9874 0.191794 11.8618 0.191794C11.7363 0.191794 11.6121 0.216548 11.4961 0.264639C11.3802 0.31273 11.2749 0.383213 11.1862 0.472054L6.49997 5.14872L1.81372 0.46247C1.725 0.373746 1.61966 0.303366 1.50374 0.255348C1.38782 0.207331 1.26357 0.182617 1.1381 0.182617C1.01262 0.182617 0.888373 0.207331 0.772449 0.255348C0.656525 0.303366 0.551194 0.373746 0.46247 0.46247C0.373746 0.551194 0.303366 0.656525 0.255348 0.772449C0.207331 0.888373 0.182617 1.01262 0.182617 1.1381C0.182617 1.26357 0.207331 1.38782 0.255348 1.50374C0.303366 1.61966 0.373746 1.725 0.46247 1.81372L5.14872 6.49997L0.46247 11.1862C0.373746 11.2749 0.303366 11.3803 0.255348 11.4962C0.207331 11.6121 0.182617 11.7364 0.182617 11.8618C0.182617 11.9873 0.207331 12.1116 0.255348 12.2275C0.303366 12.3434 0.373746 12.4487 0.46247 12.5375C0.551194 12.6262 0.656525 12.6966 0.772449 12.7446C0.888373 12.7926 1.01262 12.8173 1.1381 12.8173C1.26357 12.8173 1.38782 12.7926 1.50374 12.7446C1.61966 12.6966 1.725 12.6262 1.81372 12.5375L6.49997 7.85122L11.1862 12.5375C11.2749 12.6262 11.3803 12.6966 11.4962 12.7446C11.6121 12.7926 11.7364 12.8173 11.8618 12.8173C11.9873 12.8173 12.1116 12.7926 12.2275 12.7446C12.3434 12.6966 12.4487 12.6262 12.5375 12.5375C12.6262 12.4487 12.6966 12.3434 12.7446 12.2275C12.7926 12.1116 12.8173 11.9873 12.8173 11.8618C12.8173 11.7364 12.7926 11.6121 12.7446 11.4962C12.6966 11.3803 12.6262 11.2749 12.5375 11.1862L7.85122 6.49997L12.5375 1.81372C12.9016 1.44955 12.9016 0.83622 12.5375 0.472054Z'
        fill={ color || '#979798'} />

    </svg>
  );
};

export default IconClose;
