import { Box } from '@mui/material';

import React from 'react';
import { styled } from '@mui/material/styles';
import { map } from 'lodash';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import { AddButton } from '../ImgInputSmall/styles';

const BoxInput = styled('div')(({ theme }) => ({
  border: '1px solid #AAAAAA',
  width: '100%',
  borderRadius: 8,
  marginTop: '12px',
  padding: '12px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
}));

const InputСost = styled('input')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  width: '90%',
}));

const SimpleFileInput = ({
                           resetField,
  watch,
  register,
  name,
  marginBottom,
  btnText,
  cursor,
  readOnly,
}) => {
  return (
    <Box sx={{ marginBottom: marginBottom }}>
      {map(watch(name), (file, index) => (
        <Box sx={{ position: 'relative', mb: 2 }} key={index}>
          <audio
            id="player"
            type="audio/mpeg"
            src={URL.createObjectURL(file)}
            controls
          />
          <ClearOutlinedIcon
            onClick={() => {
              resetField(name);
            }}
            sx={{
              position: 'absolute',
              width: '40px',
              height: '40px',
              right: '.3rem',
              top: '0.4rem',
              cursor: 'pointer',
              color: '#1B8086',
            }}
          />
        </Box>
      ))}
      <InputСost
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
        readOnly={readOnly}
      />
      <AddButton sx={{ borderRadius: 100}} htmlFor={name}>
        <AudiotrackRoundedIcon  sx={{ width: '100%', height: '100%', color: '#C6F04E' }}/>
      </AddButton>
      <input
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
        id={name}
        {...register(name)}
        type="file"
        name={name}
        accept="/audio*,.mpeg,.mp4,.ogg,.mp3,.wav"
      ></input>
    </Box>
  );
};

export default SimpleFileInput;
