import React from 'react';

const IconLike = ({ width, height, color }) => {
  return (
    <svg width={width || 18} height={height || 16} viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.80005 15.6599C8.6934 15.661 8.58834 15.634 8.49536 15.5818C8.15942 15.3943 0.362549 10.9646 0.362549 5.34741C0.362549 4.36359 0.672099 3.4047 1.24735 2.60658C1.8226 1.80846 2.63439 1.21157 3.56773 0.900459C4.50107 0.589347 5.50864 0.579785 6.44771 0.87313C7.38678 1.16647 8.20975 1.74785 8.80005 2.53491C9.39034 1.74785 10.2133 1.16647 11.1524 0.87313C12.0915 0.579785 13.099 0.589347 14.0324 0.900459C14.9657 1.21157 15.7775 1.80846 16.3527 2.60658C16.928 3.4047 17.2375 4.36359 17.2375 5.34741C17.2375 7.73804 15.8547 10.1912 13.1282 12.6443C11.8901 13.7573 10.542 14.7416 9.10474 15.5818C9.01176 15.634 8.9067 15.661 8.80005 15.6599ZM5.05005 1.90991C4.13837 1.90991 3.26403 2.27208 2.61937 2.91673C1.97471 3.56139 1.61255 4.43573 1.61255 5.34741C1.61255 9.65991 7.3938 13.449 8.80005 14.3084C10.2063 13.449 15.9875 9.65991 15.9875 5.34741C15.9883 4.55275 15.7136 3.78237 15.2104 3.16736C14.7071 2.55236 14.0064 2.1307 13.2273 1.97415C12.4482 1.81759 11.6389 1.9358 10.9371 2.30867C10.2354 2.68154 9.68448 3.28603 9.37817 4.01929C9.33109 4.13392 9.25098 4.23196 9.14804 4.30097C9.0451 4.36997 8.92397 4.40681 8.80005 4.40681C8.67612 4.40681 8.55499 4.36997 8.45205 4.30097C8.34911 4.23196 8.26901 4.13392 8.22192 4.01929C7.96179 3.39293 7.52138 2.85801 6.95664 2.48244C6.39189 2.10687 5.72827 1.90759 5.05005 1.90991Z'
        fill={color || '#3495CF'} />

    </svg>
  );
};

export default (IconLike);




