import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { NewAddButton } from './styles';
import { Box, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { map } from 'lodash';
import { ImageEvent } from '../../../containers/newEvents/NewEvent/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const ImgInputBig = ({
  text,
  name,
  register,
  onClick,
  watch,
  resetField,
  validation,
}) => {
  return (
    <Box>
      <NewAddButton htmlFor={name} onClick={onClick}>
        <AddIcon sx={{ width: '67%', height: '67%', color: '#C6F04E' }} />
        <Typography
          variant="subtitle1"
          sx={{ color: grey[900], textTransform: 'none' }}
        >
          {text}
        </Typography>
      </NewAddButton>
      <input
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
        id={name}
        {...register(name, validation)}
        type="file"
        name={name}
        accept="/image*,.png,.jpg,.jpeg,.gif"
      />
      <Grid container sx={{ margin: '0.8rem 0', gap: 1.8 }}>
        {map(watch('image'), (image, index) => (
          <Box sx={{ position: 'relative' }} key={index}>
            <ImageEvent src={URL.createObjectURL(image)} alt="Картинка" />
            <ClearOutlinedIcon
              onClick={() => {
                resetField(name);
              }}
              sx={{
                position: 'absolute',
                width: '20px',
                height: '20px',
                left: '3.3rem',
                cursor: 'pointer',
                color: '#1B8086',
              }}
            />
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default ImgInputBig;
