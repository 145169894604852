import React from 'react';
import { Button } from '@mui/material';
import md5 from 'md5';

const login = process.env.REACT_APP_ROBOKASSA_LOGIN;
const pass1 = process.env.REACT_APP_ROBOKASSA_PASS_1;

const PamentButton = ({ card, buttonStyle }) => {
  const summ = card?.price;
  const receiptName = `Маршрут ${card?.id} ( ${card?.city} )`;

  const receipt = {
    sno: 'usn_income',
    items: [
      {
        name: receiptName,
        quantity: 1,
        sum: summ,
        tax: 'none',
      },
    ],
  };
  const url_sign = JSON.stringify(receipt);
  let url = encodeURIComponent(JSON.stringify(receipt));

  const signature = md5(
    `${login}:${summ}:0:${url_sign}:${pass1}:shp_interface=link:shp_route=${card.id}`
  );

  const fixedStyle = {
    position: 'fixed',
    bottom: '0',
    width: '90%',
    left: '50%',
    maxWidth: '400px',
    transform: 'translate(-50%, -50%)',
  };

  const normalStyle = {
    backgroundColor: '#ECFF9F',
  };

  return (
    <Button
      sx={buttonStyle === 'fixed' ? fixedStyle : normalStyle}
      variant="contained"
      href={`https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=${login}&InvId=0&Receipt=${url}&Culture=ru&Encoding=utf-8&OutSum=${summ}&shp_interface=link&shp_route=${card.id}&SignatureValue=${signature}`}
    >
      Купить за {card?.price} руб
    </Button>
  );
};
export default PamentButton;
