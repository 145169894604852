import styles from './TabList.module.css';

const TabList = ({ tabs, current, setCurrent }) => {
  const className = (tab) => {
    return current === tab ? `${styles.tab} ${styles.active}` : styles.tab;
  };
  return (
    <ul className={styles.tabs}>
      {tabs.map((tab) => (
        <li key={tab}>
          <button
            className={className(tab)}
            onClick={() => {
              setCurrent(tab);
            }}
          >
            {tab}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default TabList;
