import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';

export const Input = styled('input')(({ theme }) => ({
  padding: '8px 12px 96px',
  border: 'none',
  borderRadius: 8,
  outline: 'none',
}));

export const ButtonDelete = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 18,
  lineHeight: '20px',
  letterSpacing: 0.1,
  padding: '4px 0',
  marginRight: 8,
  height: 48,
  width: 109,
  border: 'none',
  borderRadius: 8,
  outline: 'none',
  backgroundColor: 'transparent',
  textTransform: 'lowercase',
  color: grey[900],
}));

export const ButtonSend = styled(Button)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  padding: 0,
  height: 40,
  width: 130,
  border: 'none',
  borderRadius: 36,
  backgroundColor: '#C6F04E',
  textTransform: 'capitalize',
  color: grey[900],
}));
