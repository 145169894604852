import React from 'react';

const IconPhotoCamera = ({ width, height, color }) => {
  return (
    <svg width={width || 16} height={height || 14} viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.3335 13.75H9.6665C12.0072 13.75 13.178 13.75 14.0187 13.1988C14.3815 12.961 14.694 12.6542 14.9382 12.2958C15.5 11.4708 15.5 10.321 15.5 8.02304C15.5 5.72429 15.5 4.57528 14.9382 3.75028C14.694 3.39187 14.3815 3.08504 14.0187 2.84728C13.4787 2.49253 12.8022 2.36578 11.7665 2.32079C11.2722 2.32079 10.847 1.95328 10.7502 1.47704C10.6763 1.12819 10.4842 0.815562 10.2064 0.591998C9.92857 0.368434 9.58209 0.247641 9.2255 0.250035H6.7745C6.0335 0.250035 5.39525 0.763785 5.24975 1.47704C5.153 1.95328 4.72775 2.32079 4.2335 2.32079C3.1985 2.36578 2.522 2.49328 1.98125 2.84728C1.61871 3.08508 1.30653 3.39191 1.0625 3.75028C0.5 4.57528 0.5 5.72429 0.5 8.02304C0.5 10.321 0.5 11.47 1.06175 12.2958C1.30475 12.6528 1.61675 12.9595 1.98125 13.1988C2.822 13.75 3.99275 13.75 6.3335 13.75ZM8 4.95478C6.27425 4.95478 4.87475 6.32803 4.87475 8.02228C4.87475 9.71729 6.27425 11.0905 8 11.0905C9.72575 11.0905 11.1252 9.71729 11.1252 8.02304C11.1252 6.32804 9.72575 4.95478 8 4.95478ZM8 6.18179C6.965 6.18179 6.125 7.00604 6.125 8.02304C6.125 9.03929 6.965 9.86353 8 9.86353C9.035 9.86353 9.875 9.03929 9.875 8.02304C9.875 7.00604 9.035 6.18179 8 6.18179ZM11.5415 5.56828C11.5415 5.22928 11.8212 4.95478 12.167 4.95478H12.9995C13.3445 4.95478 13.625 5.22928 13.625 5.56828C13.6234 5.73246 13.5567 5.88929 13.4396 6.00432C13.3224 6.11936 13.1644 6.18318 13.0002 6.18179H12.167C12.0856 6.18258 12.0049 6.16734 11.9295 6.13693C11.854 6.10653 11.7853 6.06156 11.7272 6.00459C11.6691 5.94762 11.6228 5.87977 11.5909 5.80491C11.5591 5.73004 11.5423 5.64964 11.5415 5.56828Z'
        fill={color || '#333333'} />
    </svg>
  );
};

export default (IconPhotoCamera);




