import React from 'react';

const IconTelegram = ({width, height, color, click}) => {
  return (
    <svg onClick={click} width={width || 60} height={height || 60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="30" fill={color || '#3495CF'}/>
      <path d="M40.3392 18.1773C40.3392 18.1773 43.091 17.1041 42.8608 19.7101C42.785 20.7832 42.0972 24.5395 41.5618 28.6018L39.7272 40.6364C39.7272 40.6364 39.5742 42.3994 38.1979 42.7061C36.8223 43.0121 34.7582 41.633 34.3757 41.3263C34.0697 41.0961 28.6425 37.6465 26.7314 35.9607C26.1959 35.5003 25.5839 34.5808 26.8079 33.5077L34.834 25.8421C35.7513 24.9227 36.6686 22.7765 32.8464 25.3824L22.1435 32.6641C22.1435 32.6641 20.9202 33.4312 18.6273 32.7413L13.6577 31.2078C13.6577 31.2078 11.8231 30.0581 14.9575 28.9085C22.6025 25.3059 32.0056 21.6268 40.3377 18.1773H40.3392Z" fill="white"/>
      />
    </svg>
  );
};

export default (IconTelegram);




