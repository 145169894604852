import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const MainWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  minWidth: '320px',
  margin: '0 auto',
  maxWidth: '780px',
  position: 'relative',
  boxSizing: 'border-box',
  padding: '0 16px',
}));
