import React from 'react';

const IconAudio = ({ width, height, color }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="#3495CF" />
      <path
        d="M23.3844 11.5507L17.1344 9.67568C17.0644 9.65474 16.9905 9.65044 16.9185 9.66314C16.8466 9.67584 16.7786 9.70518 16.72 9.74882C16.6614 9.79246 16.6138 9.8492 16.5811 9.91449C16.5483 9.97979 16.5312 10.0518 16.5312 10.1249V18.9569C15.9663 18.337 15.2024 17.9342 14.3717 17.8183C13.5411 17.7024 12.696 17.8806 11.983 18.3222C11.2699 18.7637 10.7338 19.4408 10.4674 20.236C10.201 21.0313 10.2211 21.8947 10.5243 22.6767C10.8275 23.4587 11.3946 24.11 12.1275 24.5178C12.8604 24.9256 13.7128 25.0643 14.5371 24.9098C15.3615 24.7552 16.1058 24.3173 16.6412 23.6717C17.1766 23.0261 17.4694 22.2136 17.4687 21.3749V14.5046L23.1156 16.1991C23.1856 16.2201 23.2595 16.2244 23.3315 16.2117C23.4034 16.199 23.4714 16.1696 23.53 16.126C23.5886 16.0823 23.6362 16.0256 23.6689 15.9603C23.7017 15.895 23.7188 15.823 23.7187 15.7499V11.9999C23.7188 11.899 23.6863 11.8008 23.626 11.7199C23.5658 11.6389 23.481 11.5796 23.3844 11.5507ZM13.875 24.0311C13.3496 24.0311 12.8361 23.8754 12.3993 23.5835C11.9624 23.2916 11.622 22.8768 11.4209 22.3914C11.2199 21.906 11.1673 21.372 11.2698 20.8567C11.3723 20.3414 11.6253 19.8681 11.9967 19.4966C12.3682 19.1252 12.8415 18.8722 13.3568 18.7697C13.8721 18.6672 14.4061 18.7198 14.8915 18.9208C15.3769 19.1219 15.7917 19.4623 16.0836 19.8992C16.3755 20.336 16.5312 20.8495 16.5312 21.3749C16.5312 22.0794 16.2514 22.755 15.7533 23.2531C15.2551 23.7513 14.5795 24.0311 13.875 24.0311ZM22.7812 15.1202L17.4687 13.5265V10.7546L22.7812 12.3483V15.1202Z"
        fill="#F8F7F9"
      />
    </svg>
  );
};

export default IconAudio;
