import { useLayoutEffect } from 'react';
import SearchSwitcher from '../../../components/SearchSwitcher';
import ExcursionList from '../../../components/ExcursionList';
import PageLayout from '../../../components/layout/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getDraftRoutes } from '../../../store/routes';
import { Typography } from '@mui/material';

const NewEventDraft = (props) => {
  const dispatch = useDispatch();
  const routes = useSelector((state) => state.routes.draftRoutes);

  useLayoutEffect(() => {
    dispatch(getDraftRoutes());
  }, [dispatch]);

  return (
    <PageLayout variant={'second'}>
      <SearchSwitcher />
      {routes !== null && routes.length !== 0 ? (
        <ExcursionList
          routes={routes}
          path={'/draft-route/'}
          clickOnCard={props.clickOnCard}
        />
      ) : (
        <Typography
          variant="subtitle1"
          sx={{ margin: 'auto', marginTop: '10vh' }}
        >
          Маршрутов нет
        </Typography>
      )}
    </PageLayout>
  );
};

export default NewEventDraft;
