import React, { useState } from 'react';
import styles from './BigImgInput.module.css';
import IconClose from '../../../images_new/IconClose';
import AddIcon from '../../../images_new/AddIcon';
import heic2any from 'heic2any';

const BigImgInput = ({ errors, register, reset}) => {
  const [photoRoute, setPhotoRoute] = useState(null);
  const { onChange, ...rest } = register('image', { required: 'Загрузи фото' });
  const handleImageRemove = () => {
    setPhotoRoute(null);
    reset({ image: null });
  };

  const convertHeicToJpeg = async (heicFile) => {
    try {
      const jpegBlob = await heic2any({ blob: heicFile, toType: 'image/jpeg' });
      return URL.createObjectURL(jpegBlob);
    } catch (error) {
      console.error('Ошибка при конвертации изображения:', error);
      return null;
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/heic') {
      const jpegUrl = await convertHeicToJpeg(file);
      setPhotoRoute(jpegUrl);
      onChange(event);
    } else {
      setPhotoRoute(URL.createObjectURL(file));
      onChange(event);
    }
  };

  return (
    <>
      {photoRoute ?
        <div style={{ position: 'relative' }}>
          <img className={styles.mainImg} src={photoRoute} alt='photoRoute' />
          <button
            className={styles.delete}
            onClick={handleImageRemove}
          >
            <IconClose width={20} height={20} />
          </button>
        </div> :
        <label htmlFor='image'>
          <div className={styles.bigButton}>
            <AddIcon color={errors.image && '#FE5F55'} />
          </div>
        </label>}
      <input
        style={{ position: 'absolute', zIndex: -10, opacity: 0 }}
        id={'image'}
        onChange={handleImageChange}
        {...rest}
        type='file'
        name={'image'}
        accept='.png,.jpg,.jpeg,.gif,.heic'
      />
    </>
  );
};

export default BigImgInput;
