import React from 'react';
import styles from './ExcursionList.module.css';
import ExcursionCard from '../ExcursionCard';

function ExcursionList({ path, routes, type = 'default' }) {
  return (
    <section className={styles.excursionList}>
      {routes.map((item) => (
        <ExcursionCard
          card={item}
          key={item.id}
          path={path + item.id}
          type={type}
        />
      ))}
    </section>
  );
}
export default ExcursionList;