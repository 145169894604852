import PdfOfferUser from '../utils/documents/service_user_offer.pdf';
import PdfPolicy from '../utils/documents/privacy_policy.pdf';
import PdfConclusion from '../utils/documents/user_conclusion.pdf';
import PdfConsentUser from '../utils/documents/user_consent.pdf';
import PdfOfferAuthor from '../utils/documents/service_author_offer.pdf';
import PdfConsentAuthor from '../utils/documents/author_consent.pdf';

export const TITLE_DOCUMENTS_USER = 'Документы для пользователей';
export const TITLE_DOCUMENTS_AUTHOR = 'Документы для авторов';
export const TITLE_SEARCH_PAGE_ALL = 'Популярное';
export const MESSAGE_SEARCH_PAGE_NO_FOUND = 'По вашему запросу ничего не найдено. Возможно, стоит сформулировать по-другому или проверить раскладку.';

export const CHECKBOX_PERSONAL_DATA = 'Я согласен на обработку персональных данных';

export const lINK_USER = [
    {
      name: 'Публичная оферта',
      to: PdfOfferUser,
    },
    {
      name: 'Согласие на обработку персональных данных',
      to: PdfConsentUser,
    },
    {
      name: 'Пользовательское соглашение',
      to: PdfConclusion,
    },
    {
      name: 'Политика конфиденциальности',
      to: PdfPolicy,
    },
  ];

  export const lINK_AUTHOR = [
    {
      name: 'Договор на оказание услуг',
      to: PdfOfferAuthor,
    },
    {
      name: 'Согласие на обработку персональных данных',
      to: PdfConsentAuthor,
    },
  ];
