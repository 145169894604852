import React, { useEffect } from 'react';
import { loading } from '../../../../store/routes/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getModerationRoute } from '../../../../store/routes';
import { useParams } from 'react-router-dom';
import CurrentRoute from '../currentRoute';

const CardModeration = () => {
  const { idCard } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(loading);
  const routeData = useSelector((state) => state.routes.moderationRoute);

  useEffect(() => {
    dispatch(getModerationRoute(idCard));
  }, [idCard]);

  /*   useLayoutEffect(() => {
    console.log(card);
    setCard(routeData)
  }, []); */

  return <>{routeData !== null && <CurrentRoute card={routeData} />}</>;
};

export default CardModeration;
