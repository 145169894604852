import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HeadsetOutlinedIcon from '@mui/icons-material/HeadsetOutlined';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const SidebarData = [
  {
    title: 'Главная',
    path: '/search',
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    title: 'Купленное',
    path: '/bought',
    icon: <HeadsetOutlinedIcon />,
  },
  {
    title: 'Кабинет автора',
    path: '/new-event-pages',
    icon: <LocalCafeOutlinedIcon />,
  },
  {
    title: 'Избранное',
    path: '/favorite',
    icon: <FavoriteBorderOutlinedIcon />,
  },
  /*{
    title: 'Помощь',
    path: '/',
    icon: <LanguageOutlinedIcon />,
  },*/
];

export const OverlayContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: grey[900],
  opacity: 0.3,
  cursor: 'pointer',
}));
