import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';

export const NewAddButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ECFF9F',
  width: '100%',
  height: 200,
  borderRadius: 8,
  color: grey[600],
  display: 'flex',
  flexDirection: 'column',
  '&:hover': { color: '#1B8086' },
  '&:active': { color: '#1B8086' },
}));
