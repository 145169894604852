import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openClickDraft, openClickExcursion, openClickModeration } from '../../store/toolkitSlice';
import './styles.css';

function SearchSwitcher(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isOpenExcursion, isOpenModeration, isOpenDraft } = useSelector(
    (state) => state.modal
  );
  //Заменить на NavLink с состоянием isActive
  return (
    <>
      {location.pathname === '/new-event-pages' ? (
        <div className="searchSwitcher__event">
          <div className="link__wrapper">
            <button
              disabled={isOpenExcursion}
              onClick={() =>
                dispatch(!isOpenExcursion ? openClickExcursion() : '')
              }
              className={
                isOpenExcursion
                  ? 'searchSwitcher__button  active'
                  : 'searchSwitcher__button hover'
              }
            >
              Мои экскурсии
            </button>
          </div>
          <div className="link__wrapper">
            <button
              disabled={isOpenModeration}
              onClick={() =>
                dispatch(!isOpenModeration ? openClickModeration() : '')
              }
              className={
                isOpenModeration
                  ? 'searchSwitcher__button  active'
                  : 'searchSwitcher__button hover'
              }
            >
              На модерации
            </button>
          </div>
          <div className="link__wrapper">
            <button
              disabled={isOpenDraft}
              onClick={() => dispatch(!isOpenDraft ? openClickDraft() : '')}
              className={
                isOpenDraft
                  ? 'searchSwitcher__button  active'
                  : 'searchSwitcher__button hover'
              }
            >
              Черновик
            </button>
          </div>
        </div>
      ) : (
        <div className="searchSwitcher__search">
          <div className="link__wrapper">
            <button
              disabled={!props.isMap}
              onClick={props.onClick}
              className={
                !props.isMap
                  ? 'searchSwitcher__button  active'
                  : 'searchSwitcher__button hover'
              }
            >
              Список Экскурсий
            </button>
          </div>
          <div className="link__wrapper">
            <button
              disabled={props.isMap}
              onClick={props.onClick}
              className={
                props.isMap
                  ? 'searchSwitcher__button  active'
                  : 'searchSwitcher__button hover'
              }
            >
              Точки на карте
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default SearchSwitcher;
