import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCity, selectedCity } from '../../../store/city';
import { map } from 'lodash';

function CitySelect() {
  const [city, setCity] = useState('Все города');
  const arrCity = useSelector((state) => state.city.city);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCity());
  }, []);

  const handleChange = (evt) => {
    setCity(evt.target.value);
    const filterCity = evt.target.value === "Все города" ? false : evt.target.value 
    dispatch(selectedCity(filterCity) )
  };

  return (
    <FormControl
      variant="standard"
      sx={{ minWidth: 120, mt: -2, width: '100%', alignItems: 'center' }}
    >
      <InputLabel
        sx={{ minWidth: 120, margin: 0 }}
        id="city-select"
      ></InputLabel>
      <Select
        labelId="city-select"
        id="city-select-standard"
        value={city}
        onChange={handleChange}
        label="Город"
        sx={{
          fontSize: '20px',
          fonWeight: 500,
          lineHeight: '28px',
          letterSpacing: 0,
          textAlign: 'center',
          width: '180px',
          '& .MuiSelect-iconStandard': {
            color: '#1B8086',
          },
        }}
      >
                  <MenuItem  value={'Все города'}>
            Все города
          </MenuItem>
        {map(arrCity, (item, index) => (
          <MenuItem key={index} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default CitySelect;
