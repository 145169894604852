import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useDispatch } from 'react-redux';
import { disLikeRoute, likeRoute } from '../../../store/routes';
import { useLocation } from 'react-router-dom';

function LikeButton({ card }) {
  const location = useLocation();
  // const [isLike, setIsLike] = useState(location.pathname==='/favorite' ? true : false);
  const [isLike, setIsLike] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    location.pathname === '/favorite'
      ? setIsLike(true)
      : card.is_favorited
      ? setIsLike(true)
      : setIsLike(false);
  }, [card]);

  const handleLikeClick = () => {
    setIsLike(!isLike);
    dispatch(likeRoute(card.id));
  };
  const handledisLikeClick = () => {
    setIsLike(!isLike);
    dispatch(disLikeRoute(card.id));
  };

  return (
    <IconButton
      aria-label="like"
      onClick={!isLike ? handleLikeClick : handledisLikeClick}
    >
      {isLike ? (
        <FavoriteIcon sx={{ color: '#C6F04E' }} />
      ) : (
        <FavoriteBorderIcon sx={{ color: '#1B8086' }} />
      )}
    </IconButton>
  );
}

export default LikeButton;
