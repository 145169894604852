import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import StandardInput from '../../inputs/StandardInput';
import TextAreaInput from '../../inputs/TextAreaInput';
import ImgInputSmall from '../../inputs/ImgInputSmall';
import CoordinatesInput from '../../inputs/CoordinatesInput';
import SimpleFileInput from '../../inputs/SimpleFileInput';
import { Validation } from '../../../containers/newEvents/NewEvent/styles';

const PointsForm = ({
  resetField,
  control,
  openPopup,
  index,
  watch,
  register,
  errors,
}) => {
  return (
    <Box>
      <Typography variant="h3" sx={{ mt: '0.8rem', mb: '0.7rem' }}>
        Точка {index + 1}
      </Typography>
      <StandardInput
        control={control}
        name={`points${index}name`}
        placeholder={'Назовите точку'}
        register={register}
        validation={{
          maxLength: {
            value: 150,
            message: 'Не более 150 символов',
          },
          required: 'А можно конкретнее?',
        }}
      />
      <Box sx={{ height: '1rem' }}>
        {errors?.['points' + index + 'name'] && (
          <Validation>{errors?.['points' + index + 'name'].message}</Validation>
        )}
      </Box>

      <TextAreaInput
        multiline
        control={control}
        name={`points${index}description`}
        placeholder={'Опишите точку'}
        validation={{
          maxLength: {
            value: 5000,
            message: 'Не более 5000 символов',
          },
          required: 'Есть подробности?',
        }}
      />
      <Box sx={{ height: '1rem' }}>
        {errors?.['points' + index + 'description'] && (
          <Validation sx={{ top: '-10px' }}>
            {errors?.['points' + index + 'description'].message}
          </Validation>
        )}
      </Box>

      <CoordinatesInput
        register={register}
        name={`points${index}point_coords`}
        placeholder={'Отметьте место'}
        handleCoorChange={openPopup}
        readOnly
        validation={{
          required: 'Без этого никуда:)',
        }}
      />
      <Box sx={{ height: '1rem' }}>
        {errors?.['points' + index + 'point_coords'] && (
          <Validation>Без этого никуда:)</Validation>
        )}
      </Box>
      <SimpleFileInput
        resetField={resetField}
        watch={watch}
        readOnly
        register={register}
        name={`points${index}file`}
        marginBottom="0.8rem"
        cursor="pointer"
        placeholder="Вставьте аудио"
        text="Добавить аудио"
        btnText="+ добавить аудиозапись"
      />
      <Grid container sx={{ margin: '0.8rem 0', gap: 1.8 }}>
        <ImgInputSmall
          resetField={resetField}
          watch={watch}
          name={`points${index}uploaded_images`}
          register={register}
          validation={{ required: 'Загрузи фото' }}
        />
        <Box sx={{ height: '1rem' }}>
          {errors?.['points' + index + 'uploaded_images'] && (
            <Validation sx={{ top: '70px', left: '-90px' }}>
              {errors?.['points' + index + 'uploaded_images'].message}
            </Validation>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default PointsForm;
