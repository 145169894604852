import { useNavigate } from 'react-router-dom';
import styles from './Container.module.css';
import IconBack from '../../images_new/IconBack';

const Container = ({ title, children, styleTitle }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <button
        onClick={() => {
          navigate(-1);
        }}
        className={styles.arrow}
      >
        <IconBack width={12} height={20} color={'#979798'} />
      </button>
      <h1 className={styles.title} style={styleTitle}>{title}</h1>
      {children}
    </div>
  );
};

export default Container;
