import React, { useEffect, useState } from 'react';
import { Avatar, Grid, TextField, Typography } from '@mui/material';
import { ButtonDelete, ButtonSend } from './styles';
import Rating from '@mui/material/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getUser } from '../../../store/auth/selectors';
import { postReviews } from '../../../store/routes';

const labels = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
};

const CardReviewForm = ({ card }) => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [valueRating, setValueRating] = useState(5);
  const [hover, setHover] = useState(-1);
  const handleSubmitReview = (data) => {
    const id = card.id;
    const { text } = data;
    dispatch(postReviews({ id, text, valueRating }));
    reset();
  };

  useEffect(() => {
    if (valueRating === null || undefined) {
      setValueRating(0);
    }
  }, [valueRating]);

  return (
    userData && (
      <Grid
        onSubmit={handleSubmit(handleSubmitReview)}
        container
        direction="column"
        component="form"
        sx={{
          background: '#FAFEE9',
          gap: 1,
          borderRadius: 1,
          padding: 2.5,
          marginBottom: 2,
        }}
      >
        <Grid container direction="row" sx={{ gap: 1.5, alignItems: 'center' }}>
          <Avatar
            alt="Аватар"
            src={userData?.avatar}
            sx={{ width: 56, height: 56 }}
          />
          <div className="card__user-info">
            <Grid
              container
              sx={{ justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <Typography variant="subtitle1">
                {labels[hover !== -1 ? hover : valueRating]}/5
              </Typography>
              <Rating
                sx={{
                  marginLeft: '4px',
                  '& .MuiRating-iconFilled': { color: '#1B8086' },
                  '& .MuiRating-icon': {
                    color: '#1B8086',
                  },
                }}
                value={valueRating}
                onChange={(evt, newValue) => {
                  setValueRating(newValue);
                }}
                onChangeActive={(evt, newHover) => {
                  setHover(newHover);
                }}
              />
            </Grid>
            <Typography variant="subtitle1">{userData.first_name}</Typography>
          </div>
        </Grid>
        {/* <TextareaAutosize           placeholder="Введите описание"
       maxRows={4}
          sx={{
            minHeight: 50,
            marginBottom: 1.5,
            border: '1px solid #AAAAAA',
          }}
          {...register('text')}>

        </TextareaAutosize> */}
        <TextField
          variant="standard"
          {...register('text')}
          id="standard-textarea"
          label="Отзыв"
          placeholder="Введите описание"
          multiline
        />
        {/* <StandardInput
          placeholder="Введите описание"
          sx={{
            marginBottom: 1.5,
            border: '1px solid #AAAAAA',
          }}
          {...register('text')}
        /> */}
        <Grid
          container
          direction="row"
          sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <ButtonDelete
            type="button"
            onClick={() => {
              reset();
            }}
          >
            удалить
          </ButtonDelete>
          <ButtonSend type="submit">Отправить</ButtonSend>
        </Grid>
      </Grid>
    )
  );
};

export default CardReviewForm;
