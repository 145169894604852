import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axios';
import toast from 'react-hot-toast';

export const getRoutes = createAsyncThunk('getRoutes', async (city) => {
  const cityFilter = city === false ? '' : `&city__name=${city}`;
  const { data } = await axiosInstance.get(`api/routes/?limit=20${cityFilter}`);
  return data;
});

export const getAuthorRoutes = createAsyncThunk('getAuthorRoutes', async () => {
  const { data } = await axiosInstance.get(`api/routes/me`);
  return data;
});

export const getRoute = createAsyncThunk('getRoute', async (idCard) => {
  const { data } = await axiosInstance.get(`api/routes/${idCard}/`);
  return data;
});

export const postRoutes = createAsyncThunk(
  'postRoutes',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('api/routes/', params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteRoute = createAsyncThunk('deleteRoute', async (id) => {
  const { data } = await axiosInstance.delete(`api/draft_routes/${id}/`);
  return data;
});

export const searchRoutes = createAsyncThunk('searchRoutes', async (str) => {
  const { data } = await axiosInstance.get(`api/routes?limit=20&search=${str}`);
  return data;
});

export const getFavoritesRoutes = createAsyncThunk(
  'getFavoritesRoutes',
  async () => {
    const { data } = await axiosInstance.get('api/favorites/');
    return data;
  }
);

export const buyRoute = createAsyncThunk('buyRoute', async (id) => {
  const { data } = await axiosInstance.get(`api/routes/${id}/buy/`);
  return data;
});

export const getReview = createAsyncThunk('getReview', async (params) => {
  const { data } = await axiosInstance.get(
    `api/routes/${params.idCard}/reviews/${params.id}`
  );
  return data;
});

export const getReviews = createAsyncThunk('getReviews', async (id) => {
  const { data } = await axiosInstance.get(`api/routes/${id}/reviews/`);
  return data;
});

export const postReviews = createAsyncThunk('postReviews', async (params) => {
  const { data } = await axiosInstance.post(
    `api/routes/${params.idCard}/reviews/`,
    params.formData
  );
  return data;
});

export const deleteReviews = createAsyncThunk(
  'deleteReviews',
  async (params) => {
    const { data } = await axiosInstance.delete(
      `api/routes/${params.route_id}/reviews/${params.id}/`
    );
    return data;
  }
);

export const getPurchasedRoutes = createAsyncThunk(
  'getPurchasedRoutes',
  async () => {
    const { data } = await axiosInstance.get(`api/purchased`);
    return data;
  }
);

export const likeRoute = createAsyncThunk('likeRoute', async (id) => {
  const { data } = await axiosInstance.post(`api/routes/${id}/favorite/`);
  return data;
});

export const disLikeRoute = createAsyncThunk('disLikeRoute', async (id) => {
  const { data } = await axiosInstance.delete(`api/routes/${id}/favorite/`);
  return data;
});

export const getModerationRoutes = createAsyncThunk(
  'getModerationRoutes',
  async () => {
    const { data } = await axiosInstance.get(`api/route_on_moder/`);
    return data;
  }
);

export const getModerationRoute = createAsyncThunk(
  'getModerationRoute',
  async (id) => {
    const { data } = await axiosInstance.get(`api/route_on_moder/${id}/`);
    return data;
  }
);

export const patchRoutesModeration = createAsyncThunk(
  'patchRoutesModeration',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('api/routes/', params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDraftRoutes = createAsyncThunk('getDraftRoutes', async () => {
  const { data } = await axiosInstance.get(`api/draft_routes/`);
  return data;
});

export const getDraftRoute = createAsyncThunk('getDraftRoute', async (id) => {
  const { data } = await axiosInstance.get(`api/draft_routes/${id}/`);
  return data;
});

export const patchDraftRoute = createAsyncThunk(
  'patchDraftRoute',
  async (params) => {
    const { data } = await axiosInstance.patch(
      `api/draft_routes/${params.id}/`,
      params.formData
    );
    return data;
  }
);

const initialState = {
  tokenReview: false,
  success: null,
  routes: null,
  authorRoutes: null,
  currentRoute: null,
  imgRoutes: [],
  searchRoutes: null,
  isRequestSearchRoutes: false,
  favoritesRoutes: null,
  purchasedRoutes: null,
  moderationRoutes: null,
  moderationRoute: null,
  draftRoutes: null,
  draftRoute: null,
  selectedCard: null,
  reviews: null,
  reviewId: null,
  dots: [],
  loaders: {
    common: false,
  },
  errors: {
    create: null,
  },
};

const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    selectedCard: (state, { payload }) => {
      state.selectedCard = payload;
    },
    selectedCardById: (state, { payload }) => {
      let id = Number(payload.id);
      payload.location.pathname === '/search'
        ? (state.selectedCard = state.routes.find((item) => item.id === id))
        : payload.location.pathname === '/favorite'
        ? (state.selectedCard = state.favoritesRoutes.find(
            (item) => item.id === id
          ))
        : payload.location.pathname === '/search-result'
        ? (state.selectedCard = state.searchRoutes.find(
            (item) => item.id === id
          ))
        : (state.selectedCard = state.purchasedRoutes.find(
            (item) => item.id === id
          ));
    },
  },
  extraReducers: (builder) => {
    //getRoutes
    builder.addCase(getRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getRoutes.fulfilled, (state, { payload }) => {
      state.routes = payload.results;
      state.isRequestSearchRoutes = false;
      state.loaders.common = false;
    });
    builder.addCase(getRoutes.rejected, (state, { error }) => {
      if (error.message.includes('403')) state.tokenReview = true;
      state.loaders.common = false;

      toast.error('Error receiving data');
    });
    //getAuthorRoutes
    builder.addCase(getAuthorRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getAuthorRoutes.fulfilled, (state, { payload }) => {
      state.authorRoutes = payload;
      state.loaders.common = false;
    });
    builder.addCase(getAuthorRoutes.rejected, (state, { error }) => {
      if (error.message.includes('403')) state.tokenReview = true;
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //getRoute
    builder.addCase(getRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getRoute.fulfilled, (state, { payload }) => {
      state.currentRoute = payload;
      state.loaders.common = false;
    });
    builder.addCase(getRoute.rejected, (state, { error }) => {
      if (error.message.includes('403')) state.tokenReview = true;
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //deleteRoute
    builder.addCase(deleteRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(deleteRoute.fulfilled, (state) => {
      state.loaders.common = false;
    });
    builder.addCase(deleteRoute.rejected, (state, { error }) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //postRoutes
    builder.addCase(postRoutes.pending, (state) => {
      state.loaders.common = true;
      state.errors.create = null;
      state.success = false;
    });
    builder.addCase(postRoutes.fulfilled, (state, { payload }) => {
      state.routes = payload.results;
      state.loaders.common = false;
      state.success = true;
    });
    builder.addCase(postRoutes.rejected, (state, action) => {
      state.errors.create = action.payload;
      state.loaders.common = false;
      state.success = false;
    });
    //getReview
    builder.addCase(getReview.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getReview.fulfilled, (state, { payload }) => {
      state.reviewId = payload;
      state.loaders.common = false;
    });
    builder.addCase(getReview.rejected, (state, { error }) => {
      if (error.message.includes('403')) state.tokenReview = true;
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //getReviews
    builder.addCase(getReviews.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getReviews.fulfilled, (state, { payload }) => {
      state.reviews = payload;
      state.loaders.common = false;
    });
    builder.addCase(getReviews.rejected, (state, { error }) => {
      if (error.message.includes('403')) state.tokenReview = true;
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //postReviews
    builder.addCase(postReviews.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(postReviews.fulfilled, (state) => {
      state.loaders.common = false;
      state.success = true;
    });
    builder.addCase(postReviews.rejected, (state, action) => {
      state.loaders.common = false;
      state.success = false;
      toast.error('Error receiving data');
    });
    //deleteReviews
    builder.addCase(deleteReviews.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(deleteReviews.fulfilled, (state) => {
      state.loaders.common = false;
    });
    builder.addCase(deleteReviews.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //searchRoutes
    builder.addCase(searchRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(searchRoutes.fulfilled, (state, { payload }) => {
      state.searchRoutes = payload.results;
      state.isRequestSearchRoutes = true;
      state.loaders.common = false;
    });
    builder.addCase(searchRoutes.rejected, (state, { error }) => {
      if (error.message.includes('403')) state.tokenReview = true;
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //Favorites routes
    builder.addCase(getFavoritesRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getFavoritesRoutes.fulfilled, (state, { payload }) => {
      state.favoritesRoutes = payload;
      state.loaders.common = false;
    });
    builder.addCase(getFavoritesRoutes.rejected, (state, { error }) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
      if (error.message.includes('403')) state.tokenReview = true;
    });
    //getModerationRoutes
    builder.addCase(getModerationRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getModerationRoutes.fulfilled, (state, { payload }) => {
      state.moderationRoutes = payload;
      state.loaders.common = false;
    });
    builder.addCase(getModerationRoutes.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //getModerationRoute
    builder.addCase(getModerationRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getModerationRoute.fulfilled, (state, { payload }) => {
      state.moderationRoute = payload;
      state.loaders.common = false;
    });
    builder.addCase(getModerationRoute.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //patchRoutesModeration
    builder.addCase(patchRoutesModeration.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(patchRoutesModeration.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
      state.success = true;
    });
    builder.addCase(patchRoutesModeration.rejected, (state, action) => {
      const { errors } = action.payload;
      state.loaders.common = false;
      state.success = false;
    });
    //getDraftRoutes
    builder.addCase(getDraftRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getDraftRoutes.fulfilled, (state, { payload }) => {
      state.draftRoutes = payload;
      state.loaders.common = false;
    });
    builder.addCase(getDraftRoutes.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //getDraftRoute
    builder.addCase(getDraftRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getDraftRoute.fulfilled, (state, { payload }) => {
      state.draftRoute = payload;
      state.loaders.common = false;
    });
    builder.addCase(getDraftRoute.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //patchDraftRoute
    builder.addCase(patchDraftRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(patchDraftRoute.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
    });
    builder.addCase(patchDraftRoute.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //buyRoute
    builder.addCase(buyRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(buyRoute.fulfilled, (state) => {
      state.loaders.common = false;
      state.currentRoute.is_shopped = true;
    });
    builder.addCase(buyRoute.rejected, (state) => {
      state.loaders.common = false;
      state.currentRoute.is_is_shopped = false;
      toast.error('Error receiving data');
    });
    //getPurchasedRoutes
    builder.addCase(getPurchasedRoutes.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getPurchasedRoutes.fulfilled, (state, { payload }) => {
      state.purchasedRoutes = payload;
      state.loaders.common = false;
    });
    builder.addCase(getPurchasedRoutes.rejected, (state, { error }) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
      if (error.message.includes('403')) state.tokenReview = true;
    });
    //likeRoute
    builder.addCase(likeRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(likeRoute.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
    });
    builder.addCase(likeRoute.rejected, (state, action) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //disLikeRoute
    builder.addCase(disLikeRoute.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(disLikeRoute.fulfilled, (state, { payload }) => {
      state.loaders.common = false;
    });
    builder.addCase(disLikeRoute.rejected, (state, action) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
  },
});

export const { selectedCard, selectedCardById } = routesSlice.actions;

export default routesSlice.reducer;
