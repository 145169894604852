import React, { useEffect } from 'react';
import { Link } from '@mui/material';
import SearchSwitcher from '../../../components/SearchSwitcher';
import AddBigButton from '../../../components/buttons/AddBigButton';
import ExcursionList from '../../../components/ExcursionList';
import PageLayout from '../../../components/layout/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutes } from '../../../store/routes/index';

const NewEventList = (props) => {
  const dispatch = useDispatch();
  const route = useSelector((state) => state.routes.routes);
  const user = useSelector((state) => state.auth.user);
  const myRoutes = route?.filter((route) => route.author.id === user?.id);

  useEffect(() => {
    dispatch(getRoutes());
  }, []);

  return (
    <PageLayout variant={'second'}>
      <SearchSwitcher />
      <Link href="/new-event" sx={{ textDecoration: 'none' }}>
        <AddBigButton text={'Создай свою экскурсию'} />
      </Link>
      {myRoutes !== null && (
        <ExcursionList
          routes={myRoutes}
          path={'/route/'}
          clickOnCard={props.clickOnCard}
        />
      )}
    </PageLayout>
  );
};

export default NewEventList;
