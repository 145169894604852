import React from 'react';
import styles from './Profile.module.css';
import TabBar from '../../components_new/TabBar';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/auth/selectors';
import { useNavigate } from 'react-router-dom';
import IconMenuArrow from '../../images_new/IconMenuArrow';
import IconAuthor from '../../images_new/IconAuthor';
import IconProfile from '../../images_new/IconProfile';
import IconMyBuy from '../../images_new/IconMyBuy';
import IconAlarm from '../../images_new/IconAlarm';
import IconSecure from '../../images_new/IconSecure';
import IconAnswer from '../../images_new/IconAnswer';
import IconGarniture from '../../images_new/IconGarniture';
import IconLogout from '../../images_new/IconLogout';
import IconFeedback from '../../images_new/IconFeedback';
import IconDocument from '../../images_new/IconDocument';

import avatarPlug from '../../images_new/avatar_plug.svg';

const Profile = () => {
  const userData = useSelector(getUser);
  const navigate = useNavigate();
  const onlogout = () => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    navigate('/search');
    window.location.reload();
  };

  return (
    <section className={styles.container}>
      <div className={styles.userContainer}>
        <img
          className={styles.userAvatar}
          alt="Аватар"
          src={userData?.avatar !== null ? userData?.avatar : avatarPlug}
        />
        <div className={styles.userDescription}>
          <p className={styles.userName}>
            Здравствуйте, {userData?.first_name || 'Гость'}
          </p>
          <span className={styles.userEmail}>{userData?.email || ''}</span>
        </div>
        {/* <button className={styles.backButton}><IconMenuArrow /></button> */}
      </div>
      <button
        className={styles.createButton}
        onClick={() => navigate('/new-event')}
      >
        <p className={styles.createButtonText}>Создать свой маршрут</p>
        <div className={styles.createButtonImg}></div>
      </button>
      <p className={styles.menuTitle}>Настройки профиля</p>
      <div
        className={styles.menuSubtitle}
        onClick={() => navigate('/profile-edit')}
      >
        <div className={styles.menuIconProfile}>
          <IconProfile width={20} height={20} color={'rgb(54, 54, 54)'} />
        </div>
        <span className={styles.menuLink}>Личная информация</span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div className={styles.menuSubtitle} onClick={() => navigate('/bought')}>
        <div className={styles.menuIconProfile}>
          <IconMyBuy />
        </div>
        <span className={styles.menuLink}>Мои покупки</span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div className={styles.menuSubtitle} onClick={() => navigate('/author')}>
        <div className={styles.menuIconProfile}>
          <IconAuthor width={19} height={19} color={'rgb(54, 54, 54)'} />
        </div>
        <span className={`${styles.menuLink}`}>Кабинет автора</span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      {/* <div className={styles.menuSubtitle} >


                <div className={styles.menuIconProfile}><IconShield/></div>
                <span className={`${styles.menuLink} ${styles.disactive}`}>Вход и безопасность</span>
                <div className={styles.backButton}><IconMenuArrow width={9} height={16} /></div>
            </div> */}
      <div className={styles.menuSubtitle}>
        <div className={styles.menuIconProfile}>
          <IconAlarm />
        </div>
        <span className={`${styles.menuLink} ${styles.disactive}`}>
          Уведомления
        </span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div className={styles.menuSubtitle}>
        <div className={styles.menuIconProfile}>
          <IconSecure />
        </div>
        <span className={`${styles.menuLink} ${styles.disactive}`}>
          Конфиденциальность
        </span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <p className={styles.menuTitle}>Поддержка</p>
      <div className={styles.menuSubtitle}>
        <div className={styles.menuIconProfile}>
          <IconAnswer color={'rgb(151, 151, 152)'} />
        </div>
        <span className={`${styles.menuLink} ${styles.disactive}`}>
          Ответы на вопросы
        </span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div className={`${styles.menuSubtitle} ${styles.borderSubtitle}`}>
        <div className={styles.menuIconProfile}>
          <IconGarniture color={'rgb(151, 151, 152)'} />
        </div>
        <span className={`${styles.menuLink} ${styles.disactive}`}>
          Поддержка
        </span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div className={styles.menuSubtitle}>
        <div className={styles.menuIconProfile}>
          <IconFeedback color={'rgb(151, 151, 152)'} />
        </div>
        <span className={`${styles.menuLink} ${styles.disactive}`}>
          Обратная связь
        </span>
        {/* <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div> */}
      </div>
      <p className={styles.menuTitle}>Юридическая информация</p>
      <div 
        className={styles.menuSubtitle}
        onClick={() => navigate('/documents-user')}>
        <div className={styles.menuIconProfile}
        >
          <IconDocument color={'rgb(151, 151, 152)'} />
        </div>
        <span className={styles.menuLink}>Документы для пользователей</span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div 
        className={styles.menuSubtitle} 
        onClick={() => navigate('/documents-author')}
        >
        <div className={styles.menuIconProfile}>
          <IconDocument color={'rgb(151, 151, 152)'} />
        </div>
        <span className={styles.menuLink}>Документы для авторов</span>
        <div className={styles.backButton}>
          <IconMenuArrow width={9} height={16} />
        </div>
      </div>
      <div
        className={`${styles.menuSubtitle} ${styles.margin}`}
        onClick={() => onlogout()}
      >
        <div className={styles.menuIconProfile}>
          <IconLogout />
        </div>
        <span className={styles.menuLink}>Выйти</span>
        {/* <div className={styles.backButton}><IconMenuArrow width={9} height={16} /></div> */}
      </div>
      <TabBar />
    </section>
  );
};

export default Profile;
