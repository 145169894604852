const IconDraft = ({ width = 20, height = 20, color = '#363636' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2852 3.83986L16.1602 0.714856C16.1064 0.659033 16.0419 0.614627 15.9706 0.584295C15.8993 0.553964 15.8226 0.53833 15.7451 0.53833C15.6676 0.53833 15.5909 0.553964 15.5196 0.584295C15.4483 0.614627 15.3838 0.659033 15.3301 0.714856L5.95508 10.0899C5.90174 10.1439 5.85962 10.208 5.83113 10.2784C5.80264 10.3488 5.78834 10.4241 5.78906 10.5V13.625C5.78906 13.7804 5.8508 13.9294 5.96068 14.0393C6.07056 14.1492 6.2196 14.2109 6.375 14.2109H9.5C9.57688 14.2121 9.65315 14.1971 9.72382 14.1668C9.7945 14.1365 9.85799 14.0917 9.91016 14.0352L19.2852 4.66017C19.3407 4.60727 19.3849 4.54364 19.4151 4.47313C19.4454 4.40263 19.4609 4.32672 19.4609 4.25001C19.4609 4.17331 19.4454 4.0974 19.4151 4.02689C19.3849 3.95639 19.3407 3.89276 19.2852 3.83986ZM9.25586 13.0391H6.96094V10.7442L13.4062 4.29884L15.7012 6.59376L9.25586 13.0391ZM16.5312 5.76368L14.2363 3.46876L15.75 1.95509L18.0449 4.25001L16.5312 5.76368ZM18.6797 9.71876V18.3125C18.6797 18.6751 18.5356 19.0229 18.2792 19.2793C18.0229 19.5357 17.6751 19.6797 17.3125 19.6797H1.6875C1.3249 19.6797 0.97715 19.5357 0.720753 19.2793C0.464355 19.0229 0.320312 18.6751 0.320312 18.3125V2.68751C0.320312 2.32491 0.464355 1.97716 0.720753 1.72077C0.97715 1.46437 1.3249 1.32033 1.6875 1.32033H10.2812C10.4367 1.32033 10.5857 1.38206 10.6956 1.49194C10.8055 1.60183 10.8672 1.75086 10.8672 1.90626C10.8672 2.06166 10.8055 2.2107 10.6956 2.32058C10.5857 2.43047 10.4367 2.4922 10.2812 2.4922H1.6875C1.6357 2.4922 1.58602 2.51278 1.54939 2.54941C1.51276 2.58603 1.49219 2.63571 1.49219 2.68751V18.3125C1.49219 18.3643 1.51276 18.414 1.54939 18.4506C1.58602 18.4872 1.6357 18.5078 1.6875 18.5078H17.3125C17.3643 18.5078 17.414 18.4872 17.4506 18.4506C17.4872 18.414 17.5078 18.3643 17.5078 18.3125V9.71876C17.5078 9.56336 17.5695 9.41433 17.6794 9.30444C17.7893 9.19456 17.9383 9.13282 18.0938 9.13282C18.2492 9.13282 18.3982 9.19456 18.5081 9.30444C18.618 9.41433 18.6797 9.56336 18.6797 9.71876Z"
        fill={color}
      />
    </svg>
  );
};

export default IconDraft;
