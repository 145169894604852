import React from 'react';
import TabBar from '../../components_new/TabBar';
import IconMail from '../../images_new/IconMail';
import IconTelegram from '../../images_new/IconTelegram';
import IconBack from '../../images_new/IconBack';
import styles from './Partners.module.css';
import IconInfo from '../../images_new/IconInfo';
import IconHeadset from '../../images_new/IconHeadset';
import IconPencil from '../../images_new/IconPencil';
import { useNavigate } from 'react-router-dom';

const Partners = () => {
  const navigate = useNavigate();
  const clickBack = () => {
    navigate(-1)
  }
  return (
    <div className={styles.container}>
      <div onClick={clickBack}>
        <IconBack  width={10} height={18} color={'#767676'} />
      </div>
      <h2 className={styles.title}>Партнерам</h2>
      <div className={styles.listElement}>
        <IconInfo width={20} height={20} />
        <p className={styles.listText}>Ответы на вопросы</p>
        <div
          className={styles.lastElement}
          style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
        >
          <IconBack width={10} height={18} color={'#979798'} />
        </div>
      </div>
      <div className={styles.listElement}>
        <IconHeadset width={20} height={21} />
        <p className={styles.listText}>Поддержка</p>
        <div
          className={styles.lastElement}
          style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
        >
          <IconBack width={10} height={18} color={'#979798'} />
        </div>
      </div>
      <div className={styles.listElement}>
        <IconPencil width={19} height={19} />
        <p className={styles.listText}>Обратная связь</p>
        <div
          className={styles.lastElement}
          style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
        >
          <IconBack width={10} height={18} color={'#979798'} />
        </div>
      </div>
      <h3 className={styles.titleLinks}>Связаться с нами</h3>
      <div className={styles.links}>
        <a href="https://t.me/boldarevyury">
          <IconTelegram width={50} height={50} />
        </a>
        <a href="mailto:boldarevyury@yandex.ru">
          <IconMail width={50} height={50} />
        </a>
      </div>
      <TabBar />
    </div>
  );
};

export default Partners;
