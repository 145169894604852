import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function viberLink(url, { title, separator }) {
  assert(url, "viber.url")
  return (
    "viber://forward" +
    objectToGetParams({
      text: title ? title + separator + url : url
    })
  )
}

const ViberShareButton = createShareButton(
  "viber",
  viberLink,
  props => ({
    title: props.title,
    separator: props.separator || " "
  }),
  {
    windowWidth: 660,
    windowHeight: 460
  }
)

export default ViberShareButton