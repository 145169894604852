import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';

function MapCard({ card }) {
  const navigate = useNavigate();
  return (
    <div className="mapCard_old" onClick={() => navigate(`/route/${card.id}`)}>
      <div className="heart-wrapper">{/* <LikeButton card={card}/> */}</div>
      <img className="mapCard__image_old" src={card.image} alt={card.name} />
      <p className="mapCard__title_old">{card.name}</p>
      <p className="mapCard__subtitle_old">{card.description}</p>
      {/* <p className="mapCard__subtitle">Доступно  маршрутов</p> */}
    </div>
  );
}
export default MapCard;
