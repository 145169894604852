import InfoContainer from '../../components_new/InfoContainer';

const NotFound = () => {
  return (
    <InfoContainer code={404} linkText="Перейти на главную" link="/search">
      <p>Потерялись?</p>
      <p>К сожалению, страница не найдена.</p>
      <p>Но на главной вы сможете найти нужный маршрут.</p>
    </InfoContainer>
  );
};

export default NotFound;
