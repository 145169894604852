import React from 'react';
import styles from './TextAreaInput.module.css';

const TextAreaInput = ({ watch, placeholder, errors, name, register, counter}) => {
  return (
    <div className={styles.containerArea}>
          <textarea
            name={name}
            className={`${styles.textarea} ${errors.description && styles.error}`}
            placeholder={placeholder}
            {...register(name, { required: true, maxLength: 5000 })}
          />
      <p className={styles.symbols}>
        Максимум{' '}
        {watch(name)?.length
          ? counter - watch(name)?.length
          : counter}{' '}
        символов
      </p>
    </div>
  );
};

export default TextAreaInput;
