import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
import MapCoordPopup from '../../components/popUps/MapCoordPopup';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { grey, red } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSuccess } from '../../store/routes/selectors';
import { BoxInput, InputСost, Validation } from './styles';
import PageLayout from '../../components/layout/PageLayout';
import { deleteRoute, patchDraftRoute, postRoutes } from '../../store/routes';
import StandardInput from '../../components/inputs/StandardInput';
import formDataCombiner from '../../utils/formDataCombiner';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import ImgInputBig from '../../components/inputs/ImgInputBig';
import PointsForm from '../../components/forms/PointsForm';
import { map } from 'lodash';
import { getAllCity } from '../../store/city';
import { AllCity } from '../../store/city/selectors';
import { Autocomplete } from '@mui/lab';
import TextField from '@mui/material/TextField';

const EditRoute = () => {
  const [items, setItems] = useState([0]);
  const [currentTarget, setCurrentTarget] = useState('');
  const [isOpenPointsPopup, setIsOpenPointsPopup] = useState(false);
  const success = useSelector(getSuccess);
  const allCity = useSelector(AllCity);
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    control,
    watch,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onChange' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formData = new FormData();

  const addPoints = (itemData) => {
    setItems([...items, itemData]);
  };
  // const checkFD = () => {
  //   for (let pair of formData.entries()) {
  //     console.log(pair[0] + ', ' + pair[1]);
  //   }
  // };

  //const resetForm = () => reset();
  const togglePointsPopup = (evt) => {
    setIsOpenPointsPopup(!isOpenPointsPopup);
    setCurrentTarget(evt.target);
  };

  const sendInModeration = (data) => {
    formDataCombiner(data, formData);
    formData.set('is_draft', false);
    dispatch(postRoutes(formData));
  };

  const sendInDraft = (data) => {
    const id = state?.id;
    formDataCombiner(data, formData);
    dispatch(patchDraftRoute({ formData, id }));
  };

  const deleteRouteClick = () => {
    dispatch(deleteRoute(state?.id));
    navigate('/new-event-pages');
  };

  useEffect(() => {
    if (success === false) {
      navigate('/new-event-fail');
    } else if (success === true) {
      navigate('/new-event-success');
    }
  }, [success]);

  useEffect(() => {
    dispatch(getAllCity());
  }, []);

  return (
    <PageLayout
      deleteClick={deleteRouteClick}
      saveRoute={handleSubmit(sendInDraft)}
      variant="event"
      marginBottom="1rem"
    >
      <FormControl
        onSubmit={handleSubmit(sendInModeration)}
        component="form"
        variant="filled"
        sx={{ width: '100%' }}
      >
        <ImgInputBig
          resetField={resetField}
          getValues={getValues}
          watch={watch}
          name={'image'}
          register={register}
          validation={{ required: 'Загрузи фото' }}
          text={'Добавьте новое фото'}
        />
        <Box sx={{ height: 0 }}>
          {errors?.image && (
            <Validation sx={{ top: '-30px' }}>
              {errors?.image.message}
            </Validation>
          )}
        </Box>
        <Typography variant="h2" sx={{ mb: '0.7rem' }}>
          Маршрут
        </Typography>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={allCity}
          getOptionLabel={(option) => option.name}
          fullWidth
          size="medium"
          defaultValue={state?.city}
          renderInput={(params) => (
            <TextField
              {...register('city', { required: 'А в каком городе?' })}
              {...params}
              placeholder={'Выберите город'}
            />
          )}
        />
        <Box sx={{ height: '1rem' }}>
          {errors?.city && <Validation>{errors?.city.message}</Validation>}
        </Box>
        <StandardInput
          control={control}
          name="name"
          defaultValue={state?.name}
          placeholder={'Назовите машрут'}
          validation={{
            maxLength: {
              value: 150,
              message: 'Не более 150 символов',
            },
            required: 'А как называется?',
          }}
        />
        <Box sx={{ height: '1rem' }}>
          {errors?.name && <Validation>{errors?.name.message}</Validation>}
        </Box>
        <TextAreaInput
          multiline
          control={control || state?.description}
          name="description"
          defaultValue={state?.description}
          placeholder={'Опишите маршрут (до 5000 символов)'}
          validation={{
            maxLength: {
              value: 5000,
              message: 'Не более 5000 символов',
            },
            required: 'А что там будет?',
          }}
        />
        <Box sx={{ height: '1rem' }}>
          {errors?.description && (
            <Validation sx={{ top: '-10px' }}>
              {errors?.description.message}
            </Validation>
          )}
        </Box>
        <BoxInput>
          <CurrencyRubleOutlinedIcon sx={{ color: '#1B8086' }} />
          <InputСost
            defaultValue={state?.price}
            placeholder="Бесплатно"
            {...register('price', {
              setValueAs: (v) => {
                if (v === '') {
                  return Number(v);
                }
                return Number(v);
              },
            })}
          />
        </BoxInput>
        <Box sx={{ height: '1rem' }}>
          {!watch('price') && <Validation>или укажите стоимость</Validation>}
        </Box>
        {map(items, (item, index) => (
          <PointsForm
            resetField={resetField}
            errors={errors}
            watch={watch}
            register={register}
            control={control}
            index={index}
            key={index}
            openPopup={togglePointsPopup}
          />
        ))}

        <Button sx={{ mb: 1.8, mt: '3rem' }} variant="contained" type="submit">
          Отправить на модерацию
        </Button>
        <Link
          onClick={addPoints}
          sx={{
            mb: '2.4rem',
            display: 'block',
            textAlign: 'center',
            cursor: 'pointer',
          }}
        >
          добавить ещё точку
        </Link>
        <Grid
          container
          sx={{
            justifyContent: 'center',
            marginBottom: '2rem',
            color: red[500],
          }}
        >
          <Link
            onClick={deleteRouteClick}
            sx={{
              marginRight: '0.7rem',
              cursor: 'pointer',
              width: '6rem',
              display: 'flex',
              alignItems: 'center',
              color: red[500],
            }}
          >
            <DeleteOutlineOutlinedIcon
              sx={{
                color: red[500],
                marginRight: '0.5rem',
              }}
            />
            Удалить
          </Link>
          <Link
            onClick={handleSubmit(sendInDraft)}
            sx={{
              color: grey[900],
              width: '7rem',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SaveOutlinedIcon sx={{ marginRight: '0.5rem' }} />
            Сохранить
          </Link>
        </Grid>
      </FormControl>
      {isOpenPointsPopup && (
        <MapCoordPopup
          open={isOpenPointsPopup}
          close={togglePointsPopup}
          currentTarget={currentTarget}
        />
      )}
    </PageLayout>
  );
};

export default EditRoute;
