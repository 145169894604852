import React, { useRef, useState } from 'react';
import styles from './PopUpPlayer.module.css';
import { useSelector } from 'react-redux';
import { getDataAudio, getPlaying } from '../../../store/audioPlayer/selectors';
import PlaybackSlider from './PlaybackSlider';
import HeaderBtns from './HeaderBtns';
import AudioCover from './AudioCover';
import ControlBtns from './ControlBtns';
import ReactPlayer from 'react-player';

const PopUpPlayer = ({
  activePoint,
  setActivePoint,
  points,
  size = 'full',
}) => {
  const refPlayer = useRef();
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const dataAudio = useSelector(getDataAudio);
  const isPlaying = useSelector(getPlaying);

  const handleProgress = (state) => {
    const playedFraction = state.played; // Прогресс в долях (fractions)
    const playedPercentage = playedFraction * 100; // Прогресс в процентах

    setProgress(playedPercentage);
    setCurrentTime(state.playedSeconds);
    console.log(state)
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleChange = (e) => {
    setProgress(e.target.value);
    refPlayer.current.seekTo(e.target.value / 100)
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const containerClassName =
    size === 'small' ? styles.container_s : styles.containerOpen;

  const containerManagementClassName =
    size === 'small' ? '' : styles.containerManagement;

  return (
    <div className={containerClassName}>
      {dataAudio &&
        dataAudio.file && ( // Добавленная проверка
          <React.Fragment>
            {size === 'full' && <AudioCover dataAudio={dataAudio} />}
            <div className={containerManagementClassName} onClick={handleClick}>
              {size === 'full' && <HeaderBtns />}
              <PlaybackSlider
                progress={progress}
                onChange={handleChange}
                currentTime={currentTime}
                duration={duration}
                size={size}
              />
              <ControlBtns
                points={points}
                activePoint={activePoint}
                setActivePoint={setActivePoint}
                size={size}
              />
            </div>
            <ReactPlayer
              url={dataAudio.file}
              playing={isPlaying && dataAudio.name !== 'Вступление'}
              controls
              width="100%"
              height="100%"
              style={{ display: 'none' }}
              onProgress={handleProgress}
              onDuration={handleDuration}
              ref={refPlayer}
            />
          </React.Fragment>
        )}
    </div>
  );
};

export default PopUpPlayer;
