import React from 'react';

const IconShare = ({ width, height, color, bacgraundColor }) => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color || 'white'}
        d="M3.77138 6.19995H2.91424C2.45958 6.19995 2.02354 6.37555 1.70205 6.68811C1.38056 7.00067 1.19995 7.42459 1.19995 7.86662V14.5333C1.19995 14.9753 1.38056 15.3992 1.70205 15.7118C2.02354 16.0244 2.45958 16.2 2.91424 16.2H11.4857C11.9403 16.2 12.3764 16.0244 12.6978 15.7118C13.0193 15.3992 13.2 14.9753 13.2 14.5333V7.86662C13.2 7.42459 13.0193 7.00067 12.6978 6.68811C12.3764 6.37555 11.9403 6.19995 11.4857 6.19995H10.6285M7.19995 10.3666V1.19995M7.19995 1.19995L4.62852 3.69995M7.19995 1.19995L9.77138 3.69995"
        stroke={bacgraundColor || '#3495CF'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconShare;
