import React from 'react';

const IconMagnifierTabBar = ({ style, width, height, color, cursor }) => {
  return (
    <svg
      style={style}
      cursor={cursor}
      width={width || 23}
      height={height || 23}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.24998 21.75L6.10981 16.8815M3.41665 10.375C3.41665 12.8172 4.38681 15.1593 6.11371 16.8862C7.8406 18.6131 10.1828 19.5833 12.625 19.5833C15.0672 19.5833 17.4094 18.6131 19.1363 16.8862C20.8632 15.1593 21.8333 12.8172 21.8333 10.375C21.8333 7.93276 20.8632 5.59058 19.1363 3.86368C17.4094 2.13679 15.0672 1.16663 12.625 1.16663C10.1828 1.16663 7.8406 2.13679 6.11371 3.86368C4.38681 5.59058 3.41665 7.93276 3.41665 10.375Z"
            stroke={color || '#979798'}
            strokeWidth="1.91667"
            strokeLinecap="round"/>
    </svg>



  );
};

export default IconMagnifierTabBar;
