import React from 'react';

const IconMenuArrow = ({ width, height, color }) => {
  return (
    <svg width={width|| "11"} height={height || "19"} viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.25526 18.2208L10.3292 10.1708C10.4251 10.075 10.4931 9.97119 10.5333 9.85938C10.5736 9.74758 10.5934 9.62779 10.5928 9.50001C10.5928 9.37223 10.5726 9.25244 10.5324 9.14063C10.4921 9.02883 10.4244 8.92501 10.3292 8.82917L2.25526 0.755216C2.03165 0.531605 1.75213 0.4198 1.41672 0.4198C1.0813 0.4198 0.793801 0.539591 0.554217 0.779175C0.314634 1.01876 0.194842 1.29827 0.194842 1.61772C0.194842 1.93716 0.314634 2.21667 0.554217 2.45626L7.59797 9.50001L0.554217 16.5438C0.330606 16.7674 0.218801 17.043 0.218801 17.3708C0.218801 17.6985 0.338593 17.9819 0.578176 18.2208C0.817759 18.4604 1.09727 18.5802 1.41672 18.5802C1.73616 18.5802 2.01568 18.4604 2.25526 18.2208Z" fill="#979798" />
    </svg>
  );
};

export default (IconMenuArrow);




