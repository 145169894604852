import { useEffect, useState } from 'react';
import ExcursionList from '../../components/ExcursionList';
import SearchInput from '../../components/inputs/SearchInput';
import TabBar from '../../components_new/TabBar';
import MapButton from '../../components_new/buttons/MapButton';
import { useSelector } from 'react-redux';
import { getAllRoutes } from '../../store/routes/selectors';
import styles from './SearchPage.module.css';
import MapIcon from '../../images_new/map.svg';
import {
  TITLE_SEARCH_PAGE_ALL,
  MESSAGE_SEARCH_PAGE_NO_FOUND,
} from '../../utils/content';

function SearchPage() {
  const routes = useSelector(getAllRoutes);
  const [renderRoutes, setRenderRoutes] = useState([]);
  const [title, setTitle] = useState(TITLE_SEARCH_PAGE_ALL);
  const [wordForFilter, setWordForFilter] = useState('');

  useEffect(() => {
    if (renderRoutes?.length === routes?.length) {
      setTitle(TITLE_SEARCH_PAGE_ALL);
    } else if (renderRoutes?.length === 0) {
      setTitle(MESSAGE_SEARCH_PAGE_NO_FOUND);
    } else if (renderRoutes?.length === 1) {
      setTitle(`Найден ${renderRoutes.length} маршрут`);
    } else if (renderRoutes?.length > 1 && renderRoutes.length < 5) {
      setTitle(`Найдено ${renderRoutes.length} маршрута`);
    } else {
      setTitle(`Найдено ${renderRoutes?.length} маршрутов`);
    }
  }, [renderRoutes, routes]);

  useEffect(() => {
    if (wordForFilter) {
      setRenderRoutes(
        routes.filter((item) =>
          item.city
            .toLocaleLowerCase()
            .startsWith(wordForFilter.toLocaleLowerCase())
        )
      );
    } else {
      setRenderRoutes(routes);
    }
  }, [wordForFilter, routes]);

  useEffect(() => {
    setRenderRoutes(routes);
    setTitle(TITLE_SEARCH_PAGE_ALL);
  }, [routes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SearchInput setWordForFilter={setWordForFilter} />
      </div>
      <h2 className={styles.title}>{title}</h2>
      {renderRoutes?.length > 0 && (
        <ExcursionList routes={renderRoutes} path={`/route/`} />
      )}
      <MapButton text="Карта" icon={MapIcon} />
      <TabBar />
    </div>
  );
}
export default SearchPage;
