import { getUser } from '../../store/auth/selectors';
import { deleteReviews } from '../../store/routes';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Review.module.css';
import avatar from '../../images_new/avatar.png';
import { map } from 'lodash';
import Text from '../UI/Text';
import { Link, useLocation } from 'react-router-dom';

const Review = ({ review, card, setReviewList, reviewsData }) => {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const location = useLocation();

  const deleteReview = async (id) => {
    const params = {
      route_id: card.id,
      id: id,
    };
    await dispatch(deleteReviews(params));
    setReviewList(reviewsData.filter((el) => el.id !== id));
    //dispatch(getReviews(card.id));
  };

  return (
    <div className={styles.containerReview} key={review.id}>
      <img
        className={styles.avatar}
        alt="Avatar"
        src={review.author.avatar || avatar}
      />
      <div className={styles.review} id={review.id} key={review.id}>
        <div className={styles.bodyReview}>
          <h2 className={styles.name}>{review.author.first_name}</h2>
          {review?.text && <Text value={review.text} size="short" />}
          {review?.review_media?.length > 0 && (
            <div className={styles.containerImg}>
              {map(
                review.review_media,
                (photo, index) =>
                  photo && (
                    <Link
                      key={index}
                      to={`/${index}`}
                      state={{ background: location, url: photo.photo }}
                      className={styles.link}
                    >
                      <img className={styles.img} src={photo.photo} alt="Img" />
                    </Link>
                  )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Review;
