import styles from './DefaultView.module.css';
import { Link } from 'react-router-dom';

const DefaultView = ({
  text,
  link = '/search',
  linkText = 'Перейти на главную',
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>Здесь будут отображаться {text}</p>
      <Link to={link} className={styles.textLink}>
        {linkText}
      </Link>
    </div>
  );
};

export default DefaultView;
