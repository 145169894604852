import React, { forwardRef } from 'react';

import ShareButton from './ShareButton';

function createShareButton(networkName, link, optsMap, defaultProps) {
  function CreatedButton(props, ref) {
    const opts = optsMap(props)
    const passedProps = { ...props }

    // remove keys from passed props that are passed as opts
    const optsKeys = Object.keys(opts)
    optsKeys.forEach(key => {
      delete passedProps[key]
    })

    return (
      <ShareButton
        {...defaultProps}
        {...passedProps}
        forwardedRef={ref}
        networkName={networkName}
        networkLink={link}
        opts={optsMap(props)}
      />
    )
  }

  CreatedButton.displayName = `ShareButton-${networkName}`

  return forwardRef(CreatedButton)
}

export default createShareButton