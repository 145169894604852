import React from 'react';
import NewEventModeration from '../NewEventModeration';
import NewEventList from '../NewEventList';
import NewEventDraft from '../NewEventDraft';
import { useSelector } from 'react-redux';

const NewEventPages = () => {
  const { isOpenModeration, isOpenDraft, isOpenExcursion } = useSelector(
    (state) => state.switcher
  );

  if (isOpenExcursion) {
    return <NewEventList />;
  } else if (isOpenModeration) {
    return <NewEventModeration />;
  } else if (isOpenDraft) {
    return <NewEventDraft />;
  }
};

export default NewEventPages;
