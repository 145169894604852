import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LandingPage from '../LandingPage';
import ProfileEdit from '../../containers_new/ProfileEdit';
import { AnimatePresence } from 'framer-motion';
import SearchPage from '../../containers_new/SearchPage';
import NewEventSuccess from '../newEvents/NewEventSuccess';
import NewEventFail from '../newEvents/NewEventFail';
import SearchResult from '../searchPages/SearchResult';
import NewEvent from '../../containers_new/NewEvent';
import EditRoute from '../EditRoute';
import NewEventPages from '../newEvents/NewEventPages';
import { theme } from './styles';
import { ThemeProvider } from '@mui/material';
import FavoritesPage from '../FavoritesPages';
import BoughtItemPage from '../PurchasedPage';
import { checkAuth } from '../../store/auth';
import { useDispatch, useSelector } from 'react-redux';
import ProtectedRoute from '../../utils/castomHooks/ProtectedRoute';
import CardModeration from '../../containers/cards/Card/CardModeration';
import CardDraft from '../../containers/cards/Card/CardDraft';
import ServerErrorPage from '../ServerErrorPage/ServerErrorPage';
import PaymentSuccess from '../SuccessPayment/SuccessPayment';
import PaymentFail from '../PaymentFail/PaymentFail';
import { tokenReview } from '../../store/routes/selectors';
import Card from '../../containers_new/Card';
import MapPage from '../../containers_new/MapPage';
import Partners from '../../containers_new/Partners';
import Profile from '../../containers_new/Profile';
import PopUpLogin from '../../components_new/popUps/PopUpLogin';
import CardOpened from '../../containers_new/CardOpened';
import Author from '../../containers_new/Author';
import Drafts from '../../containers_new/Drafts';
import Publications from '../../containers_new/Publications';
import NotFound from '../../containers_new/NotFound';
import ServerError from '../../containers_new/ServerError';
import Documents from '../../containers_new/Documents';
import {
  PATH_DOCUMENTS_USER,
  PATH_DOCUMENTS_AUTHOR,
} from '../../utils/constants';
import PopUpImgBig from '../../components_new/popUps/PopUpImgBig';
import Modal from '../../containers_new/Modal';
import { getRoutes } from '../../store/routes';
import { filterCity } from '../../store/city/selectors';

function App() {
  const jwt = localStorage.getItem('accessToken');
  const tokenRev = useSelector(tokenReview);
  const dispatch = useDispatch();
  const location = useLocation();
  const background = location.state && location.state.background;
  const navigate = useNavigate();
  const [anonymUser, setAnonymUser] = useState(false);
  const { isOpen } = useSelector((store) => store.modal);
  const cityFilter = useSelector(filterCity);

  useEffect(() => {
    const jwt = localStorage.getItem('accessToken');
    if (jwt) {
      (async () => {
        await dispatch(checkAuth());
      })();
    }
  }, [jwt]);

  useEffect(() => {
    if (tokenRev === true) {
      localStorage.removeItem('accessToken');
      setAnonymUser(!anonymUser);
      navigate('/search');
      //window.location.reload()
    }
  }, [tokenRev]);

  useEffect(() => {
    dispatch(getRoutes(cityFilter));
  }, [dispatch, cityFilter]);

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence>
        <Routes location={background || location} key={location.pathname}>
          {/* Всегда открытые Роуты */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/route/:idCard" element={<Card />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/server-error" element={<ServerError />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-fail" element={<PaymentFail />} />
          <Route path="/map" element={<MapPage />} />
          {/* Всегда закрытые Роуты */}

          <Route
            path="/search"
            element={
              //  <ProtectedRoute>
              <SearchPage anonymUser={anonymUser} />
              //  </ProtectedRoute>
            }
          />
          <Route
            path="/search-result"
            element={
              // <ProtectedRoute>
              <SearchResult />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/partners"
            element={
              // <ProtectedRoute>
              <Partners />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/new-event"
            element={
              <ProtectedRoute>
                <NewEvent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-route/:id"
            element={
              <ProtectedRoute>
                <EditRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path="/card-opened/:idCard"
            element={
              <ProtectedRoute>
                <CardOpened />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moderation-route/:idCard"
            element={
              <ProtectedRoute>
                <CardModeration />
              </ProtectedRoute>
            }
          />
          <Route
            path="/draft-route/:idCard"
            element={
              <ProtectedRoute>
                <CardDraft />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile-edit"
            element={
              <ProtectedRoute>
                <ProfileEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-event-success"
            element={
              <ProtectedRoute>
                <NewEventSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-event-fail"
            element={
              <ProtectedRoute>
                <NewEventFail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-event-pages"
            element={
              <ProtectedRoute>
                <NewEventPages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/favorite"
            element={
              // <ProtectedRoute>
              <FavoritesPage />
              //  </ProtectedRoute>
            }
          />
          <Route
            path="/bought"
            element={
              //  <ProtectedRoute>
              <BoughtItemPage />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/documents"
            element={
              <ProtectedRoute>
                <ProfileEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATH_DOCUMENTS_USER}
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATH_DOCUMENTS_AUTHOR}
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/author"
            element={
              <ProtectedRoute>
                <Author />
              </ProtectedRoute>
            }
          />
          <Route
            path="/drafts"
            element={
              <ProtectedRoute>
                <Drafts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/publications"
            element={
              <ProtectedRoute>
                <Publications />
              </ProtectedRoute>
            }
          />
          {/*<Route path="*" element={<Navigate replace to="/" />} />*/}
        </Routes>
        {isOpen && <PopUpLogin />}

        {background && (
          <>
            <Routes>
              <Route
                path="/:idImg"
                element={
                  <Modal>
                    <PopUpImgBig />
                  </Modal>
                }
              />
            </Routes>
          </>
        )}
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default App;
