import React from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import newEventFailImg from '../../../images/newEventFailImg.svg';

const NewEventFail = () => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          direction="column"
          sx={{ marginTop: 6, padding: '0 1.3rem' }}
        >
          <Box
            component="img"
            src={newEventFailImg}
            sx={{
              height: '40vh',
              width: '41vh',
              display: 'flex',
              marginBottom: 4,
              alignItems: 'center',
            }}
          />
          <Typography variant="h2" sx={{ marginBottom: 1 }}>
            Произошёл сбой при отправке
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 4.8, maxWidth: 314, textAlign: 'center' }}
          >
            Проверьте подключение к интер-нету и попробуйте ещё раз. Если
            проблема не решена, обратитесь в службу поддержки.
          </Typography>
          <Button
            variant="contained"
            component={Link}
            href="/new-event"
            sx={{ marginBottom: 1.5 }}
          >
            Назад к редактированию
          </Button>
          <Button variant="reviews">начать чат с поддержкой</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewEventFail;
