import styles from './Drafts.module.css';
import Container from '../../components_new/Container';
import DefaultView from '../../components_new/DefaultView';
import TabList from '../../components_new/TabList';
import ExcursionList from '../../components/ExcursionList';
import IconQuestion from '../../images_new/IconQuestion';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModerRoutes } from '../../store/routes/selectors';
import { getModerationRoutes } from '../../store/routes';

const Drafts = () => {
  const tabs = ['В работе', 'На проверке'];
  const [current, setCurrent] = useState(tabs[1]);
  const [help, setHelp] = useState(false);
  const dispatch = useDispatch();
  const routesModer = useSelector(getModerRoutes);

  useEffect(() => {
    !routesModer?.length && dispatch(getModerationRoutes());
  }, []);

  const drafts = (
    <div className={styles.drafts}>
      <p className={styles.textDefault}>
        Совсем скоро здесь будут ваши черновики
      </p>
    </div>
  );

  return (
    <Container title="Черновики">
      {routesModer?.length > 0 ? (
        <>
          <TabList tabs={tabs} current={current} setCurrent={setCurrent} />
          <div className={styles.btnInfo} onClick={() => setHelp(!help)}>
            <IconQuestion />
            <span className={styles.text}>О проверке маршрутов</span>
            {help && (
              <div className={styles.help}>
                <span className={`${styles.text} ${styles.helpTitle}`}>
                  Проверка маршрута
                </span>
                <p className={styles.helpText}>
                  Проверка маршрутов занимает от 24 часов. После одобрения ваш
                  маршрут будет опубликован.
                </p>
              </div>
            )}
          </div>
          {current === tabs[0] ? (
            drafts
          ) : (
            <ExcursionList
              type={'wait'}
              routes={routesModer}
              path={`/route/`}
            />
          )}
        </>
      ) : (
        <DefaultView
          text="созданные вами маршруты"
          link="/new-event"
          linkText="Создать маршрут"
        />
      )}
    </Container>
  );
};

export default Drafts;
