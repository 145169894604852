import React, { useLayoutEffect, useState } from 'react';
import SecondHeader from '../SecondHeader';
import SideBar from '../SideBar';
import { MainWrapper } from './styles';
import Grid from '@mui/material/Grid';
import MainHeader from '../MainHaeder';
import EventHeader from '../EventHeader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthentication } from '../../../store/auth/selectors';

const PageLayout = ({
  children,
  variant,
  marginBottom,
  deleteClick,
  saveRoute,
}) => {
  const navigate = useNavigate();
  const isAuth = useSelector(isAuthentication);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isOpenFilterBar, setIsOpenFilterBar] = useState(false);
  const toggleSideBar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  };
  const toggleFilterBar = () => {
    setIsOpenFilterBar(!isOpenFilterBar);
  };

  useLayoutEffect(() => {
    if (isAuth === false) {
      navigate('auth');
    }
  }, [isAuth]);

  return (
    <MainWrapper>
      {variant === 'second' && (
        <SecondHeader openSideBar={toggleSideBar} marginBottom={marginBottom} />
      )}
      {variant === 'main' && (
        <MainHeader
          openSideBar={toggleSideBar}
          openFilterBar={toggleFilterBar}
        />
      )}
      {variant === 'event' && (
        <EventHeader
          openSideBar={toggleSideBar}
          openFilterBar={toggleFilterBar}
          deleteClick={deleteClick}
          saveRoute={saveRoute}
        />
      )}

      <Grid container flexDirection="column">
        {children}
      </Grid>
      {isOpenSidebar && (
        <SideBar isOpenSidebar={isOpenSidebar} close={toggleSideBar} />
      )}
      {/* {isOpenFilterBar && <SearchFilter close={toggleFilterBar}/>} */}
    </MainWrapper>
  );
};

export default PageLayout;
