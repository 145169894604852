import React, { useEffect, useState } from 'react';
import { BoxWrapper, TimeContainer, TimeItem } from './styles';
import { Box, Typography } from '@mui/material';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import './styles.css';

let integerPoint = null;

const PointsStandart = ({ card, point, setPoint, authorUser }) => {
  const location = useLocation();
  const [readMore, setReadMore] = useState(false)
  const points = card.points;
  integerPoint = Number(point);
  //для 'показать еще'
  const windowWidth = window.innerWidth;


  const handleClickMore = () => {

    setReadMore(true)
  }
  const handleClickMoreOff = () => {

    setReadMore(false)
  }
  useEffect(() => {
    setReadMore(false);
  }, [point])
  return (
    <BoxWrapper>
      {map(points, (step, index) => (
        <Box key={index}>
          <TimeItem
            onClick={() => { setPoint(index) }}
          >
            <TimeContainer
              onClick={() => { setPoint(index) }}
              sx={
                integerPoint === index
                  ? {
                    '&:after': { backgroundColor: '#94AB07' },
                    '&:before': { backgroundColor: '#94AB07' },
                  }
                  : ''
              }
            >
              <Typography
                variant="h3"
                sx={{ cursor: 'pointer', marginBottom: '20px' }}
                onClick={() => { setPoint(index) }}
              >
                {step.name}</Typography>


              {(step?.file?.includes('mp3') || step?.file?.includes('mpeg') || step?.file?.includes('MOV')||step?.file?.includes('ogg') || step?.file?.includes('wav')|| step?.file?.includes('mp4')) && integerPoint === index &&
                location.pathname.includes('audio') && (
                  <audio
                    sx={
                      integerPoint === index
                        ?

                        { display: 'block' }

                        : { display: 'none' }
                    }
                    id="pleer"
                    type="audio/mpeg"
                    src={integerPoint === index ? step?.file : ''}
                    controls
                  />
                )}
              <Typography onClick={handleClickMoreOff} sx={
                integerPoint === index && readMore === true && step?.description.length > 80
                  ?

                  { display: 'block', cursor: "pointer", color: "#1B8086", }

                  : { display: 'none' }
              } variant="subtitle3">Свернуть описание</Typography>
              {(card.is_shopped || card?.price === 0 || authorUser === true) && (
                <Typography
                  className={integerPoint === index ? readMore === true ? "showText" : "loadMoreText" : "noShowText"}
                  // sx={
                  //   integerPoint === index && readMore===true
                  //     ? 

                  //     readMore===true ? {display: 'block'  } : {display: 'block', 'loadMoreText' }

                  //     :  { display: 'none' }
                  // } 
                  variant="body2">{step.description}</Typography>
              )}
              <Typography onClick={handleClickMore} sx={
                integerPoint === index && readMore === false && step?.description.length > 80
                  ?

                  { display: 'block', cursor: "pointer", color: "#1B8086", }

                  : { display: 'none' }
              } variant="subtitle3">Читать описание</Typography>
              {/* {!card.is_shopped && card?.price!==0 &&(
                <Typography variant="body2">Описание</Typography>
              )} */}
              {/* <Typography variant="body2">
                {step.video === '' ? '' : step.video}
              </Typography>
              <Typography variant="body2">
                {step.audio === '' ? '' : step.audio} */}
              {/* </Typography> */}
            </TimeContainer>
            <time>{step.time}</time>
          </TimeItem>
        </Box>
      ))}
    </BoxWrapper>
  );
};

export default PointsStandart;
