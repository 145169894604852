import React from 'react';
import md5 from 'md5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './PurchaseButton.module.css';
import { isAuthentication } from '../../../store/auth/selectors';
import { openPopupLogin } from '../../../store/toolkitSlice';

const login = process.env.REACT_APP_ROBOKASSA_LOGIN;
const pass1 = process.env.REACT_APP_ROBOKASSA_PASS_1;

const PurchaseButton = ({ card }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthentication);

  const shopped = card?.is_shopped === true || card?.price === 0;
  const summ = card?.price;
  const receiptName = `Маршрут ${card?.id} ( ${card?.city} )`;

  const receipt = {
    sno: 'usn_income',
    items: [
      {
        name: receiptName,
        quantity: 1,
        sum: summ,
        tax: 'none',
      },
    ],
  };

  const url_sign = JSON.stringify(receipt);
  let url = encodeURIComponent(JSON.stringify(receipt));

  const signature = md5(
    `${login}:${summ}:0:${url_sign}:${pass1}:shp_interface=link:shp_route=${card.id}`
  );

  const link = !shopped
    ? `https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=${login}&InvId=0&Receipt=${url}&Culture=ru&Encoding=utf-8&OutSum=${summ}&shp_interface=link&shp_route=${card.id}&SignatureValue=${signature}`
    : '';
  const textButton = shopped ? 'Начать' : `Купить за ${card?.price}`;

  const handleClick = () => {
    navigate(`/card-opened/${card.id}`, {replace: true});
  };

  const openPopup = () => {
    dispatch(openPopupLogin());
  };

  return (
    <>
      {isAuth === true && shopped && (
        <a className={styles.buttonExcursion} onClick={() => handleClick()}>
          {textButton}
        </a>
      )}
      {isAuth === true && !shopped && (
        <a className={styles.buttonExcursion} href={link}>
          {textButton}
        </a>
      )}

      {card.price !== 0 ? <>&#8381;</> : ''}

      {isAuth !== true && (
        <button className={styles.buttonExcursion} onClick={openPopup}>
          {textButton}
        </button>
      )}
    </>
  );
};

export default PurchaseButton;
