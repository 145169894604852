import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenExcursion: true,
  isOpenModeration: false,
  isOpenDraft: false,
  isOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openPopupLogin: (state) => {
      state.isOpen = true;
    },
    closePopupLogin: (state) => {
      state.isOpen = false;
    },
    openClickExcursion: (state) => {
      state.isOpenExcursion = true;
      state.isOpenModeration = false;
      state.isOpenDraft = false;
    },
    openClickModeration: (state) => {
      state.isOpenModeration = true;
      state.isOpenExcursion = false;
      state.isOpenDraft = false;
    },
    openClickDraft: (state) => {
      state.isOpenDraft = true;
      state.isOpenModeration = false;
      state.isOpenExcursion = false;
    },
  },
});

export const {
  openClickExcursion,
  openClickModeration,
  openClickDraft,
  openPopupLogin,
  closePopupLogin,
} = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
