import { useEffect, useState } from 'react';
import styles from './LikeButton.module.css';
import { disLikeRoute, likeRoute } from '../../../store/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//import { useModal } from '../../../utils/castomHooks/useModal';
import { getUser, isAuthentication } from '../../../store/auth/selectors';
import IconLike from '../../../images_new/IconLike';
import IconLikeFull from '../../../images_new/IconLikeFull';
import { openPopupLogin } from '../../../store/toolkitSlice';

export const LikeButton = ({ card, width, height, color }) => {
  const [isLike, setIsLike] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(getUser);
  const isAuth = useSelector(isAuthentication);

  const handleLikeClick = (event) => {
    event.stopPropagation();
    setIsLike(!isLike);
    dispatch(likeRoute(card.id));
  };

  const handledisLikeClick = (event) => {
    event.stopPropagation();
    setIsLike(!isLike);
    dispatch(disLikeRoute(card.id));
  };

  useEffect(() => {
    location.pathname === '/favorite'
      ? setIsLike(true)
      : card?.is_favorited
      ? setIsLike(true)
      : setIsLike(false);
  }, [card]);

  return (
    <>
      <div
        onClick={(event) =>{
          event.stopPropagation()
          isAuth === true
            ? !isLike
              ? handleLikeClick(event)
              : handledisLikeClick(event)
            : dispatch(openPopupLogin())
        }}
        className={styles.like}
      >
        {isLike ? (
          <IconLikeFull width={width} height={height} color={color} />
        ) : (
          <IconLike width={width} height={height} color={color} />
        )}
      </div>
    </>
  );
};
