import styles from './MenuDocuments.module.css';
import IconMenuArrow from '../../images_new/IconMenuArrow';

const MenuDocuments = ({ links }) => {
  return (
    <>
      <nav>
        <ul className={styles.list}>
          {links.map((link) => (
            <li className={styles.li} key={link.name}>
              <a className={styles.link} href={link.to} target='_blank' rel="noreferrer">
                <span className={styles.linkName}>{link.name}</span>
                <IconMenuArrow width={9} height={16} />
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default MenuDocuments;