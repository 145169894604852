import React from 'react';
import styles from './MapButton.module.css';
import Map from '../../../images_new/map.svg';
import { useNavigate } from 'react-router-dom';

const MapButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/map');
  };

  return (
    <button onClick={handleClick} className={styles.button}>
      <p className={styles.text}>Карта</p>
      <img src={Map} className={styles.img} alt="map" />
    </button>
  );
};

export default MapButton;
