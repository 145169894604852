import React from 'react';

const IconMark = ({ width, height, color }) => {
  return (
    <svg width={width || 17} height={height || 20} viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.50002 0.348267C10.6241 0.348267 12.6612 1.19206 14.1632 2.69403C15.6652 4.19599 16.5089 6.2331 16.5089 8.3572C16.5089 11.7415 14.1309 15.4297 9.43645 19.4613C9.17542 19.6855 8.8426 19.8087 8.49848 19.8084C8.15436 19.8081 7.82176 19.6843 7.56113 19.4596L7.25063 19.1902C2.76398 15.2638 0.491089 11.6659 0.491089 8.3572C0.491089 6.2331 1.33488 4.19599 2.83685 2.69403C4.33882 1.19206 6.37592 0.348267 8.50002 0.348267ZM8.50002 5.27684C7.68306 5.27684 6.89956 5.60138 6.32188 6.17905C5.7442 6.75673 5.41966 7.54023 5.41966 8.3572C5.41966 9.17416 5.7442 9.95766 6.32188 10.5353C6.89956 11.113 7.68306 11.4376 8.50002 11.4376C9.31698 11.4376 10.1005 11.113 10.6782 10.5353C11.2558 9.95766 11.5804 9.17416 11.5804 8.3572C11.5804 7.54023 11.2558 6.75673 10.6782 6.17905C10.1005 5.60138 9.31698 5.27684 8.50002 5.27684Z'
        fill={color || '#3495CF'} />
    </svg>
  );
};

export default (IconMark);