import React from 'react';
import './styles.css';
import MapCard from '../../MapCard';
import { useSelector } from 'react-redux';

function SearchListPopup(props) {
  const data = useSelector((state) => state.routes.searchRoutes);
  return (
    <section
      className={
       'searchListPopup'
      }
    >

    

      <div className="searchListLine"></div>
      {data !==null && data.map((item, i) => (
        <MapCard card={item} key={i} />
      ))}
    </section>
  );
}
export default SearchListPopup;
