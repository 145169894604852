import React, { useState } from 'react';
import styles from '../PopUpPlayer.module.css';

const HeaderBtns = () => {
  const [focus, setFocus] = useState(false);

  return (
    <div className={styles.containerIcon}>
      <button className={styles.volume} />
      <div
        className={
          !focus ? styles.volumeContainer : styles.volumeContainerHidden
        }
      >
        <input
          min="0"
          max="100"
          step="10"
          className={styles.valueInput}
          type="range"
        />
      </div>
      <div className={styles.container}>
        <button className={styles.vector} />
        <button className={styles.dot} />
      </div>
    </div>
  );
};

export default HeaderBtns;