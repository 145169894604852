import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentRoute,
  getRoutesReviews,
} from '../../store/routes/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { LikeButton } from '../../components_new/buttons/LikeButton';
import { getRoute, getReviews, customEqual } from '../../store/routes';
import ReviewList from '../../components_new/ReviewList';
import PurchaseButton from '../../components_new/buttons/PurchaseButton';
import { PopUpShare } from '../../components_new/popUps/PopUpShare';
import Mark from '../../images_new/mark.svg';
import Avatar from '../../images_new/avatar.png';
import styles from './Card.module.css';
import IconClock from '../../images_new/IconClock';
import IconHeadphones from '../../images_new/IconHeadphones';
import IconWalk from '../../images_new/IconWalk';
import IconBack from '../../images_new/IconBack';
import IconShare from '../../images_new/IconShare';
import Text from '../../components_new/UI/Text';
import { getUser } from '../../store/auth/selectors';
import MainButton from '../../components_new/buttons/MainButton';

const Card = () => {
  const { idCard } = useParams();
  const dispatch = useDispatch();
  const routeData = useSelector(getCurrentRoute);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const reviewsData = useSelector(getRoutesReviews);
  const user = useSelector(getUser)

  const handleClickShare = () => {
    setShowPopup(true);
  };

  const handleClickMainButton = () => {
    navigate(`/card-opened/${routeData.id}`, {replace: true});
  };

  useEffect(() => {
    dispatch(getRoute(idCard));
  }, [idCard]);

  useEffect(() => {
    dispatch(getReviews(idCard));
  }, [idCard]);

  return (
    <>
      {routeData !== null && (
        <div className={styles.containerCard}>
          <div className={styles.containerImage}>
            <img
              className={styles.cover}
              src={routeData?.image}
              alt="Route cover"
            />
            <div className={styles.containerLike}>
              <LikeButton card={routeData} width={20} height={20} />
            </div>
            <div onClick={handleClickShare} className={styles.containerShare}>
              <IconShare />
            </div>
            <div
              onClick={() => {
                navigate(-1 ||'/search');
              }}
              className={styles.containerArrow}
            >
              <IconBack width={9} height={15} />
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.about}>
              <h1 className={styles.title}>{routeData?.name}</h1>
              <div className={styles.containerInfo}>
                <img className={styles.icon} src={Mark} alt="mark" />
                <h2 className={styles.text}>{routeData?.city}</h2>
              </div>
              <div className={styles.containerInfo}>
                <img
                  className={styles.iconImg}
                  src={
                    routeData?.author.avatar !== null
                      ? routeData?.author.avatar
                      : Avatar
                  }
                  alt="mark"
                /><a href={
                    routeData?.author?.username === 'vereteno' ? 'https://tenchat.ru/neurobusiness_vrtn': ''} >
                <h2 className={styles.textAuthot}>
                  {routeData?.author?.first_name
                    ? routeData.author.first_name +
                      ' ' +
                      routeData.author.last_name
                    : 'Автор маршрута'}
                </h2></a>
              </div>
            </div>
            <div className={styles.containerFeatures}>
              <div className={styles.containerElement}>
                <IconWalk width={10} height={17} />
                <p className={styles.text} style={{ marginLeft: '10px' }}>
                  Пешком
                </p>
              </div>
              <div className={styles.containerElement}>
                <IconClock width={18} height={18} />
                <p className={styles.text}>1 час</p>
              </div>
              <div className={styles.containerElement}>
                <IconHeadphones width={16} height={15} />
                <p className={styles.text}>
                  {routeData.points[0]?.file?.includes('mp' || 'mpeg')
                    ? 'аудио'
                    : 'экскурсия'}
                </p>
              </div>
            </div>
            {routeData?.description && <Text value={routeData.description} />}
          </div>
          <div className={styles.containerPrice}>
            <div>
              <p className={styles.textPrice}>Цена</p>
              <p className={styles.price}>{routeData?.price}&#8381;</p>
            </div>
            {routeData?.author.id === user?.id ? (
              <MainButton text='Открыть' onClick={handleClickMainButton} style={{ width: 216, margin: 0}}/>
            ) : (
              <PurchaseButton card={routeData} />
            )}            
          </div>
          {reviewsData?.length > 0 && <ReviewList card={routeData} />}
        </div>
      )}
      {!showPopup ? (
        ''
      ) : (
        <PopUpShare isOpen={showPopup} setShowPopup={setShowPopup} />
      )}
    </>
  );
};

export default Card;
