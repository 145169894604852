import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';

const SecondHeader = ({ marginBottom, openSideBar }) => {
  let navigate = useNavigate();
  const userData = useSelector(getUser);
  return (
    <Grid
      sx={{ marginBottom: marginBottom }}
      container
      justifyContent="space-between"
    >
      <IconButton onClick={openSideBar}>
        <Avatar
          alt="Аватарка"
          src={userData?.avatar}
          sx={{ width: 28, height: 28 }}
        />
      </IconButton>
      {/* <IconButton onClick={() => navigate('/search')}>
        <SearchIcon sx={{ color: '#1B8086' }} />
      </IconButton> */}
    </Grid>
  );
};

export default SecondHeader;
