import React from 'react';
import styles from './SimpleTextForClick.module.css';

const SimpleTextForClick = ({ disabled, text, onClick }) => {

  return (
  <button disabled={disabled} onClick={onClick} className={styles.button}>{text}</button>
  );
};

export default SimpleTextForClick;