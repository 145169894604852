import React from 'react';

const IconPicture = ({ width, height, color, backgroundColor }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill={backgroundColor || '#3495CF'} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.238 10.3333H21.7618C22.267 10.3333 22.7515 10.5339 23.1087 10.8911C23.4659 11.2484 23.6666 11.7328 23.6666 12.238V21.7618C23.6666 22.267 23.4659 22.7515 23.1087 23.1087C22.7515 23.4659 22.267 23.6666 21.7618 23.6666H12.238C11.7328 23.6666 11.2484 23.4659 10.8911 23.1087C10.5339 22.7515 10.3333 22.267 10.3333 21.7618V12.238C10.3333 11.7328 10.5339 11.2484 10.8911 10.8911C11.2484 10.5339 11.7328 10.3333 12.238 10.3333Z"
        stroke="#F8F7F9"
        strokeWidth="0.952381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6666 19.8571L20.8094 17L17.9523 19.8428M21.7618 23.6666L13.1904 15.0952L10.3333 17.9524"
        stroke="#F8F7F9"
        strokeWidth="0.952381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3332 14.6189C20.8592 14.6189 21.2856 14.1925 21.2856 13.6665C21.2856 13.1405 20.8592 12.7141 20.3332 12.7141C19.8073 12.7141 19.3809 13.1405 19.3809 13.6665C19.3809 14.1925 19.8073 14.6189 20.3332 14.6189Z"
        fill="#F8F7F9"
      />
    </svg>
  );
};

export default IconPicture;
