import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './CodeInput.module.css';
import InputMask from 'react-input-mask';

const CodeInput = ({ validation, control, name, error }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <div style={{ position: 'relative' }}>
          <InputMask
            mask=" 999999"
            maskChar="—"
            className={styles.input}
            style={{ color: error ? 'red' : 'black' }}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, '').slice(0, 6); // Оставляем только цифры
              field.onChange(value);
            }}
            value={field.value}
            id={name}
            placeholder=" ——————"
          />
          {error && <p className={styles.error}>не верный код</p>}
        </div>
      )}
    />
  );
};

export default CodeInput;
