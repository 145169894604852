import { Avatar, Box, Grid, Link, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { OverlayContainer, SidebarData } from './styles';
import { grey } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';
import useLockedScroll from '../../../utils/castomHooks/useLockedScroll';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import telegram from '../../../images/telegram.svg';
import { useEffect } from 'react';

const SideBar = ({ close, isOpenSidebar }) => {
  const userData = useSelector(getUser);
  const [locked, setLocked] = useLockedScroll(false, 'root');

  useEffect(() => {
    setLocked(isOpenSidebar);
  }, [isOpenSidebar]);

  return (
    <>
      <OverlayContainer onClick={close} />
      <Grid
        container
        direction="column"
        sx={{
          height: '100vh',
          padding: '1.9rem 0 1rem 2rem',
          backgroundColor: grey[50],
          position: 'absolute',
          top: 0,
          left: 0,
          maxWidth: '304px',
          width: '100%',
          zIndex: 1,
          borderRadius: '0 1rem 1rem 0',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
        }}
      >
        <Box>
          <Link href="/profile" sx={{ textDecoration: 'none' }}>
            <Avatar
              sx={{ width: 96, height: 96, marginBottom: 1 }}
              src={userData?.avatar}
            />
            <Grid container direction="column" sx={{ marginBottom: 0.5 }}>
              <Typography variant="subtitle4">
                {userData?.first_name}
              </Typography>
              <Typography variant="caption" sx={{ color: grey[900] }}>
                Статус: {userData?.role}
              </Typography>
            </Grid>
          </Link>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent' }}
            component="nav"
          >
            {SidebarData.map((item, index) => {
              return (
                <ListItemButton
                  sx={{ padding: 0 }}
                  component={Link}
                  href={item.path}
                  key={index}
                >
                  <ListItemIcon
                    sx={{ minWidth: 0, marginRight: 1.5, color: '#1B8086' }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
        <Grid container>
          <Typography sx={{ mb: 1.9 }}>Свяжитесь с нами:</Typography>
          <Grid container sx={{ gap: 1.9 }}>
            <Link
              sx={{
                width: '65px',
                height: '60px',
                boxShadow: '1.25px 1.25px 1.25px 1.25px rgba(0, 0, 0, 0.19)',
                display: 'grid',
                justifyItems: 'center',
                padding: '10px 7px 5px',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              href="mailto:Boldarevyury@yandex.ru"
              target="_blank"
            >
              <EmailOutlinedIcon sx={{ color: '#1B8086' }} />
              <Typography>Почта</Typography>
            </Link>
            <Link
              sx={{
                width: '65px',
                height: '60px',
                boxShadow: '1.25px 1.25px 1.25px 1.25px rgba(0, 0, 0, 0.19)',
                display: 'grid',
                justifyItems: 'center',
                padding: '10px 7px 5px',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              href="https://t.me/boldarevyury"
              target="_blank"
            >
              <img src={telegram} sx={{ color: '#1B8086' }} alt="telegram" />
              <Typography>Telegram</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SideBar;
