import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './StandardInput.module.css';

const StandardInput = ({ control, name, validation, error, placeholder }) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <>
          <input
            className={styles.input}
            style={{ color: error ? 'red' : 'black' }}
            onChange={(e) => field.onChange(e)}
            value={field.value}
            id={name}
            placeholder={placeholder}
          />
          {error && <p className={styles.error}>Введите корректный email</p>}
        </>
      )}
    />
  );
};

export default StandardInput;
