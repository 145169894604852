import { useState } from 'react';
import IconClose from '../../../images_new/IconClose';
import styles from './PopUpShare.module.css';
import Vk from '../../../images_new/vk.svg';
import WhatsApp from '../../../images_new/whatsapp.svg';
import Telegram from '../../../images_new/telegram.svg';
import Viber from '../../../images_new/viber.svg';
import OK from '../../../images_new/ok.svg';
import Copy from '../../../images_new/copy.svg';
import CopyBlue from '../../../images_new/copyBlue.svg';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentRoute } from '../../../store/routes/selectors';

export const PopUpShare = ({ isOpen, setShowPopup }) => {
  const location = useLocation();
  const route = useSelector(getCurrentRoute);
  const url = `https://Kibergid.ru${location.pathname}`;
  const title = route?.name;
  const [text, setText] = useState('Скопировать ссылку');
  const [color, setColor] = useState(styles.textCopy);
  const [img, setImg] = useState(Copy);

  const clickClose = () => {
    setShowPopup(false);
  };

  const clickCopyLink = () => {
    setImg(CopyBlue);
    setColor(styles.textCopyBlue);
    setText('Ссылка скопирована');
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <div onClick={clickClose} className={styles.containerPopup_opened}></div>
      <div className={!isOpen ? styles.popup : styles.popup_opened}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1 className={styles.title}>{route?.name}</h1>
            <div className={styles.btnClose} onClick={clickClose}>
              <IconClose />
            </div>
          </div>
          <div className={styles.containerButton}>
            <a
              href={`https://vk.com/share.php?url=${url}`}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={Vk} alt="vk" />
              <p className={styles.text}>VK</p>
            </a>
            <a
              href={`https://api.whatsapp.com/send?text=${url}`}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={WhatsApp} alt="whatsApp" />
              <p className={styles.text}>WhatsApp</p>
            </a>
            <a
              href={`https://t.me/share/url?url=${url}&text=${title}`}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={Telegram} alt="telegram" />
              <p className={styles.text}>Telegram</p>
            </a>
            <a
              href={`viber://forward?text=${url}`}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={Viber} alt="viber" />
              <p className={styles.text}>Viber</p>
            </a>
            <a
              href={`https://connect.ok.ru/offer?url=${url}`}
              className={styles.link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={OK} alt="OK" />
              <p className={styles.text}>OK</p>
            </a>
          </div>
          <div onClick={clickCopyLink} className={styles.containerCopy}>
            <img className={styles.imgCopy} src={img} alt="copy" />
            <p className={color}>{text}</p>
          </div>
        </div>
      </div>
    </>
  );
};
