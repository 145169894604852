import React from 'react';
import CitySelect from '../CitySelect';
import Avatar from '@mui/material/Avatar';
import { Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/auth/selectors';

const MainHeader = ({ openSideBar, openFilterBar }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log('otsuda1')
  //   dispatch(checkAuth());
  // }, []);

  const userData = useSelector(getUser);

  return (
    <Grid sx={{ mb: 1, flexWrap: 'nowrap' }} container>
      <IconButton onClick={openSideBar}>
        <Avatar
          alt="Аватарка"
          src={userData?.avatar}
          sx={{ width: 28, height: 28 }}
        />
      </IconButton>
      <CitySelect />
      {/* <IconButton onClick={openFilterBar}>
        <Settings sx={{ color: '#1B8086' }} />
      </IconButton> */}
    </Grid>
  );
};
export default MainHeader;
