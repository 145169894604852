import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function twitterLink(url, { title, via, hashtags = [], related = [] }) {
  assert(url, "twitter.url")
  assert(Array.isArray(hashtags), "twitter.hashtags is not an array")
  assert(Array.isArray(related), "twitter.related is not an array")

  return (
    "https://twitter.com/share" +
    objectToGetParams({
      url,
      text: title,
      via,
      hashtags: hashtags.length > 0 ? hashtags.join(",") : undefined,
      related: related.length > 0 ? related.join(",") : undefined
    })
  )
}

const TwitterShareButton = createShareButton(
  "twitter",
  twitterLink,
  props => ({
    hashtags: props.hashtags,
    title: props.title,
    via: props.via,
    related: props.related
  }),
  {
    windowWidth: 550,
    windowHeight: 400
  }
)

export default TwitterShareButton