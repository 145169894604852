import React, { Component } from 'react';
import cx from 'classnames';

const isPromise = obj =>
  !!obj &&
  (typeof obj === "object" || typeof obj === "function") &&
  typeof obj.then === "function"

const getBoxPositionOnWindowCenter = (width, height) => ({
  left:
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2,
  top:
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2
})

const getBoxPositionOnScreenCenter = (width, height) => ({
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2
})

function windowOpen(url, { height, width, ...configRest }, onClose) {
  const config = {
    height,
    width,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes",
    ...configRest
  }

  const shareDialog = window.open(
    url,
    "",
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(", ")
  )

  if (onClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval)
          onClose(shareDialog)
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e)
        /* eslint-enable no-console */
      }
    }, 1000)
  }

  return shareDialog
}

export default class ShareButton extends Component {
  static defaultProps = {
    disabledStyle: { opacity: 0.6 },
    openShareDialogOnClick: true,
    resetButtonStyle: true
  }

  openShareDialog = link => {
    const {
      onShareWindowClose,
      windowHeight = 400,
      windowPosition = "windowCenter",
      windowWidth = 550
    } = this.props

    const windowConfig = {
      height: windowHeight,
      width: windowWidth,
      ...(windowPosition === "windowCenter"
        ? getBoxPositionOnWindowCenter(windowWidth, windowHeight)
        : getBoxPositionOnScreenCenter(windowWidth, windowHeight))
    }

    windowOpen(link, windowConfig, onShareWindowClose)
  }

  handleClick = async event => {
    const {
      beforeOnClick,
      disabled,
      networkLink,
      onClick,
      url,
      openShareDialogOnClick,
      opts
    } = this.props

    const link = networkLink(url, opts)

    if (disabled) {
      return
    }

    event.preventDefault()

    if (beforeOnClick) {
      const returnVal = beforeOnClick()

      if (isPromise(returnVal)) {
        await returnVal
      }
    }

    if (openShareDialogOnClick) {
      this.openShareDialog(link)
    }

    if (onClick) {
      onClick(event, link)
    }
  }

  render() {
    const {
      beforeOnClick,
      children,
      className,
      disabled,
      disabledStyle,
      forwardedRef,
      networkLink,
      networkName,
      onShareWindowClose,
      openShareDialogOnClick,
      opts,
      resetButtonStyle,
      style,
      url,
      windowHeight,
      windowPosition,
      windowWidth,
      ...rest
    } = this.props

    const newClassName = cx(
      "react-share__ShareButton",
      {
        "react-share__ShareButton--disabled": !!disabled,
        disabled: !!disabled
      },
      className
    )

    const newStyle = resetButtonStyle
      ? {
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
        font: "inherit",
        color: "inherit",
        cursor: "pointer",
        ...style,
        ...(disabled && disabledStyle)
      }
      : {
        ...style,
        ...(disabled && disabledStyle)
      }

    return (
      <button
        {...rest}
        aria-label={rest["aria-label"] || networkName}
        className={newClassName}
        onClick={this.handleClick}
        ref={forwardedRef}
        style={newStyle}
      >
        {children}
      </button>
    )
  }
}