import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axios';
import toast from 'react-hot-toast';

export const getPoints = createAsyncThunk('getPoints', async (id) => {
  const { data } = await axiosInstance.get(`api/points/${id}/`);
  return data;
});

const initialState = {
  points: [],
  loaders: {
    common: false,
  },
};

const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getPoints
    builder.addCase(getPoints.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getPoints.fulfilled, (state, { payload }) => {
      state.points = payload;
      state.loaders.common = false;
    });
    builder.addCase(getPoints.rejected, (state, { error }) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
  },
});

export const { selectedCard, selectedCardById } = pointsSlice.actions;

export default pointsSlice.reducer;
