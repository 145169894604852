import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axios';
import toast from 'react-hot-toast';

export const getCity = createAsyncThunk('getCity', async () => {
  const { data } = await axiosInstance.get('api/city_route/');
  return data;
});

export const getAllCity = createAsyncThunk('getAllCity', async () => {
  const { data } = await axiosInstance.get('api/city/');
  return data;
});

const initialState = {
  city: [],
  allCity: [],
  selectedCity: false,
  loaders: {
    common: false,
  },
};

const citySlice = createSlice({
  name: 'city',
  initialState,
 reducers: {
    selectedCity: (state, { payload }) => {
      state.selectedCity = payload;
    }},
  extraReducers: (builder) => {
    //getCity
    builder.addCase(getCity.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getCity.fulfilled, (state, { payload }) => {
      state.city = payload;
      state.loaders.common = false;
    });
    builder.addCase(getCity.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
    //getAllCity
    builder.addCase(getAllCity.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getAllCity.fulfilled, (state, { payload }) => {
      state.allCity = payload;
      state.loaders.common = false;
    });
    builder.addCase(getAllCity.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Error receiving data');
    });
  },
});
export const { selectedCity} = citySlice.actions;
export default citySlice.reducer;
