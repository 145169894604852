import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function facebookLink(url, { quote, hashtag }) {
  assert(url, "facebook.url")

  return (
    "https://www.facebook.com/sharer/sharer.php" +
    objectToGetParams({
      u: url,
      quote,
      hashtag
    })
  )
}

const FacebookShareButton = createShareButton(
  "facebook",
  facebookLink,
  props => ({
    quote: props.quote,
    hashtag: props.hashtag
  }),
  {
    windowWidth: 550,
    windowHeight: 400
  }
)

export default FacebookShareButton