import React from 'react';

const IconBack = ({width, height, color }) => {
  return (
    <svg width={width || 9} height={height || 16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.48301 15.3278L0.620141 8.48528C0.538683 8.40383 0.480848 8.31558 0.446635 8.22054C0.412423 8.12551 0.395588 8.02369 0.396131 7.91508C0.396131 7.80647 0.413237 7.70464 0.44745 7.60961C0.481662 7.51457 0.539226 7.42633 0.620141 7.34487L7.48301 0.482003C7.67308 0.291934 7.91066 0.196899 8.19577 0.196899C8.48087 0.196899 8.72525 0.298722 8.92889 0.502368C9.13254 0.706014 9.23436 0.943601 9.23436 1.21513C9.23436 1.48666 9.13254 1.72424 8.92889 1.92789L2.9417 7.91508L8.92889 13.9023C9.11896 14.0923 9.21399 14.3267 9.21399 14.6052C9.21399 14.8838 9.11217 15.1247 8.90853 15.3278C8.70488 15.5314 8.46729 15.6333 8.19577 15.6333C7.92424 15.6333 7.68665 15.5314 7.48301 15.3278Z"
            fill={color || '#3495CF'}
      />
    </svg>
  );
};

export default (IconBack);




