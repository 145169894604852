import React, { useState } from 'react';
import ExcursionList from '../../../components/ExcursionList';
import { Typography } from '@mui/material';
import SearchListPopup from '../../../components/popUps/SearchListPopup';
import { useSelector } from 'react-redux';
import blankImage from '../../../images/noResult.png';
import styles from './SearchResult.module.css';



/** контейнер не используется */

function SearchResult() {
  const routes = useSelector((state) => state.routes.searchRoutes);
  const [isMap, setIsMap] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleMovePopup = () => setIsOpen(!isOpen);

  return (
    <>
      {routes === null || routes.length === 0 ? (
        <div className={styles.container}>
          <Typography
            variant="subtitle3"
            sx={{ textAlign: 'center', margin: '0 auto 10px' }}
          >
            Найдено 0 вариантов
          </Typography>
          <img
            src={blankImage}
            style={{ width: '70%', maxWidth: '400px', margin: '0 auto 10px' }}
          />
          <Typography
            variant="subtitle5"
            sx={{ textAlign: 'center', margin: '0 auto' }}
          >
            По вашему запросу ничего не найдено.Возможно, стоит сформулировать
            по-другому или проверить раскладку
          </Typography>
        </div>
      ) : (
        <Typography
          variant="subtitle3"
          sx={{ textAlign: 'center', margin: '0 auto 10px' }}
        >
          Найдено {routes?.length} вариантов
        </Typography>
      )}
      {!isMap && routes !== null && (
        <ExcursionList routes={routes} path={`/route/`} />
      )}
      {isMap && <SearchListPopup movePopup={handleMovePopup} isOpen={isOpen} />}
    </>
  );
}
export default SearchResult;
