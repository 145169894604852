import React from 'react';

const IconMyBuy = ({ width, height, color }) => {
  return (
<svg width={width || 22} height={ height || 17} viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.4668 6.91797C21.3379 6.74362 21.1702 6.60167 20.977 6.50336C20.7838 6.40505 20.5703 6.35308 20.3535 6.35156H18.8984V4.59375C18.8984 4.23115 18.7544 3.8834 18.498 3.627C18.2416 3.3706 17.8939 3.22656 17.5312 3.22656H10.7637C10.7425 3.22836 10.7212 3.22582 10.7011 3.2191C10.6809 3.21239 10.6623 3.20163 10.6465 3.1875L7.93164 1.15625C7.69466 0.979386 7.40703 0.883511 7.11133 0.882812H1.90625C1.54365 0.882812 1.1959 1.02686 0.939503 1.28325C0.683105 1.53965 0.539063 1.8874 0.539062 2.25V16.3125C0.540168 16.4346 0.577561 16.5535 0.646484 16.6543C0.701551 16.7296 0.773543 16.791 0.85666 16.8334C0.939776 16.8758 1.03169 16.8981 1.125 16.8984H18.3125C18.4356 16.8986 18.5556 16.86 18.6555 16.7882C18.7554 16.7163 18.8302 16.6148 18.8691 16.498L21.6523 8.14844C21.7194 7.94306 21.7376 7.72486 21.7053 7.51122C21.6731 7.29759 21.5914 7.09444 21.4668 6.91797ZM1.90625 2.05469H7.11133C7.13249 2.05289 7.1538 2.05543 7.17395 2.06215C7.19409 2.06886 7.21266 2.07962 7.22852 2.09375L9.94336 4.125C10.1803 4.30186 10.468 4.39774 10.7637 4.39844H17.5312C17.583 4.39844 17.6327 4.41901 17.6694 4.45564C17.706 4.49227 17.7266 4.54195 17.7266 4.59375V6.35156H4.81641C4.52847 6.34987 4.24745 6.43978 4.01397 6.60831C3.78049 6.77683 3.60665 7.01524 3.51758 7.28906L1.71094 12.6992V2.25C1.71094 2.1982 1.73151 2.14852 1.76814 2.11189C1.80477 2.07526 1.85445 2.05469 1.90625 2.05469ZM20.5391 7.77734L17.8926 15.7266H1.93555L4.63086 7.66016C4.64013 7.61884 4.66401 7.58226 4.6981 7.55714C4.73219 7.53203 4.7742 7.52006 4.81641 7.52344H20.3535C20.384 7.52263 20.4142 7.52934 20.4414 7.54296C20.4687 7.55659 20.4921 7.57671 20.5098 7.60156C20.5282 7.62644 20.5405 7.65535 20.5456 7.68592C20.5507 7.71648 20.5485 7.74782 20.5391 7.77734Z"
 fill="#363636"/>
</svg>
  );
};

export default (IconMyBuy);




