import assert from '../../../utils/shareUtils/assert';
import objectToGetParams from '../../../utils/shareUtils/objectToGetParams';
import createShareButton from '../../../utils/shareUtils/createShareButton';

function okLink(url, { title, description, image }) {
  assert(url, "ok.url")

  return (
    "https://connect.ok.ru/offer" +
    objectToGetParams({
      url,
      title,
      description,
      imageUrl: image
    })
  )
}

const OKShareButton = createShareButton(
  "ok",
  okLink,
  props => ({
    title: props.title,
    description: props.description,
    image: props.image
  }),
  {
    windowWidth: 588,
    windowHeight: 480,
    windowPosition: "screenCenter"
  }
)

export default OKShareButton