const IconWallet = ({ width = 20, height = 17, color = '#363636' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0938 3.22656H2.46875C2.20975 3.22656 1.96136 3.12367 1.77822 2.94053C1.59508 2.75739 1.49219 2.509 1.49219 2.25C1.49219 1.991 1.59508 1.74261 1.77822 1.55947C1.96136 1.37633 2.20975 1.27344 2.46875 1.27344H15.75C15.9054 1.27344 16.0544 1.21171 16.1643 1.10182C16.2742 0.991936 16.3359 0.8429 16.3359 0.6875C16.3359 0.5321 16.2742 0.383064 16.1643 0.27318C16.0544 0.163295 15.9054 0.101563 15.75 0.101562H2.46875C1.89974 0.104131 1.35477 0.331309 0.952413 0.733663C0.550059 1.13602 0.322881 1.68099 0.320312 2.25V14.75C0.322881 15.319 0.550059 15.864 0.952413 16.2663C1.35477 16.6687 1.89974 16.8959 2.46875 16.8984H18.0938C18.4564 16.8984 18.8041 16.7544 19.0605 16.498C19.3169 16.2416 19.4609 15.8939 19.4609 15.5312V4.59375C19.4609 4.23115 19.3169 3.8834 19.0605 3.627C18.8041 3.3706 18.4564 3.22656 18.0938 3.22656ZM18.2891 15.5312C18.2891 15.583 18.2685 15.6327 18.2319 15.6694C18.1952 15.706 18.1455 15.7266 18.0938 15.7266H2.46875C2.20975 15.7266 1.96136 15.6237 1.77822 15.4405C1.59508 15.2574 1.49219 15.009 1.49219 14.75V4.16406C1.795 4.3172 2.12941 4.39746 2.46875 4.39844H18.0938C18.1455 4.39844 18.1952 4.41901 18.2319 4.45564C18.2685 4.49227 18.2891 4.54195 18.2891 4.59375V15.5312ZM15.5547 10.0625C15.5547 10.2556 15.4974 10.4445 15.3901 10.6051C15.2828 10.7656 15.1303 10.8908 14.9518 10.9647C14.7734 11.0386 14.577 11.058 14.3876 11.0203C14.1982 10.9826 14.0242 10.8896 13.8876 10.753C13.751 10.6165 13.658 10.4425 13.6203 10.253C13.5826 10.0636 13.602 9.86723 13.6759 9.68879C13.7498 9.51034 13.875 9.35782 14.0356 9.25052C14.1962 9.14321 14.385 9.08594 14.5781 9.08594C14.8371 9.08594 15.0855 9.18882 15.2687 9.37197C15.4518 9.55511 15.5547 9.8035 15.5547 10.0625Z"
        fill={color}
      />
    </svg>
  );
};

export default IconWallet;
