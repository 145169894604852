import React from 'react';

const IconGarniture = ({ width, height, color }) => {
  return (
    <svg
      width={width || '21'}
      height={height || '23'}
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5771 3.47559C16.6607 2.54941 15.5705 1.81325 14.3689 1.30933C13.1673 0.805418 11.8781 0.543657 10.5752 0.539062H10.5C7.8582 0.539063 5.32459 1.58852 3.45655 3.45655C1.58852 5.32459 0.539063 7.8582 0.539062 10.5V15.9688C0.539063 16.5386 0.765415 17.085 1.16833 17.4879C1.57124 17.8908 2.1177 18.1172 2.6875 18.1172H4.25C4.8198 18.1172 5.36626 17.8908 5.76918 17.4879C6.17208 17.085 6.39844 16.5386 6.39844 15.9688V12.0625C6.39844 11.4927 6.17208 10.9462 5.76918 10.5433C5.36626 10.1404 4.8198 9.91406 4.25 9.91406H1.73047C1.87911 7.68944 2.86766 5.60446 4.49591 4.08137C6.12417 2.55828 8.27042 1.71093 10.5 1.71094H10.5664C12.7866 1.72079 14.9203 2.57328 16.5361 4.09605C18.1519 5.61883 19.1292 7.6983 19.2705 9.91406H16.75C16.1802 9.91406 15.6337 10.1404 15.2308 10.5433C14.8279 10.9462 14.6016 11.4927 14.6016 12.0625V15.9688C14.6016 16.5386 14.8279 17.085 15.2308 17.4879C15.6337 17.8908 16.1802 18.1172 16.75 18.1172H19.2891V18.3125C19.2891 18.9859 19.0216 19.6317 18.5454 20.1079C18.0692 20.5841 17.4234 20.8516 16.75 20.8516H11.2812C11.1258 20.8516 10.9768 20.9133 10.8669 21.0232C10.757 21.1331 10.6953 21.2821 10.6953 21.4375C10.6953 21.5929 10.757 21.7419 10.8669 21.8518C10.9768 21.9617 11.1258 22.0234 11.2812 22.0234H16.75C17.7342 22.0234 18.6781 21.6325 19.374 20.9365C20.07 20.2406 20.4609 19.2967 20.4609 18.3125V10.5C20.4658 9.19702 20.2136 7.90588 19.7188 6.70051C19.224 5.49515 18.4962 4.39925 17.5771 3.47559ZM4.25 11.0859C4.509 11.0859 4.75739 11.1888 4.94053 11.372C5.12367 11.5551 5.22656 11.8035 5.22656 12.0625V15.9688C5.22656 16.2278 5.12367 16.4761 4.94053 16.6593C4.75739 16.8424 4.509 16.9453 4.25 16.9453H2.6875C2.4285 16.9453 2.18011 16.8424 1.99697 16.6593C1.81383 16.4761 1.71094 16.2278 1.71094 15.9688V11.0859H4.25ZM15.7734 15.9688V12.0625C15.7734 11.8035 15.8763 11.5551 16.0595 11.372C16.2426 11.1888 16.491 11.0859 16.75 11.0859H19.2891V16.9453H16.75C16.491 16.9453 16.2426 16.8424 16.0595 16.6593C15.8763 16.4761 15.7734 16.2278 15.7734 15.9688Z"
        fill={color || '#979798'}
      />
    </svg>
  );
};

export default IconGarniture;
