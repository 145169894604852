import { useEffect, useState } from 'react';
import styles from './PopUpReviewСomment.module.css';
import IconClose from '../../../images_new/IconClose';
import { useDispatch, useSelector } from 'react-redux';
import { getReviews, postReviews } from '../../../store/routes';
import { getSuccess } from '../../../store/routes/selectors';
import formDataCombiner_2 from '../../../utils/formDataCombiner_2';
import { filter } from 'lodash';
import { useNavigate } from 'react-router-dom';
import MainButton from '../../buttons/MainButton';

const PopUpReviewСomment = ({
  isOpen,
  setIsOpenReviewComment,
  setIsOpenParamsPhoto,
  setIsOpenThanks,
  photo,
  setPhoto,
  rating,
  idCard,
  setTimer,
}) => {
  const url = Array.from(photo).map((file) => {
    return URL.createObjectURL(file);
  });
  const [text, setText] = useState('');
  const dispatch = useDispatch();
  const success = useSelector(getSuccess);
  const formData = new FormData();
  const navigate = useNavigate();

  const clickClose = async () => {
    const data = { idCard, rating };
    await dispatch(postReviews(data));
    if (!success) {
      setIsOpenReviewComment(false);
    }
  };

  const inputsHandler = (evt) => {
    setText(evt.target.value);
  };

  const showThanks = () => {
    if (rating > 0 || text.length > 0) {
      setIsOpenThanks(true);
      setTimer(
        setTimeout(() => {
          setIsOpenThanks(false);
          navigate('/search');
        }, 3000)
      );
    } else {
      navigate('/search');
    }
  };

  const toPablishReview = async () => {
    const arrayPhotos = Array.from(photo);
    const data = { text, rating };
    for (let i = 0; i < arrayPhotos.length; i++) {
      formData.append(`uploaded_images`, arrayPhotos[i]);
    }
    formDataCombiner_2(data, formData);
    const params = { idCard, formData };

    await dispatch(postReviews(params));

    if (!success) {
      setIsOpenReviewComment(false);
      showThanks();
    }
  };

  const deletePhoto = (i) => {
    setPhoto(filter(photo, (item, index) => index !== i));
  };

  const clickMissButton = async () => {
    const data = { idCard, rating };
    await dispatch(postReviews(data));
    if (!success) {
      setIsOpenReviewComment(false);
      showThanks();
    }
  };

  useEffect(() => {
    dispatch(getReviews(idCard));
  }, []);

  return (
    <>
      <div onClick={clickClose} className={styles.containerPopup_opened}></div>
      <div className={!isOpen ? styles.popup : styles.popup_opened}>
        <div className={styles.container}>
          <div className={styles.btnClose} onClick={clickClose}>
            <IconClose width={17} height={17} />
          </div>
          <h1 className={styles.title}>
            Поделитесь впечатлениями, помогите нам стать лучше для вас.
          </h1>
          <p className={styles.text}>Напишите, что больше всего понравилось</p>
          <div className={styles.containerArea}>
            <textarea
              value={text}
              onChange={inputsHandler}
              maxLength="300"
              className={styles.textarea}
            />
            <p className={styles.symbols}>
              Осталось {300 - text.length} символов
            </p>
          </div>
          <button onClick={setIsOpenParamsPhoto} className={styles.buttonImg}>
            Прикрепить фотографии
          </button>
          <div className={styles.photoContainer}>
            {url.map((item, index) => {
              return (
                <div className={styles.photo} key={index}>
                  <img className={styles.image} src={item} alt="reviewPhoto" />
                  <button
                    className={styles.delete}
                    onClick={() => deletePhoto(index)}
                  >
                    <IconClose width={5} height={5} />
                  </button>
                </div>
              );
            })}
          </div>
          <MainButton
            text="Опубликовать"
            onClick={toPablishReview}
            style={{ marginBottom: 14, marginTop: 40, width: '100%' }}
          />
          <button onClick={clickMissButton} className={styles.buttonMiss}>
            Пропустить
          </button>
        </div>
      </div>
    </>
  );
};

export default PopUpReviewСomment;
