import React from 'react';

const IconPencil = ({ width, height, color = '#333333', size = 'm' }) => {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {size === 's' ? (
        <path
          d="M2.16667 13.8334H3.345L11.1067 6.07174L9.92833 4.8934L2.16667 12.6551V13.8334ZM15.5 15.5001H0.5V11.9642L11.6958 0.768403C11.8521 0.612177 12.064 0.524414 12.285 0.524414C12.506 0.524414 12.7179 0.612177 12.8742 0.768403L15.2317 3.1259C15.3879 3.28218 15.4757 3.4941 15.4757 3.71507C15.4757 3.93604 15.3879 4.14796 15.2317 4.30424L5.7025 13.8334H15.5V15.5001ZM11.1067 3.71507L12.285 4.8934L13.4633 3.71507L12.285 2.53674L11.1067 3.71507V3.71507Z"
          fill="#3495CF"
        />
      ) : (
        <path
          d="M19.0615 5.30381L14.6973 0.939552C14.5703 0.812582 14.4196 0.711863 14.2537 0.643147C14.0878 0.574431 13.91 0.539062 13.7305 0.539062C13.5509 0.539062 13.3731 0.574431 13.2072 0.643147C13.0414 0.711863 12.8906 0.812582 12.7637 0.939552L0.720705 12.9815C0.593493 13.1083 0.492623 13.259 0.423905 13.4249C0.355188 13.5909 0.319982 13.7687 0.320315 13.9483V18.3126C0.320315 18.6752 0.464357 19.0229 0.720755 19.2793C0.977152 19.5357 1.3249 19.6798 1.6875 19.6798H18.0938C18.2492 19.6798 18.3982 19.6181 18.5081 19.5082C18.618 19.3983 18.6797 19.2493 18.6797 19.0938C18.6797 18.9384 18.618 18.7894 18.5081 18.6795C18.3982 18.5696 18.2492 18.5079 18.0938 18.5079H7.79004L19.0615 7.2374C19.1885 7.11045 19.2892 6.95972 19.3579 6.79384C19.4266 6.62795 19.462 6.45016 19.462 6.27061C19.462 6.09105 19.4266 5.91326 19.3579 5.74737C19.2892 5.58149 19.1885 5.43077 19.0615 5.30381ZM6.13281 18.5079H1.6875C1.6357 18.5079 1.58602 18.4873 1.5494 18.4507C1.51277 18.4141 1.49219 18.3644 1.49219 18.3126V13.9483C1.49241 13.8966 1.51313 13.8471 1.54981 13.8106L10.2813 5.0792L14.9219 9.71885L6.13281 18.5079ZM18.2324 6.4083L15.75 8.89072L11.1104 4.2501L13.5918 1.76865C13.6099 1.75049 13.6315 1.73609 13.6552 1.72626C13.6789 1.71643 13.7043 1.71137 13.73 1.71137C13.7557 1.71137 13.7811 1.71643 13.8048 1.72626C13.8285 1.73609 13.85 1.75049 13.8682 1.76865L18.2324 6.13193C18.2506 6.15007 18.265 6.17162 18.2748 6.19533C18.2846 6.21904 18.2897 6.24445 18.2897 6.27012C18.2897 6.29579 18.2846 6.3212 18.2748 6.34491C18.265 6.36862 18.2506 6.39016 18.2324 6.4083Z"
          fill={color}
        />
      )}
    </svg>
  );
};

export default IconPencil;
