import React from 'react';

const IconAnswer = ({ width, height, color }) => {
  return (
    <svg
      width={width || '21'}
      height={height || '21'}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.115 9.1375C11.0872 8.98382 11.0028 8.84612 10.8785 8.75152C10.7543 8.65693 10.5991 8.61229 10.4435 8.62641C10.288 8.64052 10.1434 8.71236 10.0381 8.82777C9.93291 8.94319 9.87471 9.09382 9.875 9.25V14.8775L9.885 14.99C9.91283 15.1437 9.99718 15.2814 10.1215 15.376C10.2457 15.4706 10.4009 15.5152 10.5565 15.5011C10.712 15.487 10.8566 15.4151 10.9619 15.2997C11.0671 15.1843 11.1253 15.0337 11.125 14.8775V9.25L11.115 9.1375ZM11.4987 6.4375C11.4987 6.18886 11.4 5.9504 11.2242 5.77459C11.0483 5.59877 10.8099 5.5 10.5612 5.5C10.3126 5.5 10.0742 5.59877 9.89834 5.77459C9.72252 5.9504 9.62375 6.18886 9.62375 6.4375C9.62375 6.68614 9.72252 6.9246 9.89834 7.10041C10.0742 7.27623 10.3126 7.375 10.5612 7.375C10.8099 7.375 11.0483 7.27623 11.2242 7.10041C11.4 6.9246 11.4987 6.68614 11.4987 6.4375ZM20.5 10.5C20.5 7.84784 19.4464 5.3043 17.5711 3.42893C15.6957 1.55357 13.1522 0.5 10.5 0.5C7.84784 0.5 5.3043 1.55357 3.42893 3.42893C1.55357 5.3043 0.5 7.84784 0.5 10.5C0.5 13.1522 1.55357 15.6957 3.42893 17.5711C5.3043 19.4464 7.84784 20.5 10.5 20.5C13.1522 20.5 15.6957 19.4464 17.5711 17.5711C19.4464 15.6957 20.5 13.1522 20.5 10.5ZM1.75 10.5C1.75 9.35093 1.97633 8.21312 2.41605 7.15152C2.85578 6.08992 3.5003 5.12533 4.31282 4.31282C5.12533 3.5003 6.08992 2.85578 7.15152 2.41605C8.21312 1.97633 9.35093 1.75 10.5 1.75C11.6491 1.75 12.7869 1.97633 13.8485 2.41605C14.9101 2.85578 15.8747 3.5003 16.6872 4.31282C17.4997 5.12533 18.1442 6.08992 18.5839 7.15152C19.0237 8.21312 19.25 9.35093 19.25 10.5C19.25 12.8206 18.3281 15.0462 16.6872 16.6872C15.0462 18.3281 12.8206 19.25 10.5 19.25C8.17936 19.25 5.95376 18.3281 4.31282 16.6872C2.67187 15.0462 1.75 12.8206 1.75 10.5Z"
        fill={color || '#979798'}
      />
    </svg>
  );
};

export default IconAnswer;
