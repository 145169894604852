import Container from '../../components_new/Container';
import MenuDocuments from '../../components_new/MenuDocuments';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { PATH_DOCUMENTS_USER, PATH_DOCUMENTS_AUTHOR } from '../../utils/constants';
import {
  lINK_USER,
  lINK_AUTHOR,
  TITLE_DOCUMENTS_USER,
  TITLE_DOCUMENTS_AUTHOR,
} from '../../utils/content';

const Documents = () => {
  const location = useLocation();
  const [links, setLinks] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    handleData()
  }, []);

  const handleData = () => {
    switch (location.pathname) {
      case PATH_DOCUMENTS_USER:
        setLinks(lINK_USER);
        setTitle(TITLE_DOCUMENTS_USER);
        break;
      case PATH_DOCUMENTS_AUTHOR:
        setLinks(lINK_AUTHOR);
      setTitle(TITLE_DOCUMENTS_AUTHOR);
        break;
      default:
        return;
    }
  }

  return (
    <>
      <Container
        title={title}
        styleTitle={{ fontSize: 20, lineHeight: '30px', marginBottom: 35 }}
      >
        {links?.length && <MenuDocuments links={links} />}
      </Container>
    </>
  );
};

export default Documents;
