import React from 'react';
import styles from './Star.module.css';
import StarIcon from '../../../images_new/star.svg';
import StarIconBlue from '../../../images_new/starBlue.svg';

const Star = ({ rating, setRating }) => {
  return (
    <div className={styles.containerStars}>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <label key={i}>
            <input
              className={styles.input}
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => setRating(ratingValue)}
            />
            <img
              className={styles.star}
              src={ratingValue <= rating ? StarIconBlue : StarIcon}
              alt="star"
            />
          </label>
        );
      })}
    </div>
  );
};

export default Star;
