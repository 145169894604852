import { createTheme, styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';

export const ImageEvent = styled('img')(({ theme }) => ({
  width: 74,
  height: 71,
  borderRadius: 8,
}));

export const Input = styled('input')(({ theme }) => ({
  padding: '13px 0 59px 12px',
  border: '1px solid #AAAAAA',
  borderRadius: 8,
  outline: 'none',
  marginTop: '12px',
  width: '100%',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  boxSizing: 'border-box',
}));

export const BoxInput = styled('div')(({ theme }) => ({
  border: '1px solid #AAAAAA',
  width: '100%',
  borderRadius: 8,
  padding: '12px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
}));

export const InputСost = styled('input')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  width: '90%',
}));

export const Validation = styled('span')(({ theme }) => ({
  position: 'relative',
  top: '-5px',
  color: 'red',
  fontSize: '0.7rem',
}));

export const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          div: {
            '&.MuiFilledInput-input': {
              transition: 'none',
              padding: '24px 12px 0',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '24px',
              borderRadius: '8px',
            },
          },
        },
      },
    },
  },
});

export const ButtonСost = styled(Button)(({ theme }) => ({
  color: 'black',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '24px',
  backgroundColor: grey[300],
  width: 80,
  height: 40,
  borderRadius: '8px',
  margin: 0,
}));

export const AddButton = styled('div')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  textDecoration: 'underline',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 18,
  lineHeight: '24px',
  marginBottom: 24,
  color: 'black',
}));

export const InfoFile = styled('div')(({ theme }) => ({
  width: '100%',
  height: '40px',
  border: '1px solid black',
  borderRadius: '8px',
  margin: '7px 0 14px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 15px',
  boxSizing: 'border-box',
}));
