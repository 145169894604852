import React from 'react';
import styles from './Text.module.css';
import { useState } from 'react';
import { useMemo } from 'react';
import { shortTextLength, defaultTextLength } from '../../../utils/constants';

const Text = ({ value, size = 'default' }) => {
  const length = size === 'short' ? shortTextLength : defaultTextLength;
  const shortText = useMemo(() => value.slice(0, length), [value, length]);
  const [moreText, setMoreText] = useState(false);

  const loadMoreText = () => {
    setMoreText(true);
  };

  return (
    <p className={styles.description}>
      <span>{!moreText ? shortText : value}</span>
      {!moreText && value.length > length ? (
        <button
          onClick={loadMoreText}
          className={!moreText ? styles.btn : styles.btnHidden}
        >
          ...еще
        </button>
      ) : (
        ''
      )}
    </p>
  );
};

export default Text;
