import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const NewAddButton = styled('label')(({ theme }) => ({
  backgroundColor: '#ECFF9F',
  width: '100%',
  height: 200,
  borderRadius: 8,
  color: grey[600],
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': { color: '#94AB07' },
  '&:active': { color: '#94AB07' },
  cursor: 'pointer',
}));
