import React from 'react';

const IconClock = ({ width, height, color }) => {
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 19.25C15.3326 19.25 19.25 15.3326 19.25 10.5C19.25 5.66738 15.3326 1.75 10.5 1.75C5.66738 1.75 1.75 5.66738 1.75 10.5C1.75 15.3326 5.66738 19.25 10.5 19.25Z"
        stroke={color || '#3495CF'}
        strokeWidth="1.75"
        strokeLinejoin="round"
      />
      <path
        d="M10.5035 5.25V10.5044L14.2131 14.2144"
        stroke={color || '#3495CF'}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconClock;
