
import IconMapPoint from '../../images_new/IconMapPoint';
import styles from './PointForm.module.css';
import TextAreaInput from '../inputs/TextAreaInput';
import SmallImgInput from '../inputs/SmallImgInput';
import AudioInput from '../inputs/AudioInput';

const PointForm = ({
                     openPopup,
                     register,
                     deletePoints,
                     index,
                     watch,
                     errors,
                     reset
                   }) => {
  const dynName = `points${index}name`;
  const dynDesc = `points${index}description`;
  const dynCoords = `points${index}point_coords`;
  return (
    <>
      <h1 className={styles.title}>Точка {index + 1}</h1>
      <input
        name={dynName}
        className={`${styles.input} ${errors[dynName] && styles.error}`}
        {...register(dynName, { required: true, maxLength: 150 })}
        placeholder='Назовите точку'
      />
      <TextAreaInput register={register} errors={errors}
                     placeholder='Опишите точку'
                     name={dynDesc}
                     counter={500} watch={watch} />
      <div className={`${styles.inputPointContainer} ${errors[dynCoords] && styles.error}`} onClick={openPopup}>
        <IconMapPoint width={16} height={20} />
        <input
          name={dynCoords}
          className={`${styles.inputPoint} ${errors[dynCoords] && styles.errorPlaceholder}`}
          placeholder='Отметьте место на карте'

          {...register(dynCoords, {
            required: true,
            pattern: {
              value: /^[ 0-9,.]+$/i
            }
          })}
        />
      </div>
      <div className={styles.containerFile}>
        <AudioInput register={register}
                    reset={reset}
                    errors={errors}
                    index={index}/>
        <SmallImgInput register={register}
                       errors={errors}
                       reset={reset}
                       index={index}
        />
      </div>
      <div className={styles.containerButton}>
        {/* <button className={styles.save}>Сохранить точку</button> */}
        <button
          className={styles.save}
          onClick={(evt) => deletePoints(evt, index)}
        >
          Удалить
        </button>
      </div>
    </>
  );
};

export default PointForm;
