import React from 'react';
import styles from './ListButton.module.css';
import List from '../../../images_new/list.svg';
import { useNavigate } from 'react-router-dom';

const ListButton = () => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate('/search');
  };

  return (
    <button onClick={handleClick} className={styles.button}>
      <p className={styles.text}>Список</p>
      <img src={List} className={styles.img} alt="map" />
    </button>
  );
};

export default ListButton;
