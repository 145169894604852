import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LikeButton } from '../../components_new/buttons/LikeButton';
import { Box, Typography } from '@mui/material';
import styles from './ExcursionCard.module.css';
import IconStar from '../../images_new/IconStar';
import IconMapPoint from '../../images_new/IconMapPoint';
import IconClock from '../../images_new/IconClock.js';
import IconHeadphones from '../../images_new/IconHeadphones.js';
import IconPencil from '../../images_new/IconPencil.js';
import IconShare from '../../images_new/IconShare.js';
import { useState } from 'react';
import { PopUpShare } from '../../components_new/popUps/PopUpShare';

function ExcursionCard({ card, path, type }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const handleClick = (e) => {
    type !== 'wait' && navigate(path);
  };

  const handleClickShare = (e) => {
    e.stopPropagation();
    setShowPopup(true);
  };

  const isOpenModeration = useSelector((state) => state.modal.isOpenModeration);

  return (
    <>
      <article onClick={handleClick} className={styles.excursionCard}>
        <div className={styles.imageWrapper}>
          {type !== 'wait' && (
            <div className={styles.containerLike}>
              {type === 'drafts' ? (
                <IconPencil size="s" width={16} height={16} />
              ) : type === 'publications' ? (
                <div
                  onClick={handleClickShare}
                  className={styles.containerShare}
                >
                  <IconShare />
                </div>
              ) : (
                <LikeButton card={card} width={18} height={18} />
              )}
            </div>
          )}
          <img
            className={styles.excursionCardImage}
            src={card.image}
            alt={card.name}
          />
        </div>
        <div className={styles.excursionCardDescription}>
          <div className={styles.excursionCardContainer}>
            <p className={styles.excursionCardTitle}>{card.name}</p>
            <div className={styles.rating}>
              <IconStar width={20} height={20} />
              <p className={styles.excursionCardType}>{card.rating || 0}</p>
            </div>
          </div>
          <div className={styles.containerMark}>
            <IconMapPoint width={15} height={15} />
            <p className={styles.excursionCardsSubtitle}>{card.city}</p>
          </div>
          <div className={styles.excursionContainerIcon}>
            <div className={styles.excursionCardHeader}>
              <IconClock width={16} height={16} />
              <p className={styles.excursionCardRating}>1 час</p>
            </div>
            <div className={styles.excursionCardHeader}>
              <IconHeadphones width={16} height={16} />
              <p className={styles.excursionCardRating}>
                {card.points[0]?.file?.includes('mp' || 'mpeg')
                  ? 'аудио'
                  : 'экскурсия'}
              </p>
            </div>
          </div>
          <p className={styles.price}>
            {card.price === 0 ? (
              'Бесплатно'
            ) : (
              <p className={styles.priceText}>
                Цена:{' '}
                <span className={styles.spanText}>{card.price}&#8381;</span>{' '}
              </p>
            )}
          </p>
        </div>
        {location.pathname === '/new-event-pages' && isOpenModeration && (
          <Box
            sx={{
              backgroundColor: '#ECFF9F',
              borderRadius: 4,
              textAlign: 'center',
              padding: '0.5rem 0',
              marginTop: 1.5,
            }}
          >
            <Typography variant="body">Статус проверки: в очереди</Typography>
          </Box>
        )}
      </article>
      {showPopup && (
        <PopUpShare isOpen={showPopup} setShowPopup={setShowPopup} />
      )}
    </>
  );
}
export default ExcursionCard;
