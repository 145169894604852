import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axios';
import toast from 'react-hot-toast';
import { each } from 'lodash/collection';

export const submitSingUp = createAsyncThunk(
  'submitSingUp',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('api/auth/users/', params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateCode = createAsyncThunk(
  'generateCode',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('api/generate_code/', params);
      return { data, success: true };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkCode = createAsyncThunk(
  'checkCode',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('api/login/', params);
      return { data, success: true };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyToken = createAsyncThunk(
  'verifyToken',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        'api/auth/token/verify/',
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkAuth = createAsyncThunk('checkAuth', async () => {
  const { data } = await axiosInstance.get('api/auth/users/me/');
  return data;
});

export const login = createAsyncThunk(
  'login',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('api/auth/token/', params);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//VK
export const loginVK = createAsyncThunk('loginVK', async () => {
  const { data } = await axiosInstance.get(
    'api/auth/o/google-oauth2/?redirect_url=https://cyber-gid-frontend.vercel.app/auth'
  );
  return data;
});

export const checkAuthVK = createAsyncThunk('checkAuthVK', async () => {
  const { data } = await axiosInstance.get('social_auth/convert-token/');
  return data;
});

export const patchProfile = createAsyncThunk('patchProfile', async (params) => {
  
  const { data } = await axiosInstance.patch('api/auth/users/me/', params
    // first_name: params.first_name,
    // email: params.email,
    // birth_date: params.birth_date,
    // city: params.city,
    // description: params.description,
  );
  return data;
});

export const patchAvatar = createAsyncThunk('patchAvatar', async (params) => {
  const { data } = await axiosInstance.patch('api/auth/users/me/', params);
  return data;
});

export const passwordReset = createAsyncThunk(
  'passwordReset',
  async (email) => {
    const { data } = await axiosInstance.post(
      'api/auth/users/reset_password/',
      email
    );
    return data;
  }
);

export const passwordResetСonfirm = createAsyncThunk(
  'passwordResetСonfirm',
  async (params) => {
    const { data } = await axiosInstance.post(
      'api/auth/users/reset_password_confirm/',
      params
    );
    return data;
  }
);
export const emailReset = createAsyncThunk(
  'emailReset',
  async (email) => {
    const { data } = await axiosInstance.post(
      'api/auth/users/reset_email/',
      email
    );
    return data;
  }
);


const initialState = {
  user: null,
  isAuthenticated: false,
  // isAuthenticated: true,
  isAdmin: false,
  onSuccess: null,
  errors: null,
  email: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    preSubmitSingUp: (state, { payload }) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    //submitSignUp
    builder.addCase(submitSingUp.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(submitSingUp.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(submitSingUp.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
      // each(errors, (error) => state.errors = (error.detail));
      // each(errors, (error) => toast.error(error.detail));
    });
    //login
    builder.addCase(login.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {

      const { email, first_name, access, refresh, username, avatar } = payload;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      state.user = { email, first_name, username, avatar };
      state.isAuthenticated = true;
      state.loaders.common = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loaders.common = false;
      const { errors } = action.payload;
      each(errors, (error) => toast.error(error.detail));
    });
    //checkCode
    builder.addCase(checkCode.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(checkCode.fulfilled, (state, { payload }) => {

      const { email, first_name, access_token, refresh_token, username, avatar } = payload.data;

      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      state.user = { email, first_name, username, avatar };
      state.isAuthenticated = true;
      state.loaders.common = false;
      state.onSuccess = true;
    });
    builder.addCase(checkCode.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      const { errors } = action.payload;
      each(errors, (error) => toast.error(error.detail));
    });
    //checkAuth
    builder.addCase(checkAuth.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(checkAuth.rejected, (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.user = null;
    });
    //verifyToken
    builder.addCase(verifyToken.fulfilled, (state, action) => {
      //  state.user = action.payload;
      state.isAuthenticated = true;
    });
    builder.addCase(verifyToken.rejected, (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      //state.user = null;
    });
    //patchProfile
    builder.addCase(patchProfile.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(patchProfile.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loaders.common = false;
    });
    builder.addCase(patchProfile.rejected, (state, action) => {
      state.loaders.common = false;
      toast.error('Ошибка создания пользователя');
    });
    //patchAvatar
    builder.addCase(patchAvatar.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(patchAvatar.fulfilled, (state, { payload }) => {
      state.user = payload;
      state.loaders.common = false;
    });
    builder.addCase(patchAvatar.rejected, (state, action) => {
      state.loaders.common = false;
      toast.error('Ошибка загрузки аватара');
    });
    //passwordReset
    builder.addCase(passwordReset.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(passwordReset.fulfilled, (state, action) => {
      state.email = action.meta.arg;
      state.loaders.common = false;
    });
    builder.addCase(passwordReset.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Ошибка отправки письма');
    });
    //passwordResetСonfirm
    builder.addCase(passwordResetСonfirm.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(passwordResetСonfirm.fulfilled, (state) => {
      state.loaders.common = false;
    });
    builder.addCase(passwordResetСonfirm.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Ошибка отправки письма');
    });
    //emailReset
    builder.addCase(emailReset.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(emailReset.fulfilled, (state, action) => {

      state.email = action.meta.arg;
      state.loaders.common = false;
    });
    builder.addCase(emailReset.rejected, (state) => {
      state.loaders.common = false;
      toast.error('Ошибка отправки письма');
    });
  },
});

export const { preSubmitSingUp } = authSlice.actions;
export default authSlice.reducer;
