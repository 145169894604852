import React from 'react';
import styles from './PriceInput.module.css';
import Tooltip from '../../Tooltip';
import IconQuestion from '../../../images_new/IconQuestion';

const PriceInput = ({ visible, setVisible, register }) => {
  return (
    <div className={styles.price}>
      <input
        className={styles.inputPrice}
        placeholder='Укажите стоимость'
        {...register('price', {
          setValueAs: (v) => {
            if (v === '') {
              return Number(v);
            }
            return Number(v);
          }
        })}
      />
      <span className={styles.span}>
            {visible && <Tooltip />}
        <div
          className={styles.spanContainer}
          onMouseEnter={() => setVisible(true)}
          onMouseLeave={() => setVisible(false)}
        >
              <IconQuestion cursor={'pointer'} />
            </div>
          </span>
    </div>
  );
};

export default PriceInput;
